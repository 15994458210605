<template>
    <div class="AffiateInsightsContainer">
      <div class="multiHeaderContainer">
        <div class="dashboard-container" style="display: flex; justify-content: space-between;">
          <div>
            <b-card-title>
              <h3 class="app-title mdsm mt-2 ml-2">Affiliate Insights</h3>
            </b-card-title>
          </div>
          
          <div class="filters-container" style="display: flex; justify-content: space-between; gap: 10px;">
            <div class="selectBox custom-vue-select">
              <label for="month-select">Months:</label>
              <v-select 
                :reduce="option => option.value" 
                label="value" 
                id="month-select" 
                placeholder="Select Months" 
                v-model="selectedMonths" 
                :options="allMonths" 
                :clearable="false"
                class="horizontal-scroll heightFixed" 
              />
            </div>
        
            <div class="selectBox custom-vue-select">
              <label for="project-select">Projects:</label>
              <v-select 
                label="value" 
                :reduce="option => option.value" 
                id="project-select" 
                placeholder="Select Projects"
                v-model="selectedProjects" 
                multiple 
                :options="allProjects"
                class="horizontal-scroll heightFixed" 
              />
            </div>
        
            <div class="selectBox custom-vue-select">
              <label for="affiliate-select">Affiliate Channels:</label>
              <v-select 
                label="name" 
                id="affiliate-select" 
                :reduce="option => option.value"
                placeholder="Select Affiliate Channels" 
                v-model="selectedAffiliates" 
                multiple 
                :options="allAffiliates" 
                class="horizontal-scroll heightFixed" 
              />
            </div>
        
            <div class="button-container" style="margin-top: 13px;">
              <b-button 
                variant="outline-primary" 
                @click="openProjectOutComeReport" 
                size="sm"
                class="d-flex align-items-center" 
                style="padding: 8px; gap: 5px;">
                <img src="@/assets/images/logo/sheets.png" alt="Google Sheets Icon" style="width: 20px; height: 20px;" />
                {{ this.monthlySheetUrl.length ? 'Update' : 'Connect' }} your sheet
              </b-button>
            </div>
          </div>
        </div>
        <div>
          <div v-if="this.allAffiliateDataFetching" class="spinnerCentered">
            <multiChannelSpinner />
          </div>
          <div v-else-if="Object.keys(this.allAffiliateData).length === 0" class="spinnerCentered">
            <span class="p-4">
              Please upload Affilate Insights Data.
            </span>
          </div>
          <div v-else class="multiHeaderContainer">
            <b-row>
              <b-col cols="12">
                <b-card>
                  <div class="spinnerCentered" v-if="this.filteredData.length === 0">
                    <div class="no-data-message">
                      <span class="line"></span>
                      <span class="text">Data Not Found For Selected Filters</span>
                      <span class="line"></span>
                    </div>
                  </div>
    
                  <div v-else class="header-container">
                    <div class="table-project-out-come">
                      <div class="date-column">
    
                        <div>
                          <table class="table">
                            <thead>
                              <tr>
                                <th>
                                  <feather-icon icon="DownloadIcon" color="#200E6B" size="18"
                                    style="cursor: pointer; margin-top: 5px"
                                    @click="exportProjectOutDataEntireData('Affilate_Insights')" />
                                </th>
                              </tr>
                              <tr>
                                <th>Project</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="(item, index) in filteredData" :key="index">
                                <td class="dateFacebookProjectTd paddingMeta0">
                                  <span>{{ item["Project"] }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
    
                      <div class="scrollable-table" v-for="(item, index) in filteredData" :key="index">
                        <table class="table">
                          <thead>
                            <tr>
                              <th colspan="26" class="mainTh"
                                :style="{ backgroundColor: getProjectsBackgroundColor(index) }">
                                <span style="display: flex; justify-content: space-between; align-items: center;">
                                  <span></span>
                                  <span style="margin-top: 10px; float: right;">{{ item['affiliate channel'] }}</span>
                                  <span style="float: right;">
                                    <!-- <feather-icon icon="DownloadIcon" color="#200E6B" size="18" style="cursor: pointer;"
                                      @click="exportProductOutcomeData(item, item['affiliate channel'])" /> -->
                                  </span>
                                </span>
                              </th>
                            </tr>
                            <tr style="font-size: 11px; white-space: nowrap;">
                              <th>
                                Start Date
                              </th>
                              <th>
                                End Date
                              </th>
                              <th>
                                No of days
                              </th>
                              <th>
                                Daily Spend
                              </th>
                              <th>
                                Spends
                              </th>
                              <th>
                                Affiliate channel
                              </th>
                              <th>
                                Leads
                              </th>
                              <th>
                                Walkins
                              </th>
                              <th>
                                Bookins
                              </th>
                              <th>
                                Revenue
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(data, dataIndex) in filteredData" :key="dataIndex">
                              <td>
                                {{ data['Start Date'] }}
                              </td>
                              <td>
                                {{ data['End Date'] }}
                              </td>
                              <td>
                                {{ data['No of Days'] }}
                              </td>
                              <td>
                                {{ data['Daily Spend'] }}
                              </td>
                              <td>
                                {{ data['Spends'] }}
                              </td>
                              <td>
                                {{ data['affiliate channel'] }}
                              </td>
                              <td>
                                {{ data['Leads'] }}
                              </td>
                              <td>
                                {{ data['Walkins'] }}
                              </td>
                              <td>
                                {{ data['Bookins'] }}
                              </td>
                              <td>
                                {{ data['Revenue'] }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </b-card>
    
              </b-col>
            </b-row>
          </div>
        </div>
        <b-modal v-model="individualDateWise" id="showShopNameModal" hide-footer hide-header centered size="lg">
          <b-card-text>
            <div class="header text-right">
              <feather-icon style="cursor: pointer" @click="CloseProjectOutComeReport" class="Xicon"
                icon="XIcon"></feather-icon>
            </div>
            <div class="modal-item mb-1 mt-1">
              <div class="pr-1 edit-form" style="display: flex; justify-content:space-between">
                <h4 class="app-title mdsm">
                  Upload Affilate Insights Data
                </h4>
                <div>
                  <a v-if="this.individual" v-b-tooltip.hover.top="tooltipForSampleSheet"
                    href="https://docs.google.com/spreadsheets/d/1RuuJdljLSly3SEVhyFsBghXFLX4a3noQdrhcCHkAzZ8/edit?gid=0#gid=0"
                    target="_blank">
                    <span style="text-decoration: underline;
                    color: #6b6b6b !important;">
                      Individual project sample sheet
                    </span>
                    <span>
                      <img src="@/assets/images/logo/sheets.png" alt="Icon"
                        style="width: 30px; height: 30px; margin-right: 5px; margin-left: 5px; cursor:pointer " />
                    </span>
                  </a>
                  <a v-if="!this.individual" v-b-tooltip.hover.top="tooltipForSampleSheet"
                    href="https://docs.google.com/spreadsheets/d/1gMbU6U1tKKmL-LmRxE7fO1Gqri0BfbsITSzCt1OIl2w/edit?gid=0#gid=0"
                    target="_blank">
                    <span style="text-decoration: underline;
                  color: #6b6b6b !important;">
                      Monthly sample sheet
                    </span>
                    <span>
                      <img src="@/assets/images/logo/sheets.png" alt="Icon"
                        style="width: 30px; height: 30px; margin-right: 5px; margin-left: 5px; cursor:pointer " />
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </b-card-text>
          <div class="option-container-affilate">
            <div style="display: flex; gap:40px ; margin-bottom:10px">
              <div class="radio-label">
                <input type="radio" v-model="individual" :value="false" />
                Upload for all projects
              </div>
              <div class="radio-label client-radio">
                <input type="radio" disabled v-model="individual" :value="true" />
                Upload for individual project
              </div>
            </div>
          </div>
          <!-- <span >
              <img v-b-tooltip.hover.top="tooltipForSampleSheet" src="@/assets/images/logo/sheets.png" alt="Icon"
              style="width: 30px; height: 30px; margin-right: 5px;margin-bottom: 27px; cursor:pointer " />
            </span> -->
          <div>
            <b-row v-if="individual" class="pl-1 pr-1" v-for="(form, index) in forms" :key="index">
              <b-col cols="4">
                <label for="username" class="username">Select a project:</label>
                <b-form-select v-model="form.selectProjectName" @change="updateSelectedOptions(index)">
                  <option v-for="option in getAvailableOptions(index)" :value="option" :key="option">
                    {{ option }}
                  </option>
                </b-form-select>
              </b-col>
              <b-col cols="5" class="mb-1">
                <label for="username" class="username">Upload File:</label>
                <b-form-file :state="Boolean(form.files && form.files.length)" placeholder="Choose CSV files..."
                  drop-placeholder="Drop files here..." accept=".csv" ref="fileInput" hidden></b-form-file>
              </b-col>
              <b-col cols="3">
                <div style="display:flex; justify-content:space-around">
                  <b-button v-if="!form.uploadSuccessful" variant="outline-primary" @click="
                    (event) => {
                      $refs.fileInput[index].$el.click();
                      handleFileChange(event, index);
                    }
                  " size="sm" style="height:37px; margin-top:23px">Upload <b-icon icon="cloud-upload" variant="primary" />
                  </b-button>
                  <!-- <b-icon
                 v-b-tooltip.hover.top="'CSV Uploaded Successfully!'"
                v-if="form.uploadSuccessful"
                variant="success"
                class="upPlusIcon"
                icon="check"
              /> -->
                  <b-button v-if="form.uploadSuccessful" variant="outline-primary" disabled size="sm"
                    style="height:37px; margin-top:23px">Uploaded <b-icon icon="check" variant="success" />
                  </b-button>
                  <template v-if="projectListCompareLength !== forms.length">
                    <b-icon v-if="index === forms.length - 1" variant="primary" class="upPlusIcon" icon="plus-circle-fill"
                      v-b-tooltip.hover.top="tooltipForNewLine" @click="addForm" />
                  </template>
                  <b-icon v-if="index !== forms.length - 1" v-b-tooltip.hover.top="tooltipForReduceLine" variant="danger"
                    class="upPlusIcon" icon="dash-circle-fill" @click="removeForm(index)" />
                </div>
              </b-col>
            </b-row>
            <b-row v-if="!individual" style="padding: 0 10px">
              <div v-if="this.monthlySheetUrl.length > 0"
                style="width: 100%; background-color: #f0f0f0; padding: 10px; border-radius: 5px; text-align: left; word-wrap: break-word; overflow-wrap: break-word; font-size:11px; margin-bottom: 10px;">
                <strong style="text-align: left !important;">Current Sheet :</strong>
                <a target="_blank" rel="noopener noreferrer" :href="this.monthlySheetUrl"> {{ this.monthlySheetUrl }}</a>
              </div>
    
            </b-row>
            <b-row style="padding: 0 10px; flex-wrap: wrap;">
              <b-col cols="12" sm="10">
                <label><span v-if="this.monthlySheetUrl.length > 0">New </span>Sheet Url</label>
                <b-form-input v-model="sheetUrlSubmit" style="width: 100%;"></b-form-input>
              </b-col>
              <b-col cols="12" sm="2" style="margin-top: 23px; text-align: right;">
                <b-button variant="primary" @click="submitSheet" style="width: 100%; max-width: 120px;">
                  Submit <b-spinner v-if="isSheetUrlSubmit" small></b-spinner>
                </b-button>
              </b-col>
            </b-row>
            <template v-if="this.uploadWarnings.length > 0">
              <span class="text-danger"
                style="font-size: 12px; margin-left: 10px; width: 700px; word-wrap: break-word; display: inline-block;">
                {{ this.uploadWarnings }}
              </span>
            </template>
          </div>
          <b-row class="mt-2 p-1">
            <div style="display: flex; width: 100%;">
              <div style="flex-grow: 1;"></div>
              <b-button variant="primary" style="margin-left: auto;" @click="CloseProjectOutComeReport">
                <span v-if="this.individual">Done</span> <span v-if="!this.individual">Done</span>
              </b-button>
            </div>
          </b-row>
        </b-modal>
      </div>
    </div>
</template>

<script>
import {
  BButton,
  BCard,
  BModal,
  BFormFile,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BCardTitle,
  BCardText,
  BSpinner,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserToken } from "@/auth/utils";
// import AffilatedMap from "@/@core/utils/projectOutCome";
import AffilatedMap from "@/@core/utils/affilatesMap";
export default {
  data() {
    return {
      selectedProjects: [],
      selectedAffiliates: [],
      selectedMonths: [this.getCurrentMonth()], // Default month selection
      allAffiliateData: {},
      allAffiliateDataFetching: false,
      isSheetUrlSubmit: false,
      sheetUrlSubmit: "",
      uploadWarnings: "",
      // monthlySheetUrl:"",
      individualDateWise: false,
      individual: false,
      // filterAffilatesWiseType:[],
      filterAffilatesChannelsWiseType: [],
      projectListLength: 0,
      selectProjectName: "",
      AffilatesChannels: [],
      filterProjectOutComeData: [],
      AffilatedMap: AffilatedMap,
      tooltipForSampleSheet: "Click here to view a sample sheet for the upload",
      tooltipForReduceLine: "Click to remove this",
      tooltipForNewLine: "Click to upload more",
      uploadWarnings: [],
      businessTypeProject: "",
      addProjectOutComeReport: false,
      uploadFiles: false,
      uploadStatus: "",
      selectedItems: [],
      forms: [{ selectProjectName: "", files: null, uploadSuccessful: false }],
      selectedAdsOption: "all",
      sourceType: ["all", "google", "meta"],
      lightColorHashCodes: [
        "#AFEEEE", // Light Turquoise
        "#E6E6FA", // Light Lavender
        "#F08080", // Light Coral
        "#778899", // Light Slate Gray
        "#9370DB", // Light Indigo
        "#B03060", // Light Maroon
        "#008B8B", // Light Teal
        "#808000", // Light Olive
        "#FFA07A", // Light Salmon
        "#D8BFD8", // Light Orchid
      ],
    };
  },
  components: {
    BCard,
    BModal,
    BButton,
    BFormFile,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BSpinner,
    vSelect,
    ToastificationContent,
    BFormSelect,
    multiChannelSpinner,
  },
  props: {
    clientID: {
      type: String,
    },
    AffilatedData: {
      type: Array,
    },
    allAffiliateDataFlag: {
      type: Boolean,
    },
    clientName: {
      type: String,
    },
    affilatesOptions: {
      type: Array,
    },
    affilatesWiseOptions: {
      type: Array,
    },
    monthlySheetUrl: {
      type: String,
    }
  },
  methods: {
    getCurrentMonth() {
      const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
      ];
      const currentMonthIndex = new Date().getMonth();
      return monthNames[currentMonthIndex];
    },
    submitSheet() {
      this.isSheetUrlSubmit = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/submit_google_sheet_link`,
          { clientID: this.clientID, attr: 'affiliateGSheet', gsheet: this.sheetUrlSubmit },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Submited",
            "Google Sheet submitted successfully!",
            "success"
          );
          this.isSheetUrlSubmit = false;
          // this.submitSheetModal = false;
          this.handleFilterAffliatesOnSheet();
        })
        .catch((error) => {
          this.isSheetUrlSubmit = false;
          // this.submitSheetModal = false;
          console.log(error.response);
        });
    },
    getAllAffiliateData() {
      this.allAffiliateData = {};
      this.allAffiliateDataFetching = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/affiliate-sheet-data?clientID=${this.clientID}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          console.log(response, '---');
          this.allAffiliateData = response.data
          console.log(this.allAffiliateData, '---1');
          this.allAffiliateDataFetching = false;
        })
        .catch((error) => {
          this.allAffiliateDataFetching = false;
          console.log(error);
        });
    },
    getFetchOptions() {
      this.affilatesOptionsModal = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/options?clientID=${this.clientID}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          let zoneWiseTypeOptions = response && response.data;
          const combinedArray = Object.values(
            zoneWiseTypeOptions.projectNames
          ).flat();
          this.affilatesOptionsModal = [...combinedArray];
          this.projectListLength = this.affilatesOptionsModal.length;
          this.selectProjectName = this.affilatesOptionsModal[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    handleFilterAffliatesType(newSelection) {
      this.$emit("filterAffliatesType", newSelection);
    },
    handleFilterAffliatesOnSheet(newSelection) {
      this.$emit("filterAffliatesOnSheet", newSelection);
    },
    handleFilterAffliatesChannelType(newSelection) {
      this.$emit("filterAffliatesChannelType", newSelection);
    },
    handleAfflilatesComeGetApi(newSelection) {
      this.$emit("callAfflilatesComeGetApi", newSelection);
    },
    updateSelectedOptions(index) {
      // Update selected items based on current selection
      this.selectedItems = this.forms.map((form) => form.selectProjectName);
    },
    getAvailableOptions(index) {
      return this.affilatesOptionsModal && this.affilatesOptionsModal.filter(
        (option) =>
          !this.selectedItems.includes(option) ||
          this.forms[index].selectProjectName === option
      );
    },
    exportProjectOutDataEntireData(name) {
  let displayArr = {
    Spends: "Spends",
    Leads: "Leads",
    Walkins: "Walkins",
    Bookins: "Bookins",
    Revenue: "Revenue",
    "Daily Spend": "Daily Spend",
    "No of Days": "No of Days"
  };

  const escapeCSV = (str) => {
    if (str === null || str === undefined) return '""';
    return `"${String(str).replace(/"/g, '""')}"`;
  };

  let headers = ["Date"];
  let groupNames = [];
  let allDates = new Set();
  let structuredData = [];

  // Loop through each month and collect data
  Object.entries(this.allAffiliateData).forEach(([month, dataArray]) => {
    dataArray.forEach((item) => {
      allDates.add(item["Start Date"]); // Assuming "Start Date" is the primary date
      structuredData.push({ month, ...item });

      if (!groupNames.includes(item.Project)) {
        groupNames.push(item.Project);
      }
    });
  });

  // Generate headers dynamically (Project-wise)
  groupNames.forEach((project) => {
    Object.keys(displayArr).forEach((key) => {
      headers.push(`${project} - ${key}`);
    });
  });

  // Create CSV content
  const csvRows = [headers.map(escapeCSV).join(",")];

  Array.from(allDates).forEach((date) => {
    const row = [escapeCSV(date)];

    groupNames.forEach((project) => {
      // Filter data for the given date and project
      const projectData = structuredData.filter(
        (entry) => entry.Project === project && entry["Start Date"] === date
      );

      let aggregatedData = {};
      Object.keys(displayArr).forEach((key) => {
        // Sum values if multiple records exist for a project on the same date
        aggregatedData[key] = projectData.reduce((sum, entry) => {
          return sum + (parseFloat(entry[displayArr[key]]) || 0);
        }, 0);
      });

      Object.keys(displayArr).forEach((key) => {
        row.push(escapeCSV(aggregatedData[key] || ""));
      });
    });

    csvRows.push(row.join(","));
  });

  // Create CSV file
  const csv = csvRows.join("\n");
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" });
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.download = `${name}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}
,
    handleCallOutComeGetApi(newSelection) {
      this.$emit("callAffilatesGetApi", newSelection);
    },

    exportProductOutcomeData(data, name) {
  // Define the mapping for the CSV columns
  let displayArr = {
    "Start Date": "startDate",
    "End Date": "endDate",
    "No of Days": "noOfDays",
    "Daily Spend": "dailySpend",
    "Project": "project",
    "Spends": "spends",
    "Affiliate Channel": "affiliateChannel",
    "Leads": "leads",
    "Walkins": "walkins",
    "Bookings": "bookings",
    "Revenue": "revenue",
  };

  // Check if data is an array and not empty
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Data is empty or not an array");
    return;
  }

  // Function to escape CSV values
  const escapeCSV = (str) => {
    if (str === null || str === undefined) {
      return '""';
    }
    return `"${String(str).replace(/"/g, '""')}"`;
  };

  // Function to get nested values from objects using a string path
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => (acc && acc[part] !== undefined ? acc[part] : ""), obj);
  };

  // Create CSV header
  const header = Object.keys(displayArr).map(escapeCSV).join(",");

  // Extract the first object (assuming it's structured like your provided data)
  const formattedData = {
    startDate: data[0]["Start Date"],
    endDate: data[0]["End Date"],
    noOfDays: data[0]["No of Days"],
    dailySpend: data[0]["Daily Spend"],
    project: data[0]["Project"],
    spends: data[0]["Spends"],
    affiliateChannel: data[0]["affiliate channel"],
    leads: data[0]["Leads"],
    walkins: data[0]["Walkins"],
    bookings: data[0]["Bookins"],
    revenue: data[0]["Revenue"],
  };

  // Create CSV row
  const row = Object.keys(displayArr)
    .map((key) => escapeCSV(getNestedValue(formattedData, displayArr[key])))
    .join(",");

  // Combine header and row to form CSV content
  const csvContent = [header, row].join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  // Create a download link for the CSV file
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${name || "export"}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
,
    getProjectsBackgroundColor(index) {
      return this.lightColorHashCodes[index % 10] + "!important";
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    isFormComplete(form) {
      return form.projectName && form.files.length;
    },
    async handleFileChange(event, formIndex) {
      this.uploadWarnings = "";
      const inputElement = this.$refs.fileInput[formIndex];
      if (!inputElement) {
        console.error(
          "File input element not found for form index:",
          formIndex
        );
        return;
      }

      const files = inputElement.files;

      if (files && files.length) {
        if (this.forms[formIndex]) {
          this.$set(this.forms[formIndex], "files", Array.from(files));
          await this.submitForms();
        } else {
          console.error("Form at index", formIndex, "does not exist");
        }
      }
    },
    async submitForms() {
      this.uploadWarnings = [];
      this.uploadStatus = "";
      this.uploadFiles = true;
      for (const [index, form] of this.forms.entries()) {
        console.log(form);
        if (form.selectProjectName && form.files.length) {
          const formData = new FormData();
          formData.append("projectName", form.selectProjectName);
          form.files.forEach((file) => {
            formData.append(`csvFile`, file);
          });
          formData.append(`clientID`, this.clientID);
          try {
            const response = await axios.post(
              `${process.env.VUE_APP_SERVER_URL}/api/channel/csv/upload-affiliate-report`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            );

            if (response.status === 200) {
              this.uploadWarnings = response.data && response.data.errors;
              this.handleAfflilatesComeGetApi();
              this.showToast("Success!", `${response.data.message}`, "success");
              this.uploadFiles = false;
              this.uploadStatus = "Files uploaded successfully.";
              this.$set(this.forms[index], "uploadSuccessful", true);
            } else {
              this.uploadStatus = "File upload failed.";
            }
          } catch (error) {
            this.uploadWarnings = "";
            this.uploadFiles = false;
            this.showToast("Error", "Unknown error occurred.", "danger");
            console.error("Error uploading files:", error);
            this.uploadStatus = "Error uploading files.";
          }
        } else {
          this.uploadStatus = "Please provide a project name and select files.";
        }
      }
    },
    removeForm(index) {
      this.forms.splice(index, 1);
      this.updateSelectedOptions();
    },
    addForm() {
      this.forms.push({
        selectProjectName: "",
        files: null,
        uploadSuccessful: false,
      });
    },
    CloseProjectOutComeReport() {
      this.getFetchOptions();
      (this.forms = [
        {
          projectName: "",
          file: null,
        },
      ]),
        (this.individualDateWise = false);
      this.uploadWarnings = "";
    },
    openProjectOutComeReport() {
      this.getFetchOptions();
      this.individualDateWise = true;
    },
    exportData(dateWiseData, projectName) {
      console.log("Exporting data for", projectName, dateWiseData);
    },
    downReportProjectsEntirely(reportName) {
      console.log("Downloading report", reportName);
    },
  },
  computed: {
    allProjects() {
      let projects = new Set();
      Object.values(this.allAffiliateData).forEach((monthData) => {
        monthData.forEach((item) => projects.add(item.Project));
      });
      return Array.from(projects).map((proj) => ({ name: proj, value: proj }));
    },
    allAffiliates() {
      let channels = new Set();
      Object.values(this.allAffiliateData).forEach((monthData) => {
        monthData.forEach((item) => channels.add(item["affiliate channel"]));
      });
      return Array.from(channels).map((channel) => ({
        name: channel,
        value: channel,
      }));
    },
    allMonths() {
      return Object.keys(this.allAffiliateData).map((month) => ({
        name: month,
        value: month,
      }));
    },
    filteredData() {
      console.log(this.selectedProjects, this.selectedAffiliates, '---');
      return Object.entries(this.allAffiliateData)
        .filter(([month]) => this.selectedMonths.includes(month)) // Month filter
        .map(([month, data]) =>
          data.filter(
            (item) =>
              (this.selectedProjects.length === 0 ||
                this.selectedProjects.includes(item['Project'])) &&
              (this.selectedAffiliates.length === 0 ||
                this.selectedAffiliates.includes(item["affiliate channel"]))
          )
        )
        .flat();
    },
    projectListCompareLength() {
      // logic to calculate projectListLength
      return this.projectListLength;
    },
    filteredProjectOutComeData() {
      return this.AffilatedData
    }
  },
  watch: {
    // filterAffilatesWiseType(newVal, oldVal) {
    //   this.sourceProjectsType = [];
    //   this.filterAffilatesWiseType = newVal;
    // },
    filterAffilatesChannelsWiseType(newVal, oldVal) {
      this.sourceProjectsType = [];
      this.filterAffilatesChannelsWiseType = newVal;
    },
    "form.files": function (newFiles) {
      if (newFiles.length) {
        this.submitForms();
      }
    },
  },
  mounted() {
    this.getAllAffiliateData();
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.AffiateInsightsContainer{
  
.heightFixed {
  .vs__dropdown-toggle {
    padding: 0 0 4px 0;
    background: none;
    font-size: 13px !important;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    height: 38px !important;
    margin-bottom: 10px;
  }
}

.selectBox {
  .custom-select {
    width: 100px !important;
  }
}

.custom-vue-select .vs__selected-options {
  display: flex !important;
  overflow-x: auto !important;
  white-space: nowrap;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-vue-select .vs__selected {
  display: inline-block;
  margin-right: 5px;
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
}

.table-project-out-come {
  display: flex;
  width: 100%;
  overflow-x: auto;

  .table thead th {
    // border-bottom: 1px solid #ebe9f1;
    font-size: 12px !important;
    color: black;
    width: 400px !important;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 0.5px solid #ddd;
    color: black;
    text-align: center;
    font-size: 12px;
    height: 60px;
    width: 100px;
  }

  .dateTd {
    color: black;
    font-size: 12px !important;
  }

  th {
    color: black;
    background-color: #eff5f5 !important;
  }

  .padding0 {
    padding: 0px;
  }
}

.date-column {
  position: sticky;
  left: 0;
  z-index: 1;
  color: black;
  background-color: white;

  .padding0 {
    padding: 0px;
  }

  .paddingMeta0 {
    padding: 0 2px !important;
  }

  td {
    color: black;
    background-color: #ffffff;
  }
}

.dateProjectTd {
  color: black;
  font-size: 12px !important;
}

.dateFacebookProjectTd {
  color: black;
  font-size: 12px !important;
}

.single-select {
  height: 38px !important;
}

.upDownUploadIcon {
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer;
  color: blue;
  font-size: 20px;
}

.upPlusIcon {
  margin-left: 10px;
  margin-top: 30px;
  cursor: pointer;
  color: #200e6b;
  font-size: 20px;
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  font-size: 13px !important;
}

.no-data-message {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  padding: 30px;
  font-weight: bold;
  color: #555;
}

.line {
  flex-grow: 1;
  height: 2px;
  background-color: #ccc;
  min-width: 50px;
}

.selectBox {
  width: 100% !important;
  width: 140px !important;
}
@media (max-width: 1024px) { /* Tablets */
  .filters-container {
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }

  .selectBox {
    width: 100% !important;
    max-width: 300px !important;
  }

  .button-container {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 768px) { /* Mobile */
  .dashboard-container {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .filters-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .selectBox {
    width: 100% !important;
    max-width: 100% !important;
  }

  .button-container {
    width: 100%;
    text-align: center;
  }
}
@media screen and (max-width: 768px) {
  .option-container-affilate{
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .edit-form {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .edit-form h4 {
    margin-bottom: 10px;
  }

  .edit-form div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .edit-form a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
  }

  .edit-form img {
    width: 25px !important;
    height: 25px !important;
  }
}

}

</style>
