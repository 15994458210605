<template>
  <div class="category-creation-container">
    <b-row>
      <b-col cols="12">
        <b-card style="margin-left: 5px; margin-right: 5px">
          <div class="Filter-Category-title"
          >
            <div>
              <b-card-title>
                <h3 class="app-title mdsm">Filter Category</h3>
              </b-card-title>
            </div>
            <div
              style="display: flex; justify-content: flex-end;gap: 1rem; align-items: center; flex:1 ;right: 0px;"
            >
              <b-form-select
                style="width: 10rem;"
                id="clientSelect"
                v-model="TableChannel"
              >
                <option
                  v-for="onetype in ChannelOption"
                  :value="onetype.value"
                  class="form-control"
                  id="lg_type"
                  name="lg_type"
                  :key="onetype.value"
                >
                  {{ onetype.label }}
                </option>
              </b-form-select>

              <b-button variant="primary" @click="openAddSpendsModal">
                <feather-icon
                  icon="PlusCircleIcon"
                  color="white"
                  size="15"
                  style="margin-right: 5px;"
                />
                Create
              </b-button>
            </div>
          </div>

          <!-- Add/Edit Modal -->
          <b-modal
            v-model="addSpendsModal"
            hide-footer
            hide-header
            centered
            size="lg"
          >
            <b-card-text>
              <div class="modal-item">
                <div
                  class="pr-1 form-title"
                  style="display: flex;justify-content: space-between;"
                >
                  <h6 class="app-title lg">
                    {{ isEditing ? "Edit" : "Create" }} Filter
                  </h6>
                  <div class="header text-right">
                    <feather-icon
                      style="cursor: pointer"
                      @click="closeAddSpendsModal"
                      class="Xicon"
                      icon="XIcon"
                    ></feather-icon>
                  </div>
                </div>
              </div>
            </b-card-text>

            <!-- Form Fields -->
            <div>
              <b-form-group label-for="Campaign-select">
                <label>Channel(s)<span class="validation">*</span></label>
                <b-form-select id="clientSelect" v-model="selectedChannel">
                  <option
                    v-for="onetype in this.CreateChannelOption"
                    :value="onetype.value"
                    class="form-control"
                    id="lg_type"
                    name="lg_type"
                    v-bind:key="onetype.value"
                    >{{ onetype.label }}</option
                  >
                </b-form-select>
              </b-form-group>

              <b-form-group label-for="Category">
                <label>Filter Category<span class="validation">*</span></label>
                <b-form-input
                  v-model="selectedCategory"
                  placeholder="Enter your filter category"
                >
                </b-form-input>
              </b-form-group>

              <b-form-group label-for="filter-entity">
                <label
                  >Provide Filter(s)<span class="validation">*</span></label
                >
                <v-select
                  v-model="selectedtags"
                  multiple
                  taggable
                  :clearable="true"
                  placeholder="Provide filters to the above category"
                  @input="handleChannelFilter"
                  hide-no-data
                />
              </b-form-group>

              <div :class="!isEditing ? 'spend-save-btn': ''" class="col-12 col-md-12 text-right">
                <b-button
                  variant="primary"
                  size="lg"
                  class="w-9 saveButton"
                  @click="isEditing ? updateFilter() : addFilterCampaign()"
                  :disabled="isCreateButtonDisabled"
                >
                  <span> {{ isEditing ? "Update" : "Save" }} </span>
                </b-button>
              </div>
            </div>
          </b-modal>

          <!-- alert -->
          <b-modal
            no-close-on-backdrop
            id="modal-center"
            hide-footer
            hide-header
            centered
            size="small"
            v-model="optionsDeleteModal"
          >
            <b-card-text>
              <div style="float: right">
                <b-button
                  variant="flat-primary"
                  @click="closeOptionsDeleteModal"
                  style="margin-top: -10px; margin-right: -18px"
                  ><feather-icon icon="XIcon"></feather-icon
                ></b-button>
              </div>
              <br />
              <div class="text-center" style="margin-top: 5px">
                <h4>
                  {{
                    `Are you sure you want to delete "${this.deletingCategory}" category from
                                    "${this.deletingChannel}-Ads" channel ?`
                  }}
                </h4>
              </div>
              <div class="button-container">
                <b-button
                  variant="outline-danger"
                  class="custom-primary-with-border"
                  @click="closeOptionsDeleteModal"
                >
                  Cancel
                </b-button>
                <b-button variant="danger" @click="deleteFilter">
                  Delete
                </b-button>
              </div>
            </b-card-text>
          </b-modal>
          <!-- Table -->
          <div v-if="isFilteredDataFetched" class="spinnerCentered">
            <multiChannelSpinner />
          </div>
          <div v-else>
            <vue-good-table
              :columns="tableColumns"
              :rows="filterOptimisationTabData"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div>
                  <div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (currentPage) =>
                          props.pageChanged({ currentPage: currentPage })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <template #table-row="props">
                <div style="display: flex; justify-content: center;align-items: center;" v-if="props.column.label === 'Action'">
                  <b-button
                    style="background-color: #6C4E96 !important;"
                    size="sm"
                    @click="editFilter(props.row)"
                  >
                    Edit
                  </b-button>
                  <b-button
                    size="sm"
                    variant="danger"
                    class="ml-2"
                    @click="confirmDelete(props.row)"
                  >
                    Delete
                  </b-button>
                </div>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BModal,
  BButton,
  BFormGroup,
  BCardText,
  BFormSelect,
  BFormInput,
  BTable,
  BSpinner,
  BPagination,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";

export default {
  name: "CategoryCreation",
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BModal,
    BButton,
    BFormGroup,
    vSelect,
    BCardText,
    BFormSelect,
    BFormInput,
    BTable,
    ToastificationContent,
    VueGoodTable,
    BSpinner,
    BPagination,
    multiChannelSpinner,
  },
  data() {
    return {
      pageLength: 10,
      currentPage: 1,
      isFilteredDataFetched: false,
      addSpendsModal: false,
      selectedChannel: "",
      TableChannel: "",
      selectedCategory: "",
      selectedtags: [],
      isEditing: false,
      editingId: null,
      optionsDeleteModal: false,
      deletingId: null,
      filterId: null,
      deletingCategory: "",
      deletingChannel: "",

      ChannelOption: [
        { value: "", label: "All" },
        { value: "meta", label: "Meta Ads" },
        { value: "google", label: "Google Ads" },
      ],
      CreateChannelOption: [
        { value: "", label: "Select your platform" },
        { value: "meta", label: "Meta Ads" },
        { value: "google", label: "Google Ads" },
      ],
      tableData: [],
      tableFields: [
        { key: "platform", label: "channel" },
        { key: "category", label: "Category" },
        { key: "filters", label: "filters" },
        { key: "actions", label: "Actions" }, // Ensure this is in fields to show action buttons
      ],
    };
  },
  props: {
    clientID: {
      type: String,
    },
  },
  mounted() {
    this.fetchFiltersData();
  },
  methods: {
    addActivityLog() {
      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/api/logs/`, {
              "description": "Category Creation Opened",
              "action": "view"
          }, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`
          }
        })
        .then(response => {
        })
        .catch(error => {
        });
    },
    fetchFiltersData(TableChannel = "") {
      this.isFilteredDataFetched = true;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/campaign-filter-lists?clientID=${this.clientID}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            "Content-Type": "application/json",
          },
          params: { platform: TableChannel },
        })
        .then((response) => {
          this.tableData = response.data.data;
          this.isFilteredDataFetched = false;
          this.currentPage = 1;
          // this.ChannelOption = response.data.channels;
        })
        .catch((error) => {
          this.isFilteredDataFetched = false;
          console.error("Error fetching filters:", error);
          this.showToast("Error", "Failed to fetch filters!", "danger");
        });
    },

    addFilterCampaign() {
      const newFilter = {
        clientID: this.clientID,
        platform: this.selectedChannel,
        category: this.selectedCategory,
        filters: this.selectedtags,
      };

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/campaign-filter`,
          newFilter,
          {
            headers: { Authorization: `Bearer ${getUserToken()}` },
          }
        )
        .then(() => {
          this.fetchFiltersData();
          this.resetForm();
          this.showToast("Success!", "Filter successfully created", "success");
          this.addActivityLog();
        })
        .catch((error) => {
          console.error("Error:", error);
          const errorMessage =
            error.response?.data?.message || "Unknown error occurred!";
          this.showToast("Error", errorMessage, "danger");
        });
    },

    updateFilter() {
      const updatedFilter = {
        clientID: this.clientID,
        platform: this.selectedChannel,
        category: this.selectedCategory,
        filters: this.selectedtags,
        id: this.editingId,
      };

      axios
        .put(
          `${process.env.VUE_APP_SERVER_URL}/api/campaign-filter`,
          updatedFilter,
          { headers: { Authorization: `Bearer ${getUserToken()}` } }
        )
        .then(() => {
          this.fetchFiltersData();
          this.resetForm();
          this.showToast("Success!", "Filter updated successfully", "success");
        })
        .catch((error) => {
          this.showToast(
            "Error",
            error.response?.data?.message || "Update failed!",
            "danger"
          );
        });
    },

    deleteFilter() {
      axios
        .delete(
          `${process.env.VUE_APP_SERVER_URL}/api/campaign-filter?clientID=${this.clientID}&id=${this.deletingId}`,
          { headers: { Authorization: `Bearer ${getUserToken()}` } }
        )
        .then(() => {
          this.fetchFiltersData();
          this.showToast("Deleted!", "Filter removed successfully", "danger");
          this.optionsDeleteModal = false;
        })
        .catch((error) => {
          this.showToast(
            "Error",
            error.response?.data?.message || "Deletion failed!",
            "danger"
          );
        });
    },
    confirmDelete(data) {
      this.deletingCategory = data.category;
      this.deletingChannel = data.platform;
      this.deletingId = data._id;
      this.optionsDeleteModal = true;
    },
    closeOptionsDeleteModal() {
      this.optionsDeleteModal = false;
    },

    editFilter(filter) {
      this.selectedChannel = filter.platform;
      this.selectedCategory = filter.category;
      var filterList = [filter.filters];
      let data = filterList[0].split(",").map((item) => item.trim());
      this.selectedtags = data;

      this.editingId = filter._id;
      this.isEditing = true;
      this.addSpendsModal = true;
    },

    resetForm() {
      this.selectedCategory = "";
      this.selectedtags = [];
      this.selectedChannel = "";
      this.isEditing = false;
      this.addSpendsModal = false;
    },

    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant === "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },

    openAddSpendsModal() {
      this.resetForm();
      this.addSpendsModal = true;
    },

    closeAddSpendsModal() {
      this.addSpendsModal = false;
    },

    handleChannelFilter(selected) {
      console.log("Selected Channels:", selected);
    },
  },
  watch: {
    TableChannel(newChannel) {
      this.fetchFiltersData(newChannel);
    },
  },
  computed: {
    tableColumns() {
      let columns = [
        {
          label: "Channel",
          field: "platform",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: false,
        },
        {
          label: "Category",
          field: "category",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: false,
        },
        {
          label: "Filter",
          field: "filters",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: false,
        },
        {
          label: "Action",
          field: "-",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: false,
        },
      ];
      return columns;
    },
    isCreateButtonDisabled() {
      return !(
        this.selectedCategory &&
        this.selectedChannel &&
        this.selectedtags.length
      );
    },
    filterOptimisationTabData() {
      if (!Array.isArray(this.tableData)) return [];

      return this.tableData.map((item) => {
        return {
          ...item,
          filters: Array.isArray(item.filters)
            ? item.filters.join(", ")
            : item.filters || "",
        };
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

@media (max-width: 768px) {
    .Filter-Category-title{
        flex-direction: column;
        align-items: center;
    }
}
.category-creation-container {
  .vgt-table td,
  .vgt-table th {
    padding: 0.6rem !important;
  }

  .small-row-table ::v-deep(.vgt-table tr) {
    height: 30px !important;
  }

  .small-row-table ::v-deep(.vgt-table td) {
    padding: 4px !important;
    font-size: 12px;
  }

  .spinnerCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Set the height of the container */
  }

  .custom-text {
    font-size: 12px !important;
    color: #1a1a1d;
  }

  .custom-text-th {
    font-size: 13px !important;
    font-weight: 600 !important;
    padding: 30px !important;
    color: black;
  }
  .Filter-Category-title{
    display: flex; 
    justify-content: space-between;
    padding-bottom: 1rem
  }
  .spend-save-btn{
    display: flex;
    justify-content: flex-end !important;
  }
}
</style>