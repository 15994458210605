<template>
  <div class="MultiChannelConnected">
    <div v-if="this.idClientEntireLoad" class="spinnerCentered" style="margin-top: 15%;">
      <Spinner />
    </div>
    <div v-else variant="white" spinner-variant="primary" blur="0" opacity=".75" rounded="sm"
      class="multi-dashboard-overlay">
      <!-- :show="showSummaryDashboardLoading ||
      showSummaryDashboardLoadingGraph ||
      isLiveDataIsLoad " -->
      <b-row style="width:100% ; margin:auto" v-if="client.status" class="content-header">
        <b-col class="content-header-left mb-2" cols="12" md="8">
          <b-row class="breadcrumbs-top">
            <b-col cols="12">
              <h2 class="content-header-title float-left pr-1 mb-0 text-white">
                <b-avatar class="mr-1" size="32" :src="client.logo" :text="avatarText(client.name)"
                  :variant="client.logo ? `white` : `primary`" />
                {{ client.name }}
              </h2>
              <div class="breadcrumb-wrapper breadcrumb-container text-white">
                <b-breadcrumb>
                  <b-breadcrumb-item to="/">
                    <feather-icon icon="HomeIcon" size="16" class="align-text-top text-white" />
                  </b-breadcrumb-item>
                  <b-breadcrumb-item v-for="item in breadcrumbs" :key="item.text" :active="item.active" :to="item.to">
                    <span class="text-white">
                      {{ item.text }}
                    </span>
                  </b-breadcrumb-item>
                </b-breadcrumb>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12" md="4" style="margin-bottom: 10px; padding-right: 0px !important;">
          <div class="sync-btn" style="display: flex; flex-direction: column; align-items: flex-end; gap: 5px;">
            <b-button v-b-tooltip.hover.top="'Sync Your Ads Data'" @click="openDataSyncModal" variant="primary"
              size="sm" :class="{
                'disabled-button-data-sync': isUpdatedToday(
                  this.client.updatedAt
                ),
              }" :disabled="isUpdatedToday(this.client.updatedAt)">
              <feather-icon icon="RefreshCwIcon" size="12" style="margin-right: 0px;" />
              Sync Data
            </b-button>

            <div v-if="
              (this.client &&
                this.client.integrations &&
                this.client.integrations.googleAds) ||
              (this.client &&
                this.client.integrations &&
                this.client.integrations.facebook)
            " style="color:white">
              <span style="font-size: 9px; text-align: center;">
                <strong>Last Updated:</strong>
                {{ formatDateAndTimeForSync(client.updatedAt) }}
              </span>
            </div>
          </div>
        </b-col>
      </b-row>

      <b-row style="width:100% ; margin:auto" class="filterContainer">
        <b-col style="padding-left: 0px !important;" class="filterContainer-Box" cols="7" md="4">
          <b-card class="headerContainerItem filterCard">
            <div>
              <b-row class="allChannelContainer">
                <b-col cols="12" :style="dynamicStyle">
                  <label class="multi-range-select" v-if="this.reportModeSelected == 'weekly'">
                    Showing Weekly Data</label>
                  <label class="multi-range-select" v-else-if="this.reportModeSelected == 'monthly'">
                    Showing Monthly Data</label>
                  <label class="multi-range-select" v-else-if="
                    this.tabMultiChannelName === 'ProductOutCome' &&
                    this.reportModeSelected === 'weekly'
                  ">
                    Showing Weekly Outcome Data</label>
                  <label class="multi-range-select form-label" v-else for="clientSelect">Showing Data for {{
                    this.dateRange.startDate | date }} to
                    {{ this.dateRange.endDate | date }}</label>
                  <date-range-picker ref="picker" opens="right" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"
                    :showWeekNumbers="false" :showDropdowns="true" :autoApply="true" v-model="dateRange"
                    @update="updateValues" :dateFormat="dateFormat" control-container-class="picker-controller"
                    :ranges="dateRanges" :auto-apply="false" :readonly="false" :maxDate="new Date(new Date().setDate(new Date().getDate() - 1))
                      " class="filterCardSelect multiDateSelector" :disabled="this.reportModeSelected == 'weekly' ||
                      this.reportModeSelected == 'monthly' ||
                      tabMultiChannelName === 'ProductOutCome' ||
                      this.tabMultiChannelName === 'CampaignFootfallInsights'
                      " :class="{
                      disabledRanges:
                        reportModeSelected == 'weekly' ||
                        reportModeSelected == 'monthly' ||
                        this.tabMultiChannelName === 'AffiliateInsights' ||
                        tabMultiChannelName === 'ProductOutCome' ||
                        this.tabMultiChannelName === 'CampaignFootfallInsights',
                    }">
                    <template v-slot:input="picker">
                      <feather-icon icon="CalendarIcon" size="16" class="mr-1"></feather-icon>
                      {{ formatDateDisplay(picker.startDate) }} -
                      {{ formatDateDisplay(picker.endDate) }}
                    </template>
                  </date-range-picker>
                </b-col>
                <b-col cols="12" v-if="this.tabMultiChannelName === 'AffiliateInsights'">
                  <div style="display: flex; gap: 100px; margin-top: 5px; justify-content:left;">
                    <label style="display: flex; align-items: center;">
                      <input :disabled="tabMultiChannelName === 'AffiliateInsights'" class="checkbox-weekly"
                        style="cursor: pointer;" type="radio" v-model="reportModeAffiliateSelected" value="custom" />
                      <span style="margin-left: 3px; margin-top:2px">Custom</span>
                    </label>
                    <label style="display: flex; align-items: center;">
                      <input class="checkbox-weekly" style="cursor: pointer;" type="radio"
                        v-model="reportModeAffiliateSelected" value="monthly" />
                      <span style="margin-left: 3px; margin-top:2px">Monthly</span>
                    </label>
                  </div>
                </b-col>
                <b-col cols="12" v-if="
                  this.tabMultiChannelName === 'ZoneInsights' ||
                  this.tabMultiChannelName === 'ProductOutCome'
                ">
                  <div style="display: flex; gap: 10px; margin-top: 5px; justify-content:space-between;">
                    <label style="display: flex; align-items: center;">
                      <input :disabled="tabMultiChannelName === 'ProductOutCome'" class="checkbox-weekly"
                        style="cursor: pointer;" type="radio" v-model="reportModeSelected" value="daily"
                        @change="handleReportModeChange" />
                      <span style="margin-left: 3px; margin-top:2px">Daily</span>
                    </label>
                    <label style="display: flex; align-items: center;">
                      <input :disabled="tabMultiChannelName === 'ProductOutCome'" class="checkbox-weekly"
                        style="cursor: pointer;" type="radio" v-model="reportModeSelected" value="weekly"
                        @change="handleReportModeChange" />
                      <span style="margin-left: 3px; margin-top:2px">Weekly</span>
                    </label>
                    <label style="display: flex; align-items: center;">
                      <input class="checkbox-weekly" style="cursor: pointer;" type="radio" v-model="reportModeSelected"
                        value="monthly" @change="handleReportModeChange" />
                      <span style="margin-left: 3px; margin-top:2px">Monthly</span>
                    </label>
                  </div>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
        <b-col class="filterContainer-Box" cols="7" md="4">
          <b-card style="height: 100px; " class="filterCard  click-link multichannelCardfilter">
            <div v-if="client.googleSheetReportEnabled">
              <b-row class="ml-1">
                <b-col :cols="getReportsCols">
                  <div class="form-group">
                    <label for="monthSelect" class="form-label">
                      Reports:</label>
                    <div class="select-container">
                      <b-form-select id="monthSelect" v-model="selectedReportType" @change="updateReportID">
                        <option v-for="report in reportType" :value="report" :key="report">
                          <span style="font-size: 10px !important;">{{
                            report
                            }}</span>
                        </option>
                      </b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col :cols="getReportsCols" v-if="this.selectedReportType == 'Daily'">
                  <div class="form-group">
                    <label for="monthSelect" class="form-label"> Month:</label>
                    <div class="select-container">
                      <b-form-select id="monthSelect" v-model="selectedReportMonth"
                        :disabled="this.selectedReportType == 'Weekly'" @change="updateReportID"
                        class="custom-report-select">
                        <option v-for="month in dynamicArray" :value="month.value" :key="month.value">
                          {{ month.label }}
                        </option>
                      </b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col :cols="getReportsCols">
                  <div class="form-group">
                    <label for="monthSelect" class="form-label"> year:</label>
                    <div class="select-container">
                      <b-form-select disabled id="monthSelect" v-model="selectedYear">
                        <option v-for="year in year" :value="year" :key="year">
                          {{ year }}
                        </option>
                      </b-form-select>
                    </div>
                  </div>
                </b-col>
                <b-col cols="2">
                  <a :href="`https://docs.google.com/spreadsheets/d/${this.selectedReportID}`
                    " target="_blank" style="text-decoration: none; color: white;">
                    <div variant="primary">
                      <img src="@/assets/images/logo/sheets.png" alt="Icon"
                        style="width: 30px; height: 30px; margin-right: 5px;margin-top: 27px; " />
                      <span style="font-size: 12px;"></span>
                    </div>
                  </a>
                </b-col>
                <b-col cols="12" style="margin-top: -12px !important;">
                  <a :href="`https://docs.google.com/spreadsheets/d/${this.selectedReportID}`
                    " target="_blank">
                    <span style="text-decoration: underline; margin-left: 20%;
                  color: #6b6b6b !important; font-size: 12px">
                      View google sheet report here
                    </span>
                  </a>
                </b-col>
              </b-row>
            </div>
            <div v-else>
              <b-link :to="`/client/${client.slug}`">
                <b-card-text class="xs text-center text-capitalize mt-1">
                  <span style="text-decoration: underline;
                  color: #6b6b6b !important;">
                    click here to connect your data
                  </span>
                </b-card-text>
              </b-link>
            </div>
          </b-card>
        </b-col>
        <b-col style="padding-right: 0px !important;" class="filterContainer-Box" cols="7" md="4">
          <b-card style="height: 100px; " class="filterCard  filterCardIcons">
            <div style="display: flex;  align-items: center; justify-content: space-evenly; margin-top: 8px;"
              class="iconGroupContainer">
              <!-- Loop through computed properties to generate links -->
              <div v-for="link in orderedLinks()" :key="link.name" class="avatar-section">
                <b-link :to="link.to" :disabled="link.disabled" v-bind:style="{ opacity: link.connected ? 1 : 0.4 }"
                  v-bind:class="{ 'not-allowed': !link.connected }">
                  <b-avatar v-if="link.name == 'googleAds'" square variant="white" size="35"
                    src="../../assets/images/logo/ads-logo.png"></b-avatar>
                  <b-avatar v-if="link.name == 'amazonAds'" square variant="white" size="35"
                    src="../../assets/images/logo/amazon.png"></b-avatar>
                  <b-avatar v-if="link.name == 'facebookAds'" square variant="white" size="35"
                    src="../../assets/images/logo/meta.png"></b-avatar>
                  <b-avatar v-if="link.name == 'googleAnalytics'" square variant="white" size="35"
                    src="../../assets/images/portrait/small/analytics-avatar.png"></b-avatar>
                  <b-avatar v-if="link.name == 'gmb'" square variant="white" size="35"
                    src="../../assets/images/logo/gmb.jpeg"></b-avatar>
                  <b-avatar v-if="link.name == 'leadSquared'" square variant="white" style="height: 35px; width:35px;"
                    src="../../assets/images/logo/leadSquared.png"></b-avatar>
                  <b-avatar v-if="link.name == 'shopifyAds'" square variant="white" style="height: 35px; width:35px;"
                    src="../../assets/images/logo/Shopify.png"></b-avatar>
                  <b-avatar v-if="link.name == 'dv360'" square variant="white" style="height: 30px; width:30px;"
                    src="../../assets/images/logo/dv360-logo.svg"></b-avatar>
                  <b-avatar v-if="link.name == 'salesforce'" square variant="white" style="height: 35px; width:50px;"
                    src="../../assets/images/logo/salesforce.svg"></b-avatar>
                </b-link>
              </div>
            </div>
            <div v-if="client.googleSheetReportEnabled" style="margin-top: -12px !important;">
              <b-link :to="`/client/${client.slug}`">
                <b-card-text class="xs text-center text-capitalize mt-1">
                  <span style="text-decoration: underline;
                  color: #6b6b6b !important; font-size: 12px">
                    click here to connect your data
                  </span>
                </b-card-text>
              </b-link>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-card style=" width:100% ; margin:auto" class="tabs-container screenAlignmentMultiChannel">
        <b-tabs fill class="multichanneldashbpa">
          <b-tab title="Overview" active @click="changeTab('Overview')"></b-tab>
          <b-tab @click="changeTab('ProjectInsights')" v-if="
            client.accessConfiguration &&
            client.accessConfiguration.projects &&
            client.accessConfiguration.projects.enabled &&
            client.businessType &&
            client.businessType[0] === 'leadgen'
          ">
            <template #title>
              <span :class="isProjectWiseIsLoad ? 'text-red blink-soft' : ''">Project Insights</span>
            </template>
          </b-tab>
          <b-tab @click="changeTab('ProductOutCome')" v-if="
            client.accessConfiguration &&
            client.accessConfiguration.projects &&
            client.accessConfiguration.projects.enabled &&
            client.businessType &&
            client.businessType[0] === 'leadgen'
          ">
            <template #title>
              <span :class="fetchProductOutWiseData ? 'text-red blink-soft' : ''">Project Outcome</span>
            </template>
          </b-tab>
          <b-tab @click="changeTab('AffiliateInsights')" v-if="
            client.accessConfiguration &&
            client.accessConfiguration.projects &&
            client.accessConfiguration.projects.enabled &&
            client.businessType &&
            client.businessType[0] === 'leadgen'
          ">
            <template #title>
              <span :class="fetchAffilatestOutWiseData ? 'text-red blink-soft' : ''">Affiliate Insights</span>
            </template>
          </b-tab>
          <b-tab @click="changeTab('ZoneInsights')" v-if="
            client.accessConfiguration &&
            client.accessConfiguration.projects &&
            client.accessConfiguration.projects.enabled &&
            client.businessType &&
            client.businessType[0] == 'leadgen'
          ">
            <template #title>
              <span :class="zoneWiseTypeOptionsFlag ? 'text-red blink-soft' : ''">Group Insights</span>
            </template>
          </b-tab>
          <b-tab @click="changeTab('TargetVSAchieved')" v-if="
            client.accessConfiguration &&
            client.accessConfiguration.projects &&
            client.accessConfiguration.projects.enabled &&
            client.businessType &&
            client.businessType[0] == 'leadgen'
          ">
            <template #title>
              <span>Target/Achieved</span>
            </template>
          </b-tab>
          <b-tab title="Product Insights" @click="changeTab('ProductInsights')" v-if="
            client.accessConfiguration &&
            client.accessConfiguration.projects &&
            client.accessConfiguration.projects.enabled &&
            client.businessType &&
            client.businessType[0] == 'leadgen'
          ">
            <template #title>
              <span :class="isProductDatafetched ? 'text-red blink-soft' : ''">Product Insights</span>
            </template>
          </b-tab>
          <b-tab title="Trends" @click="changeTab('Trends')" v-if="
            client.accessConfiguration &&
            client.accessConfiguration.projects &&
            client.accessConfiguration.projects.enabled &&
            client.businessType &&
            client.businessType[0] === 'leadgen'
          ">
            <template #title>
              <span>
                <!-- <b-icon icon="graph-up" font-scale="1.5" color="#41B06E" class="dotLive" /> -->
                Trends</span>
            </template>
          </b-tab>
          <b-tab v-if="client.businessType && client.businessType[0] === 'leadform'" title="Analyze"
            @click="changeTab('FilterData')">
            <template #title>
              <span> Analyze</span>
            </template>
          </b-tab>

          <!-- <b-tab title="Creative Analysis" @click="changeTab('CreativeAnalysis')" v-if="client.accessConfiguration && client.accessConfiguration.projects && client.accessConfiguration.projects.enabled">
          </b-tab> -->
          <b-tab title="Campaign Overview" @click="changeTab('ChannelPerformanceOverview')">
          </b-tab>
          <b-tab title="MTD Insights" @click="changeTab('MTDInsights')">
          </b-tab>
          <b-tab v-if="client.businessType && client.businessType[0] === 'leadform'" title="Campaign Footfall Insights"
            @click="changeTab('CampaignFootfallInsights')">
          </b-tab>
          <!-- <b-tab
          title="Meta-Ads Creative Analysis"
          @click="changeTab('CreativeAnalysisTable')"
        >
        </b-tab> -->
          <!-- <b-tab
          title="Meta-Ads Creative Analysis"
          @click="changeTab('MetaCreativeAnalysisTable')"
        >
        </b-tab> -->
          <b-tab v-if="
            client.businessType &&
            client.businessType[0] === 'leadform' &&
            client.role === 'ADMIN'
          " title="Add Filters" @click="changeTab('FilterCategory')">
            <template #title>
              <span> Add Filters</span>
            </template>
          </b-tab>
          <b-tab v-if="
            client.accessConfiguration &&
            client.accessConfiguration.projects &&
            client.accessConfiguration.projects.enabled &&
            client.businessType[0] === 'leadgen' &&
            client.role === 'ADMIN'
          " title="Project Creation" @click="changeTab('ProjectCreation')"></b-tab>
        </b-tabs>
      </b-card>
      <b-row style="padding-bottom: 1rem; width:100%; margin:auto" class="piechartAndSpendsContainer"
        v-if="this.tabMultiChannelName === 'Overview'">
        <b-col class="pr-0 pr-lg-1" style="padding-left:0px !important;" cols="12" xl="8">
          <div style="height: 100%;" class="mainPieChartContainer">
            <b-card style="height:100%; " class="mainPieChartCardContainer">
              <div style="height: 100%; display: flex; justify-content: center ; flex-direction: column;">
                <b-row class="centered-form donutChartContainer">
                  <b-col class="donutChart" cols="12" md="2">
                    <div v-if="this.showSummaryDashboardLoadingGraph" class="spinnerCentered">
                      <multiChannelSpinner />
                    </div>
                    <div class="chartContainer" v-else>
                      <div class="chartContainer-content">
                        <b-media class="text-center" no-body>
                          <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder">
                              Spends
                            </h5>
                            <h3 style="font-size:11px; color:#200E6B; font-weight:bold">
                              {{ formatNumberToTwoDecimals(this.spendsTotal) }}
                            </h3>
                          </b-media-body>
                        </b-media>
                        <apexchart class="benchmarkPie" type="donut" :options="chartOptions" :series="seriesSpends"
                          height="95"></apexchart>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="donutChart" cols="12" md="2">
                    <div v-if="this.showSummaryDashboardLoadingGraph" class="spinnerCentered">
                      <multiChannelSpinner />
                    </div>
                    <div class="chartContainer" v-else>
                      <div class="chartContainer-content">
                        <b-media class="text-center" no-body>
                          <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder ">
                              Impressions
                            </h5>
                            <h3 style="font-size:11px; color:#200E6B; font-weight:bold">
                              {{
                                formatNumberToTwoDecimals(this.impressionsTotal)
                              }}
                            </h3>
                          </b-media-body>
                        </b-media>
                        <apexchart class="benchmarkPie" type="donut" :options="chartOptions" :series="seriesImpressions"
                          height="95"></apexchart>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="donutChart" cols="12" md="2">
                    <div v-if="this.showSummaryDashboardLoadingGraph" class="spinnerCentered">
                      <multiChannelSpinner />
                    </div>
                    <div class="chartContainer" v-else>
                      <div class="chartContainer-content">
                        <b-media class="text-center" no-body>
                          <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder">
                              Clicks
                            </h5>
                            <h3 style="font-size:11px; color:#200E6B; font-weight:bold;">
                              {{ formatNumberToTwoDecimals(this.clicksTotal) }}
                            </h3>
                          </b-media-body>
                        </b-media>
                        <apexchart class="benchmarkPie" type="donut" :options="chartOptions" :series="seriesClicks"
                          height="95"></apexchart>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="donutChart" cols="12" md="2" v-if="client._id !== '6696476bc3cd30a4b4a7fc85'">
                    <div v-if="this.showSummaryDashboardLoadingGraph" class="spinnerCentered">
                      <multiChannelSpinner />
                    </div>
                    <div class="chartContainer" v-else>
                      <div class="chartContainer-content">
                        <b-media class="text-center" no-body>
                          <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder">
                              <span v-if="client.businessType[0] == 'leadgen'">Leads
                                <b-icon icon="info-circle-fill" font-scale="0.8" color="#68becc

" class="infoIconLive" v-b-tooltip.hover.top.html="'<div class=\'custom-tooltip\'>• Leads<br>• Messaging conversations initiated</div>'
  " /></span>
                              <span v-else-if="
                                client.businessType[0] == 'ecommerce'
                              ">Purchases</span>
                              <span v-else>Conversions</span>
                            </h5>
                            <h3 v-if="client.businessType[0] == 'ecommerce'"
                              style="font-size:11px; color:#200E6B; font-weight:bold">
                              {{
                                formatNumberToTwoDecimals(
                                  this.webPurchasesTotal
                                )
                              }}
                            </h3>
                            <h3 v-else style="font-size:11px; color:#200E6B; font-weight:bold">
                              {{
                                formatNumberToTwoDecimals(this.conversionsTotal)
                              }}
                            </h3>
                          </b-media-body>
                        </b-media>
                        <apexchart v-if="client.businessType[0] == 'ecommerce'" type="donut" class="benchmarkPie"
                          :options="chartOptions" :series="seriesWebPurchases" height="95"></apexchart>
                        <apexchart v-else type="donut" class="benchmarkPie" :options="chartOptions"
                          :series="seriesConversions" height="95"></apexchart>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="donutChart" cols="12" md="2" v-if="
                    client.businessType[0] === 'leadgen' &&
                    client._id !== '6696476bc3cd30a4b4a7fc85'
                  ">
                    <div v-if="this.showSummaryDashboardLoadingGraph" class="spinnerCentered">
                      <multiChannelSpinner />
                    </div>
                    <div class="chartContainer" v-else>
                      <div class="chartContainer-content">
                        <b-media class="text-center" no-body>
                          <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder">
                              CPL
                            </h5>
                            <h3 style="font-size:11px; color:#200E6B; font-weight:bold">
                              {{ this.cplTotal }}
                            </h3>
                          </b-media-body>
                        </b-media>
                        <apexchart type="donut" class="benchmarkPie" :options="chartOptions" :series="seriesCPL"
                          height="95">
                        </apexchart>
                      </div>
                      <!-- <div v-else>
                        <div>
                          <b-media class="text-center" no-body>
                            <b-media-body class="my-auto">
                              <h5 class="font-weight-bolder">
                                CPL
                              </h5>
                              <h3 style="font-size:11px; color:#200E6B; font-weight:bold">
                                {{ formatNumberToTwoDecimals(this.revenueTotal) }}
                              </h3>
                            </b-media-body>
                          </b-media>
                          <apexchart type="donut" class="benchmarkPie" :options="chartOptions" :series="seriesRevenue"
                            height="95"></apexchart>
                        </div>
                      </div> -->
                    </div>
                  </b-col>

                  <b-col class="donutChart" cols="12" md="2" v-if="client.businessType[0] !== 'leadgen'">
                    <div v-if="this.showSummaryDashboardLoadingGraph" class="spinnerCentered">
                      <multiChannelSpinner />
                    </div>
                    <div v-else>
                      <div v-if="
                        isRevenueEmpty
                        // && this.metaRevenue > 0  && this.amazonRevenue > 0 && this.googleRevenue > 0
                      ">
                        <div class="">
                          <b-media class="text-center" no-body>
                            <b-media-body class="my-auto">
                              <h5 class="font-weight-bolder">
                                Revenue
                              </h5>
                              <h3 style="font-size:11px; color:#200E6B; font-weight:bold">
                                {{ this.revenueTotal }}
                              </h3>
                            </b-media-body>
                          </b-media>
                          <apexchart type="donut" class="benchmarkPie" :options="chartOptions" :series="seriesRevenue"
                            height="95"></apexchart>
                        </div>
                      </div>
                      <div v-else>
                        <div>
                          <b-media class="text-center" no-body>
                            <b-media-body class="my-auto">
                              <h5 class="font-weight-bolder">
                                Revenue
                              </h5>
                              <h3 style="font-size:11px; color:#200E6B; font-weight:bold">
                                {{
                                  formatNumberToTwoDecimals(this.revenueTotal)
                                }}
                              </h3>
                            </b-media-body>
                          </b-media>
                          <apexchart type="donut" class="benchmarkPie" :options="chartOptions" :series="seriesRevenue"
                            height="95"></apexchart>
                        </div>
                      </div>
                    </div>
                  </b-col>
                  <b-col class="donutChart" cols="12" md="2">
                    <div v-if="this.showSummaryDashboardLoadingGraph" class="spinnerCentered">
                      <multiChannelSpinner />
                    </div>
                    <div class="chartContainer" v-else>
                      <div class="chartContainer-content">
                        <b-media class="text-center" no-body>
                          <b-media-body class="my-auto">
                            <h5 class="font-weight-bolder">
                              CTR %
                            </h5>
                            <h3 style="font-size:11px; color:#200E6B; font-weight:bold">
                              {{ this.ctrTotal }} %
                            </h3>
                          </b-media-body>
                        </b-media>
                        <apexchart type="donut" class="benchmarkPie" :options="chartOptions" :series="seriesCTR"
                          height="95">
                        </apexchart>
                      </div>
                    </div>
                  </b-col>
                </b-row>

                <div class="donutChartlable"
                  style="display: flex; padding-right:1rem;  width: 100%; margin-top:10px;  font-size: 11px; font-weight: bold; justify-content: flex-end;">
                  <span v-if="this.googleAdsConnected"
                    style="width:12px;  height: 12px; background-color: #7ab240; border-radius: 5px; margin-right: 2px;"></span><span
                    v-if="this.googleAdsConnected" style="margin-right: 4px;">Google</span>
                  <span v-if="this.amazonAdsConnected"
                    style="width:12px; height: 12px; background-color: #ff9900; border-radius: 5px; margin-right: 4px;"></span>
                  <span v-if="this.amazonAdsConnected" style="margin-right: 2px;">Amazon</span>
                  <span v-if="this.facebookAdsConnected"
                    style="width:12px; height: 12px; background-color: #0273eb; border-radius: 5px; margin-right: 4px;"></span><span
                    v-if="this.facebookAdsConnected" style="margin-right: 2px;">Meta</span>
                  <span v-if="this.dv360Connected"
                    style="width:12px; height: 12px; background-color: #66D2CE; border-radius: 5px; margin-right: 4px;"></span><span
                    v-if="this.dv360Connected" style="margin-right: 2px;">DV360</span>
                </div>
              </div>
            </b-card>
          </div>
        </b-col>
        <b-col style="padding:0px !important;" class="sendTrackerParent" cols="12" xl="4">
          <div style="height:100%" class="spendsTrackerContainer">
            <b-card style="height:100%" class="mainPieChartCardContainer">
              <div v-if="isSpendsLoading" class="spinnerCentered" style="height: 120px;">
                <multiChannelSpinner class="mt-1" />
              </div>
              <div v-else>
                <div style="display: flex; justify-content: space-between">
                  <span>
                    <b-icon icon="circle-fill" font-scale="1" color="#41B06E" class="dotLive" /><span
                      class="blink_text"><strong>Live</strong></span></span>
                  <b-button variant="primary" @click="openAddSpendsModal">
                    <feather-icon icon="PlusCircleIcon" color="white" size="15" style="margin-right: 5px;" />
                    Track
                  </b-button>
                </div>
                <div class="spendsCardTrackerContainer">
                  <div v-if="this.spendsMessage && this.spendsMessage.length > 0"
                    style="display: flex; justify-content: space-between; margin-bottom: 0px;margin-top: 14px; margin-left: 15px">
                    <!-- <b-badge variant="primary">{{this.spendsMessage}}</b-badge> -->
                    <span class="animated-message-text">
                      <span>{{ this.spendsMessage }}</span>
                    </span>
                  </div>
                  <div v-if="
                    spendsTracker && Object.keys(spendsTracker).length > 0
                  " style="display: flex; justify-content: space-between; margin-bottom: 10px; margin-left: 15px;">
                    <span v-if="spendsTracker && spendsTracker.result"
                      style="font-size: 13px; font-weight: bold; margin-bottom: 10px; margin-top: 8px;"
                      class="animated-message-text">
                      <span>{{ spendsTracker.message }}</span>
                    </span>

                    <span v-else style="font-size: 13px; margin-top: 0.1rem; font-weight: bold;">
                      <span class="animated-message-text">
                        <span v-if="
                          this.startDateForTriggers &&
                          this.startDateForTriggers.length > 0 &&
                          this.endDateForTriggers &&
                          this.endDateForTriggers.length > 0
                        ">
                          Tracking Spends from {{ this.startDateForTriggers }}
                          to
                          {{ this.endDateForTriggers }}
                        </span>
                        <span class="animated-message-text mt-1" v-else>
                          <span>Add a transaction to start spends tracking!</span>
                        </span>
                      </span>
                    </span>
                  </div>

                  <!-- Show this message if spendsTracker is empty -->
                  <div v-else>
                    <!-- <span  class="animated-message-text">
                    No spend tracking data available.
                    </span> -->
                    <span class="animated-message-text ml-1">
                      <span>Add a transaction to start spends tracking!</span>
                    </span>
                  </div>

                  <div style="display: flex; justify-content: space-between;">
                    <!-- || this.spendsTracker.result  -->

                    <div v-if="spendsTracker && spendsTracker.resultt">
                      <ul style="text-decoration: none; list-style: none; margin-left: -25px; font-size: 14px">
                        <li>
                          <strong>Planned Budget: </strong>{{
                            (this.spendsTracker &&
                              this.spendsTracker.result
                                .plannedBudgetFormatted) ||
                            this.conditionalSpends
                          }}
                        </li>
                        <li>
                          <strong>Total Spends : </strong>{{
                            this.spendsTracker.spentSoFarFormatted == undefined
                              ? "____"
                              : this.spendsTracker.spentSoFarFormatted
                          }}
                        </li>
                        <li>
                          <strong>Available Balance : </strong>{{
                            this.spendsTracker.remainingBudgetFormatted ==
                              undefined
                              ? "____"
                              : this.spendsTracker.remainingBudgetFormatted
                          }}
                        </li>
                        <li>
                          <strong>Days Left : </strong><span>
                            {{
                              this.spendsTracker.daysLeftBySpends == undefined
                                ? `Effective from ${this.spendsTracker.message.match(
                                  /[A-Za-z]{3} \d{1,2}, \d{4}/
                                )}`
                                : this.spendsTracker.daysLeftBySpends
                            }}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div v-else-if="
                      spendsTracker && this.spendsTracker.isEnded == false
                    ">
                      <ul style="text-decoration: none; list-style: none; margin-left: -25px; font-size: 14px">
                        <li>
                          <strong>Amount Received : </strong>{{
                            (this.spendsTracker &&
                              this.spendsTracker.amountReceived) ||
                            this.conditionalSpends
                          }}
                        </li>
                        <li>
                          <strong>Total Spends : </strong>{{
                            this.spendsTracker.spentSoFarFormatted == undefined
                              ? "____"
                              : this.spendsTracker.spentSoFarFormatted
                          }}
                        </li>
                        <li>
                          <strong>Available Balance : </strong>{{
                            this.spendsTracker.remainingBudgetFormatted ==
                              undefined
                              ? "____"
                              : this.spendsTracker.remainingBudgetFormatted
                          }}
                        </li>
                        <li>
                          <strong>Days Left : </strong><span>
                            {{
                              this.spendsTracker.daysLeftBySpends == undefined
                                ? `Effective from ${this.spendsTracker.message.match(
                                  /[A-Za-z]{3} \d{1,2}, \d{4}/
                                )}`
                                : this.spendsTracker.daysLeftBySpends
                            }}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div v-else>
                      <ul style="text-decoration: none; list-style: none; margin-left: -25px; font-size: 14px">
                        <li>
                          <strong>Planned Budget : </strong>{{
                            this.allSpendTrackersPlannedBudgetFormatted === 0
                              ? "____"
                              : this.allSpendTrackersPlannedBudgetFormatted
                          }}
                        </li>
                        <li>
                          <strong>Spent So Far :</strong>{{
                            this.allSpendTrackersSpentSoFarFormatted === 0
                              ? "____"
                              : this.allSpendTrackersSpentSoFarFormatted
                          }}
                        </li>
                        <li>
                          <strong>Remaining Budget : </strong>{{
                            this.allSpendTrackersRemainingBudgetFormatted === 0
                              ? "____"
                              : this.allSpendTrackersRemainingBudgetFormatted
                          }}
                        </li>
                        <li>
                          <strong>Active Triggers Counts: </strong>{{
                            this.totalActiveTriggers === 0
                              ? "____"
                              : this.totalActiveTriggers
                          }}
                        </li>
                      </ul>
                    </div>
                    <div>
                      <img src="@/assets/images/logo/spending-money.png" alt="d2scale-logo"
                        style="width: 60px; margin-right: 15px" />
                    </div>
                  </div>
                  <div style="display: flex; justify-content: space-evenly; margin-bottom: 0px">
                    <div style="display: flex; justify-content: space-between;">
                      <div>
                        <div v-if="this.googleAdsConnected">
                          <b-link :to="`/client/${client.slug}/dashboard/google-ads`">
                            <b-avatar square variant="white" size="40" src="../../assets/images/logo/ads-logo.png">
                            </b-avatar>
                          </b-link>
                        </div>
                        <div v-else>
                          <b-avatar square variant="white" size="40" src="../../assets/images/logo/ads-logo.png">
                          </b-avatar>
                        </div>
                      </div>
                      <div>
                        <span style="font-size: 13px; font-weight: bold; margin-top: 10px;">Google Ads</span>
                        <br />
                        <span style="font-size: 14px;">
                          {{
                            this.googleAdsspenddata === ""
                              ? "____"
                              : this.googleAdsspenddata
                          }}
                        </span>
                      </div>
                      <br />
                    </div>
                    <div style="display: flex; justify-content: space-between;">
                      <div>
                        <div v-if="this.facebookAdsConnected">
                          <b-link :to="`/client/${client.slug}/dashboard/meta-ads`">
                            <b-avatar square variant="white" size="40" src="../../assets/images/logo/meta.png">
                            </b-avatar>
                          </b-link>
                        </div>
                        <div v-else>
                          <b-avatar square variant="white" size="40" src="../../assets/images/logo/meta.png">
                          </b-avatar>
                        </div>
                      </div>
                      <div>
                        <span style="font-size: 13px; font-weight: bold; margin-top: 10px;">Meta</span>
                        <br />
                        <span style="font-size: 14px;">
                          {{
                            this.metaAdsspenddata === ""
                              ? "____"
                              : this.metaAdsspenddata
                          }}
                        </span>
                      </div>
                      <br />
                    </div>
                    <div style="display: flex; justify-content: space-between;">
                      <div>
                        <div>
                          <b-avatar square variant="white" size="40" src="../../assets/images/logo/money-bags.png">
                          </b-avatar>
                        </div>
                      </div>
                      <div>
                        <span style="font-size: 13px; font-weight: bold; margin-top: 10px;">Others</span>
                        <br />
                        <span style="font-size: 14px;">
                          {{
                            this.otherAdsspenddata === ""
                              ? "____"
                              : this.otherAdsspenddata
                          }}
                        </span>
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <div v-if="this.tabMultiChannelName === 'Overview'">
        <b-row style="width:100%;margin:auto;" class="centered-form">
          <b-col style="padding-left: 0px !important;" :cols="getCols" v-if="this.googleAdsConnected">
            <b-link :to="`/client/${client.slug}/dashboard/google-ads`">
              <b-card class="cardContainer todayCardContainer">
                <div v-if="isLiveDataIsLoad" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div class="cardItemContainer " v-else>
                  <span class="todayText">
                    <b-icon icon="circle-fill" font-scale="1" color="#41B06E" class="dotLive" /><span
                      class="blink_text">Today</span></span>
                  <div class="avatar-section"
                    style="display: flex;  align-items: center; justify-content: center; margin-top: -12px;">
                    <b-link :to="`/client/${client.slug}/dashboard/google-ads`">
                      <b-avatar square variant="white" size="45" src="../../assets/images/logo/ads-logo.png">
                      </b-avatar>
                    </b-link>
                    <b-card-text class="app-title sm ml-0.5">
                      Google Ads
                    </b-card-text>
                  </div>
                  <div v-if="this.googleAdsId == ''"
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;">
                    <b-button variant="primary" size="sm" :to="`/client/${client.slug}`">Select an account</b-button>
                  </div>
                  <div v-else
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;">
                    <div style="display: flex;  justify-content: space-evenly; ">
                      <div style=" display: flex; ">
                        <div style=" display: flex; justify-content: space-between;">
                          <p style="font-size:11px; color: #7a7a7a; width:100px">
                            <b>Spends</b><br /><span style="font-weight: bold; font-size: 14px; color:#200E6B ; ">
                              {{ this.googleSpendsToday }}
                            </span>
                            <br />
                            <span v-if="this.googleSpendsDelta > 0" class="text-success" v-b-tooltip.hover.bottom
                              :title="'Yesterday : ' + this.googleSpendsYeserday
                                ">
                              <feather-icon icon="ArrowUpIcon" size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                  Math.abs(this.googleSpendsDelta) }}%
                            </span>
                            <span v-else class="text-danger" v-b-tooltip.hover.bottom :title="'Yesterday : ' + this.googleSpendsYeserday
                              ">
                              <feather-icon icon="ArrowDownIcon" size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                  Math.abs(this.googleSpendsDelta) }}%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; ">
                      <div style="display: flex; ">
                        <div style="display: flex; justify-content: space-between;">
                          <p style="font-size:11px; color: #7a7a7a;width:100px; ">
                            <b><span v-if="client.businessType[0] == 'leadgen'">Leads</span>
                              <span v-else-if="
                                client.businessType[0] == 'ecommerce'
                              ">Purchases</span>
                              <span v-else>Conversions</span></b><br /><span
                              style="font-weight: bold; font-size: 14px; color:#200E6B ;">
                              <span v-if="client.businessType[0] == 'ecommerce'">
                                {{ this.googleWebPurchasesToday }}</span>
                              <span v-else>{{
                                this.googleConversionToday
                                }}</span>
                            </span>
                            <br />
                            <span v-if="client.businessType[0] == 'ecommerce'">
                              <span v-if="this.googleWebPurchasesDelta > 0" v-b-tooltip.hover.bottom
                                class="text-success" :title="'Yesterday : ' +
                                  this.googleWebPurchasesYeserday
                                  ">
                                <feather-icon icon="ArrowUpIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                    Math.abs(this.googleWebPurchasesDelta) }}%
                              </span>
                              <span v-else class="text-danger" v-b-tooltip.hover.bottom :title="'Yesterday : ' +
                                this.googleWebPurchasesYeserday
                                ">
                                <feather-icon icon="ArrowDownIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                    Math.abs(this.googleWebPurchasesDelta) }}%
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="this.googleConversionDelta > 0" v-b-tooltip.hover.bottom class="text-success"
                                :title="'Yesterday : ' + this.googleConversionYeserday
                                  ">
                                <feather-icon icon="ArrowUpIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                    Math.abs(this.googleConversionDelta) }}%
                              </span>
                              <span v-else class="text-danger" v-b-tooltip.hover.bottom :title="'Yesterday : ' + this.googleConversionYeserday
                                ">
                                <feather-icon icon="ArrowDownIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                    Math.abs(this.googleConversionDelta) }}%
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-link>
          </b-col>
          <b-col :cols="getCols" v-if="this.amazonAdsConnected">
            <b-link :to="`/client/${client.slug}/dashboard/amazon-ads`">
              <b-card class="cardContainer todayCardContainer">
                <div v-if="isLiveDataIsLoad" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div class="cardItemContainer " v-else>
                  <span class="todayText">
                    <b-icon icon="circle-fill" font-scale="1" color="#41B06E" class="dotLive" /><span
                      class="blink_text">Today</span></span>
                  <div class="avatar-section"
                    style="display: flex;  align-items: center; justify-content: center; margin-top: -12px;">
                    <b-link :to="`/client/${client.slug}/dashboard/amazon-ads`">
                      <b-avatar square variant="white" size="45" src="../../assets/images/logo/amazon.png">
                      </b-avatar>
                    </b-link>
                    <b-card-text class="app-title sm ml-1">
                      Amazon
                    </b-card-text>
                  </div>
                  <div v-if="this.amazonAdsId == ''"
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;">
                    <b-button variant="primary" size="sm" :to="`/client/${client.slug}`">Select an account</b-button>
                  </div>
                  <div v-else
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;">
                    <div style="display: flex;  justify-content: space-evenly; ">
                      <div style=" display: flex; ">
                        <div style=" display: flex; justify-content: space-between;">
                          <p style="font-size:11px; color: #7a7a7a; width:100px">
                            <b>Spends</b><br /><span style="font-weight: bold; font-size: 14px; color:#200E6B ;">
                              {{ this.amazonSpendsToday }}
                            </span>
                            <br />
                            <span v-if="this.amazonSpendsDelta > 0" class="text-success" v-b-tooltip.hover.bottom
                              :title="'Yesterday : ' + this.amazonSpendsYeserday
                                ">
                              <feather-icon icon="ArrowUpIcon" size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                  Math.abs(this.amazonSpendsDelta) }}%
                            </span>
                            <span v-else class="text-danger" v-b-tooltip.hover.bottom :title="'Yesterday : ' + this.amazonSpendsYeserday
                              ">
                              <feather-icon icon="ArrowDownIcon" size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>
                              {{ Math.abs(this.amazonSpendsDelta) }}%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; ">
                      <div style="display: flex; ">
                        <div style="display: flex; justify-content: space-between;">
                          <p style="font-size:11px; color: #7a7a7a; width:100px">
                            <b>Conversions</b><br /><span style="font-weight: bold; font-size: 14px; color:#200E6B ;">
                              {{ this.amazonConversionToday }}
                            </span>
                            <br />

                            <span v-if="this.amazonConversionDelta > 0" class="text-success" v-b-tooltip.hover.bottom
                              :title="'Yesterday : ' + this.amazonConversionYeserday
                                ">
                              <feather-icon icon="ArrowUpIcon" size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                  Math.abs(this.amazonConversionDelta) }}%
                            </span>
                            <span v-else class="text-danger" v-b-tooltip.hover.bottom :title="'Yesterday : ' + this.amazonConversionYeserday
                              ">
                              <feather-icon icon="ArrowDownIcon" size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                  Math.abs(this.amazonConversionDelta) }}%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-link>
          </b-col>
          <b-col :cols="getCols" v-if="this.facebookAdsConnected">
            <b-link :to="`/client/${client.slug}/dashboard/meta-ads`">
              <b-card class="cardContainer todayCardContainer">
                <div v-if="isLiveDataIsLoad" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div class="cardItemContainer" v-else>
                  <span class="todayText">
                    <b-icon icon="circle-fill" font-scale="1" color="#41B06E" class="dotLive" /><span
                      class="blink_text">Today</span></span>
                  <div class="avatar-section"
                    style="display: flex;  align-items: center; justify-content: center; margin-top: -12px;">
                    <b-link :to="`/client/${client.slug}/dashboard/meta-ads`">
                      <b-avatar square variant="white" size="45" src="../../assets/images/logo/meta.png">
                      </b-avatar>
                    </b-link>
                    <b-card-text class="app-title sm ml-1">
                      Meta
                    </b-card-text>
                  </div>
                  <div v-if="this.metaAdsId == ''"
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;">
                    <b-button variant="primary" size="sm" :to="`/client/${client.slug}`">Select an account</b-button>
                  </div>
                  <div v-else
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;">
                    <div style="display: flex;  justify-content: space-evenly; ">
                      <div style=" display: flex; ">
                        <div style=" display: flex; justify-content: space-between; ">
                          <p style="font-size:11px; color: #7a7a7a; width:100px">
                            <b>Spends</b><br /><span style="font-weight: bold; font-size: 14px; color:#200E6B ;">
                              {{ this.metaSpendsToday }}
                            </span>
                            <br />
                            <span v-if="this.metaSpendsDelta > 0" v-b-tooltip.hover.bottom class="text-success"
                              :title="'Yesterday : ' + this.metaSpendsYeserday">
                              <feather-icon icon="ArrowDownIcon" size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>
                              {{ Math.abs(this.metaSpendsDelta) }}%
                            </span>
                            <span v-else class="text-danger" :title="'Yesterday : ' + this.metaSpendsYeserday"
                              v-b-tooltip.hover.bottom>
                              <feather-icon icon="ArrowDownIcon" size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>
                              {{ Math.abs(this.metaSpendsDelta) }}%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; ">
                      <div style="display: flex; ">
                        <div style="display: flex; justify-content: space-between; ">
                          <p style="font-size:11px; color: #7a7a7a; width:100px">
                            <b>
                              <span v-if="client.businessType[0] == 'leadgen'">Leads</span>
                              <span v-else-if="
                                client.businessType[0] == 'ecommerce'
                              ">Purchases</span>
                              <span v-else>Conversions</span></b><br /><span
                              style="font-weight: bold; font-size: 14px; color:#200E6B ;">
                              <span v-if="client.businessType[0] == 'ecommerce'">
                                {{ this.metaWebPurchasesToday }}</span>
                              <span v-else>{{ this.metaConversionToday }}</span>
                            </span>
                            <br />
                            <span v-if="client.businessType[0] == 'ecommerce'">
                              <span v-if="this.metaWebPurchasesDelta > 0" class="text-success" v-b-tooltip.hover.bottom
                                :title="'Yesterday : ' + this.metaWebPurchasesYeserday
                                  ">
                                <feather-icon icon="ArrowDownIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>
                                {{ Math.abs(this.metaWebPurchasesDelta) }}%
                              </span>
                              <span v-else class="text-danger" :title="'Yesterday : ' + this.metaWebPurchasesYeserday
                                " v-b-tooltip.hover.bottom>
                                <feather-icon icon="ArrowDownIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>
                                {{ Math.abs(this.metaWebPurchasesDelta) }}%
                              </span>
                            </span>
                            <span v-else>
                              <span v-if="this.metaConversionDelta > 0" class="text-success" v-b-tooltip.hover.bottom
                                :title="'Yesterday : ' + this.metaConversionYeserday
                                  ">
                                <feather-icon icon="ArrowDownIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>
                                {{ Math.abs(this.metaConversionDelta) }}%
                              </span>
                              <span v-else class="text-danger" :title="'Yesterday : ' + this.metaConversionYeserday
                                " v-b-tooltip.hover.bottom>
                                <feather-icon icon="ArrowDownIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>
                                {{ Math.abs(this.metaConversionDelta) }}%
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-link>
          </b-col>
          <b-col :cols="getCols" v-if="this.dv360Connected">
            <b-link :to="`/client/${client.slug}/dashboard/google-ads`">
              <b-card class="cardContainer todayCardContainer">
                <div v-if="isLiveDataIsLoad" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div class="cardItemContainer " v-else>
                  <span class="todayText">
                    <b-icon icon="circle-fill" font-scale="1" color="#41B06E" class="dotLive" /><span
                      class="blink_text">Today</span></span>
                  <div class="avatar-section"
                    style="display: flex;  align-items: center; justify-content: center; margin-top: -12px;">
                    <b-link :to="`/client/${client.slug}/dashboard/google-ads`">
                      <b-avatar square variant="white" size="40" src="../../assets/images/logo/dv360-logo.svg">
                      </b-avatar>
                    </b-link>
                    <b-card-text class="app-title sm ml-1">
                      DV-360
                    </b-card-text>
                  </div>
                  <div v-if="this.googleAdsId == ''"
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;">
                    <b-button variant="primary" size="sm" :to="`/client/${client.slug}`">Select an account</b-button>
                  </div>
                  <div v-else
                    style="display: flex; justify-content: space-evenly;  gap: 3rem; margin-bottom: -30px; margin-top: 2px;">
                    <div style="display: flex;  justify-content: space-evenly; ">
                      <div style=" display: flex; ">
                        <div style=" display: flex; justify-content: space-between;">
                          <p style="font-size:11px; color: #7a7a7a; width:100px">
                            <b>Spends</b><br /><span style="font-weight: bold; font-size: 14px; color:#200E6B ; ">
                              {{ this.dv360SpendsToday }}
                            </span>
                            <br />
                            <span v-if="this.dv360SpendsToday > 0" class="text-success" v-b-tooltip.hover.bottom
                              :title="'Yesterday : ' + this.dv360SpendsYeserday">
                              <feather-icon icon="ArrowUpIcon" size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                  Math.abs(this.dv360SpendsToday) }}%
                            </span>
                            <span v-else class="text-danger" v-b-tooltip.hover.bottom
                              :title="'Yesterday : ' + this.dv360SpendsYeserday">
                              <feather-icon icon="ArrowDownIcon" size="10"
                                style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                  Math.abs(this.dv360SpendsDelta) }}%
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div style="display: flex; ">
                      <div style="display: flex; ">
                        <div style="display: flex; justify-content: space-between;">
                          <p style="font-size:11px; color: #7a7a7a;width:100px; ">
                            <b> <span>Clicks</span></b><br /><span
                              style="font-weight: bold; font-size: 14px; color:#200E6B ;">
                              {{ this.dv360clicksToday }}
                            </span>
                            <br />
                            <!-- <span v-if="client.businessType[0] == 'ecommerce'">
                              <span v-if="this.googleWebPurchasesDelta > 0" v-b-tooltip.hover.bottom
                                class="text-success" :title="'Yesterday : ' +
                                  this.googleWebPurchasesYeserday
                                  ">
                                <feather-icon icon="ArrowUpIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                    Math.abs(this.googleWebPurchasesDelta) }}%
                              </span>
                              <span v-else class="text-danger" v-b-tooltip.hover.bottom :title="'Yesterday : ' +
                                this.googleWebPurchasesYeserday
                                ">
                                <feather-icon icon="ArrowDownIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                    Math.abs(this.googleWebPurchasesDelta) }}%
                              </span>
                            </span> -->
                            <span>
                              <span v-if="this.dv360clicksDelta > 0" v-b-tooltip.hover.bottom class="text-success"
                                :title="'Yesterday : ' + this.dv360clicksYeserday
                                  ">
                                <feather-icon icon="ArrowUpIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                    Math.abs(this.dv360clicksDelta) }}%
                              </span>
                              <span v-else class="text-danger" v-b-tooltip.hover.bottom :title="'Yesterday : ' + this.dv360clicksYeserday
                                ">
                                <feather-icon icon="ArrowDownIcon" size="10"
                                  style="cursor: pointer; margin-bottom:3px; margin-right:5px"></feather-icon>{{
                                    Math.abs(this.dv360clicksDelta) }}%
                              </span>
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-link>
          </b-col>
          <b-col :cols="getCardCols">
            <b-card class="cardContainer">
              <div v-if="this.showSummaryDashboardLoadingGraph" class="spinnerCentered">
                <multiChannelSpinner />
              </div>
              <div v-else>
                <h3 style="text-align: center; color: grey; font-size: 11px;">
                  Spends
                </h3>
                <apexchart type="line" height="63" :options="chartOptionsBench" :series="seriesCostBenchMark">
                </apexchart>
                <div style="display: flex; justify-content: space-between; font-size: 9px;">
                  <span>{{ this.dateRange.startDate | date }}</span>
                  <span> {{ this.dateRange.endDate | date }}</span>
                </div>
              </div>
            </b-card>
          </b-col>
          <b-col cols-lg="12" style="padding-right: 0px !important; margin: -5px;" >
            <b-card class="cardContainer">
              <div v-if="this.showSummaryDashboardLoadingGraph" class="spinnerCentered">
                <multiChannelSpinner />
              </div>
              <div v-else>
                <h3 style="text-align: center; color: grey;font-size: 11px;">
                  <span v-if="client.businessType[0] == 'leadgen'">Leads</span>
                  <span v-else-if="client.businessType[0] == 'ecommerce'">Purchases</span>
                  <span v-else>Conversions</span>
                </h3>
                <apexchart type="line" height="63" :options="chartOptionsBench" :series="seriesConversionsBenchMark">
                </apexchart>
                <div style="display: flex; justify-content: space-between; font-size: 9px;">
                  <span>{{ this.dateRange.startDate | date }}</span>
                  <span> {{ this.dateRange.endDate | date }}</span>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>

        <div v-if="this.client && this.client.businessType[0] === 'leadgen'"  class="multiChannelSpendCustomizedSheetContainer">
          <div class="multiChannelSpendSheet">
            <div class="date-range-options">
              <label>
                <input type="radio" value="yesterday" v-model="selectedDateRange" @change="handleDateRangeChange" />
                Yesterday
              </label>
              <label>
                <input type="radio" value="weekend" v-model="selectedDateRange" @change="handleDateRangeChange" />
                Weekend
              </label>
              <label>
                <input type="radio" value="custom" v-model="selectedDateRange" @change="handleDateRangeChange" />
                Custom
              </label>
            </div>

            <div v-if="selectedDateRange ==='custom'" class="ChannelSheetDataCustomized">
              <ComparisonDashboardForInsighs 
              :comparisonCustomApiData="comparisonCustomApiData"
      :spinner_comparisonData="spinner_comparisonData"
      :comparisonCustomSpinner="comparisonCustomSpinner"
      :formatDateDisplay="formatDateDisplay"
      @dateRangeUpdated="handleDaterange"
      
      
              />
            </div>
            <div v-if="selectedDateRange === 'yesterday'">
              <ComparisonDashboardForInsighs 
              :comparisonCustomApiData="comparisonDailyApiData"
      :spinner_comparisonData="spinner_comparisonData"
      :formatDateDisplay="formatDateDisplay"
      @dateRangeUpdated="handleDaterange"
              />
            </div>
            <div v-if="selectedDateRange === 'weekend'">

              <ComparisonDashboardForInsighs 
              :comparisonCustomApiData="comparisonWeekendApiData"
      :spinner_comparisonData="spinner_comparisonData"
      :formatDateDisplay="formatDateDisplay"
      @dateRangeUpdated="handleDaterange"
              />
            </div>


          </div>
        </div>
      </div>
      <div style="margin-left:8px; margin-right:8px" v-if="this.tabMultiChannelName === 'ProjectInsights'">
        <div v-if="
            client &&
              client.accessConfiguration &&
              client.accessConfiguration.projects.enabled
          ">
          <b-row v-if="true">
            <b-col cols="12">
              <b-card>
                <div v-if="this.isProjectWiseIsLoad" class="spinnerCentered">
                  <multiChannelSpinner />
                </div>
                <div v-else>
                  <MultiHeaderProjectChannel :projectName="
                      'Project(s) Summary ' +
                        this.formatDateDisplay(this.dateRange.startDate) +
                        ' to ' +
                        this.formatDateDisplay(this.dateRange.endDate)
                    " v-if="
                      client &&
                        client.accessConfiguration &&
                        client.accessConfiguration.projects.enabled
                    " type="overallChannelProjectData" :businessTypeProject="client.businessType[0]"
                    :tableData="projectWiseData" :leadSquaredIntegrations="client.integrations.leadSquared"
                    :salesforceDataFound="salesforceDataFound" :clientName="client.name"
                    :isEmptyFlag="hideOverallProjectsTab" :roleType="client.role" />
                </div>
              </b-card>
            </b-col>
          </b-row>
        </div>
      </div>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'ChannelPerformanceOverview'">
        <b-col cols="12">
          <div>
            <b-card>
              <div v-if="projectOverViewLoad" class="spinnerCentered">
                <multiChannelSpinner />
              </div>
              <div v-else>
                <div style="display: flex; justify-content: space-between;">
                  <b-card-title style="font-size: 14px; font-weight: bold; color: #200E6B;">
                    Campaign Performance Overview
                  </b-card-title>
                  <div style="display: flex; justify-content: space-between; gap: 10px;">
                    <div style="display: flex; justify-content: space-between; gap: 10px;">
                      <div style="margin-top: -10px;">
                        <label>Campaign:</label>
                        <b-form-input id="boomark-search-input" v-model="searchByCampaignName"
                          placeholder="Search By Campaign" />
                      </div>
                      <!-- <v-select style="min-width: 450px;max-width: 550px;" label="name" placeholder="Select Campagin"
                    v-model="selectedFilterCampaign" multiple :options="optionsCampaignNamses" /> -->
                    </div>
                    <div style="display: flex; justify-content: space-between; gap: 10px;">
                      <div style="margin-top: -10px;">
                        <label>Channel(s):</label>
                        <b-form-select v-model="selectedAdsOption" :options="optionsAds"></b-form-select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="projectOverviewTable">
                  <table>
                    <thead>
                      <tr>
                        <th :style="{ backgroundColor: firstColumnColor }">
                          {{ getChannelOverviewData[0].title }}
                        </th>
                        <th v-for="(column,
                          index) in getChannelOverviewData.slice(1)" :key="index" :style="{
                            backgroundColor: column ? column.color : '',
                          }">
                          {{ getChannelOverviewData[index + 1].title }}
                        </th>
                      </tr>
                    </thead>
                    <template v-if="this.client.businessType[0] === 'branding'">
                      <tbody>
                        <tr v-for="(row, rowIndex) in this.displayedOverviewItems" :key="rowIndex">
                          <td :style="
                              getCellStyle(
                                getChannelOverviewData[0].color,
                                row.campaign
                              )
                            " style="font-weight: bold;">
                            {{ row.campaign }}
                          </td>
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[1].color,
                                row.source
                              )
                            ">
                            {{ row.source }}
                          </td>
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[2].color,
                                row.metricsUnformatted.spends
                              )
                            ">
                            {{ row.metrics.spends }}
                          </td>
                          <td style="font-weight:bold" :style="getCellStyle(
                                getChannelOverviewData[3].color,
                                row.metricsUnformatted.impressions
                              )
                            ">
                            {{ row.metrics.impressions }}
                          </td>
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[4].color,
                                row.metricsUnformatted.activeViewImpressions
                              )
                            ">
                            {{ row.metrics.activeViewImpressions }}
                          </td>
                          <!-- <td
                            style="font-weight:bold"
                            :style="
                              getCellStyle(
                                getChannelOverviewData[5].color,
                                row.metricsUnformatted.cpm
                              )
                            "
                          >
                            {{ row.metrics.cpm }}
                          </td> -->
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[6].color,
                                row.metricsUnformatted.videoViews
                              )
                            ">
                            {{ row.metrics.videoViews }}
                          </td>
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[7].color,
                                row.metricsUnformatted.averageCpv
                              )
                            ">
                            {{ row.metrics.averageCpv }}
                          </td>
                          <td style="font-weight:bold" :style="getCellStyle(
                                getChannelOverviewData[8].color,
                                row.metricsUnformatted
                                  .averageImpressionFrequencyPerUser
                              )
                            ">
                            {{ row.metrics.averageImpressionFrequencyPerUser }}
                          </td>
                          <td style="font-weight:bold" :style="getCellStyle(
                            getChannelOverviewData[9].color,
                            row.metricsUnformatted.videoViewRate
                          )
                            ">
                            {{ row.metrics.videoViewRate }}
                          </td>
                          <td style="font-weight:bold" :style="getCellStyle(
                            getChannelOverviewData[10].color,
                            row.metricsUnformatted.clicks
                          )
                            ">
                            {{ row.metrics.clicks }}
                          </td>
                          <td style="font-weight:bold" :style="getCellStyle(
                            getChannelOverviewData[10].color,
                            row.metricsUnformatted.ctr
                          )
                            ">
                            {{ row.metrics.ctr }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                    <template v-else-if="this.client.businessType[0] === 'ecommerce'">
                      <tbody>
                        <tr v-for="(row, rowIndex) in this.displayedOverviewItems" :key="rowIndex">
                          <td :style="
                              getCellStyle(
                                getChannelOverviewData[0].color,
                                row.campaign
                              )
                            " style="font-weight: bold;">
                            {{ row.campaign }}
                          </td>
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[1].color,
                                row.source
                              )
                            ">
                            {{ row.source }}
                          </td>
                          <td style="font-weight:bold" :style="getCellStyle(
                                getChannelOverviewData[4].color,
                                row.metricsUnformatted.spends
                              )
                            ">
                            {{ row.metrics.spends }}

                          </td>
                          <td style="font-weight:bold" :style="getCellStyle(
                                getChannelOverviewData[3].color,
                                row.metricsUnformatted.impressions
                              )
                            ">
                            {{ row.metrics.impressions }}
                          </td>
                          <!-- <td
                            :style="
                              getCellStyle(
                                columns[4].color,
                                row.metrics.impressions
                              )
                            "
                          >
                            {{ row.metrics.impressions }}
                          </td> -->
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[2].color,
                                row.metricsUnformatted.clicks
                              )
                            ">
                            {{ row.metrics.clicks }}
                          </td>

                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[5].color,
                                row.metricsUnformatted.conversions
                              )
                            ">
                            {{ row.metrics.conversions }}
                          </td>
                          <td style="font-weight:bold"
                            :style="getCellStyle(getChannelOverviewData[6].color, row.metricsUnformatted.clicks / row.metricsUnformatted.impressions)">
                            {{row.metrics.ctr}}
                          </td>

                          <td style="font-weight:bold"
                            :style="getCellStyle(getChannelOverviewData[7].color, row.metricsUnformatted.spends / row.metricsUnformatted.clicks)">
                            INR {{ (row.metricsUnformatted.spends / row.metricsUnformatted.clicks).toFixed(2) }}
                          </td>

                        </tr>
                      </tbody>
                    </template>
                    <template v-else>
                      <tbody>
                        <tr v-for="(row, rowIndex) in this.displayedOverviewItems" :key="rowIndex">
                          <td :style="
                              getCellStyle(
                                getChannelOverviewData[0].color,
                                row.campaign
                              )
                            " style="font-weight: bold;">
                            {{ row.campaign }}
                          </td>
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[1].color,
                                row.source
                              )
                            ">
                            {{ row.source }}
                          </td>
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[2].color,
                                row.metricsUnformatted.clicks
                              )
                            ">
                            {{ row.metrics.clicks }}
                          </td>
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[3].color,
                                row.metricsUnformatted.impressions
                              )
                            ">
                            {{ row.metrics.impressions }}
                          </td>
                          <!-- <td
                            :style="
                              getCellStyle(
                                columns[4].color,
                                row.metrics.impressions
                              )
                            "
                          >
                            {{ row.metrics.impressions }}
                          </td> -->
                          <td style="font-weight:bold" :style="getCellStyle(
                                getChannelOverviewData[4].color,
                                row.metricsUnformatted.spends
                              )
                            ">
                            {{ row.metrics.spends }}
                          </td>
                          <td style="font-weight:bold" :style="
                              getCellStyle(
                                getChannelOverviewData[5].color,
                                row.metricsUnformatted.conversions
                              )
                            ">
                            {{ row.metrics.conversions }}
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </table>
                  <div style="justify-content: center; margin-top: 10px; ">
                    <b-pagination v-if="this.fetchOverViewAllData.length > 0" v-model="currentPage"
                      :total-rows="filterDataLength" :per-page="perPage" first-number last-number align="right"
                      prev-class="prev-item" next-class="next-item"></b-pagination>
                  </div>
                  <div v-if="fetchOverViewAllData.length === 0">
                    <div style="padding: 70px 0; text-align: center;">
                      <h4 class="text-primary">No Data Found.</h4>
                    </div>
                  </div>
                </div>
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'ProductOutCome'">
        <b-col cols="12">
          <b-card class="multiHeaderMulti" style="margin-left:2px; margin-right: 2px;">
            <ProjectOutXL @getXLMonthOnChange="getXLMonthDataChange" :isXLDataFetching="isXLDataFetched"
              :maxXLCol="projectOutComeCol" :maxXLRow="projectOutComeRow" :tableDataXL="projectOutComeXL"
              :clientId="client._id" @getXLReport="fetchProjectOutComeXL" />
            <!-- <ProductOutCome :clientID="client._id" :clientName="client.name" :productOutComeData="productOutWiseData" :dataLoadFlag="fetchProductOutWiseData" @callOutComeGetApi="handleCallOutComeGetApiChange"/> -->
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'MTDInsights'">
        <b-col cols="12">
          <b-card class="multiHeaderMulti" style="margin-left:2px; margin-right: 2px;">
            <div style="display: flex; justify-content: space-between;">
              <div>
                <b-card-title>
                  <h3 class="app-title mdsm">MTD Insights</h3>
                </b-card-title>
              </div>
              <div>
                <b-button @click="openViewSheet" size="sm" variant="outline-primary">
                  <img src="@/assets/images/logo/sheets.png" alt="Icon"
                    style="width: 22px; height: 22px; margin-right: 5px;" /><strong>
                    <span v-if="this.sheetUrl.length === 0">Connect</span>
                    <span v-else>Update</span> Your Google Sheet</strong></b-button>
              </div>
            </div>
            <div>
              <div v-if="this.sheetUrl.length === 0" class="spinnerCentered">
                No sheet link found!
              </div>
              <div v-else>
                <!-- <div v-if="isMTDFetched" >
                  <multiChannelSpinner />
                </div> -->
                <div>
                  <div v-if="isMTDFetched" class="spinnerCentered">
                    <multiChannelSpinner />
                  </div>
                  <br />
                  <div v-if="!isMTDFetched">
                    <iframe :src="sheetUrl" width="100%" height="600px" frameborder="0">
                    </iframe>
                  </div>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'AffiliateInsights'">
        <b-col cols="12">
          <b-card class="multiHeaderMulti" style="margin-left:2px; margin-right: 2px;">
            <AffilateInsights :clientID="client._id" @filterAffliatesType="handledAffilatesType"
              @filterAffliatesOnSheet="getClient" @filterAffliatesChannelType="handledAffilatesChannelType"
              :clientName="client.name" :AffilatedData="affilatesOutWiseData"
              :dataLoadAffilatedFlag="fetchAffilatestOutWiseData" :affilatesOptions="affilatesAllOptions"
              @callAffilatesGetApi="handleAffilatesGetApiChange"
              :intialAffilatesWiseOptions="affilatesWiseOptionsIntial" :affilatesWiseOptions="affilatesWiseOptions"
              @callAfflilatesComeGetApi="handleCallAffilatesGetApiChange" :monthlySheetUrl="client.affiliateGSheet" />
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'TargetVSAchieved'">
        <b-col cols="12">
          <b-card>
            <TargetVsAchieved :clientId="client._id" :projectNames="zoneWiseTypeOptions"
              :getTargetVsAchivedData="targetVsAchivedData" :isTargetVsAchievedLoad="zoneWiseTypeOptionsFlag"
              @getChannelType="getChangedChannelTypeTarget" @getTargetTypeChange="getChangedTarget"
              @getTargetProjectNameType="getChangedProjectNameTargetCall" @targetCalCulated="fetchTargetVsAchived" />
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'ZoneInsights'">
        <b-col cols="12">
          <b-card class="multiHeaderMulti" style="margin-left:2px; margin-right: 2px;">
            <div v-if="this.optionsLength === 0" class="spinnerCentered">
              <p class="p-4">No Data found!</p>
              <!-- <multiChannelSpinner /> -->
            </div>
            <div v-else>
              <MultiHeader :clientId="client._id" :clientName="client.name" :reportType="reportModeSelected"
                :zoneWiseType="zoneWiseTypeOptions" :tableData="zoneWiseData" type="zoneWise" selectionType="single"
                :businessTypeProject="client.businessType[0]" channelType="overall"
                :projectNameSummary="'Group(Zone) Insights'" selectBoxType="Select Zone(s)"
                @sourceChangedData="getSourceData" :zoneWiseDataLoading="isZonewiseDatafetched"
                :rangeType="reportModeSelected" @getSubProductOptions="getSubOptions"
                @zoneTypeChangedData="getZoneTypeData" @handleZoneListChange="handleZoneList"
                @filterDataZoneWiseType="handledZoneType" :zoneWiseOptionsLoad="this.optionsLength"
                @openModal="openDataToZonewiseModal" :salesforceDataFound="salesforceDataFound" />
            </div>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'ProductInsights'">
        <b-col cols="12">
          <b-card class="multiHeaderMulti" style="margin-left:2px; margin-right: 2px;">
            <MultiHeader :clientName="client.name" :productAllProjectWiseType="productWiseTypeOptions"
              :tableData="productWiseData" type="productWise" selectionType="multiple"
              @sourceProductChangedData="getProductSourceData" :businessTypeProject="client.businessType[0]"
              :projectNameSummary="'Product Insights'" channelType="overall" selectBoxType="Select Product(s)"
              :productWiseDataLoading="isProductDatafetched" @handleZoneListProjectChange="handleProductProjectList"
              :salesforceDataFound="salesforceDataFound" />
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'Trends'">
        <b-col cols="12">
          <TrendsTab @sourceTrendsChangedData="getTrendsSourceData" :trendOptionsType="zoneWiseTypeOptions"
            :trendsOverallData="TrendsData" :tableTrendsData="TrendsTableDataFetched"
            :ifFetchedTrends="isTrendsDataFetched" :ifClientFetchedTrends="idClientEntireLoad"
            :ifFetchedTableDataTrends="isTrendsTableDataFetched"
            :isQualityVolumeChartDataFetched="isQualityVolumeFetched" :qualityVolumeChartData="isQualityVolumeData"
            :isLeadsDataFetched="isLeadsFetched" :leadsData="LeadsData" :selectedDates="this.formatDateDisplay(this.dateRange.startDate) +
                ' to ' +
                this.formatDateDisplay(this.dateRange.endDate)
            " />
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'CreativeAnalysis'">
        <b-col cols="12">
          <CreativeAnalysis :trendsOverallData="TrendsData" :isLoadCreatives="zoneWiseTypeOptionsFlag"
            :clientId="client._id" :optionsListFlag="zoneWiseTypeOptionsFlag" :optionsList="creativeOptions"
            :creativesData="isCreativesFlag" :graphData="creativesData"
            @changedCreativesProjectName="changeCreativesProjectName"
            @changedCreativeMetricChange="changeCreativeMetricChange" @changeChannelChange="changedChannelChange" />
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'ProjectCreation'">
        <b-col cols="12">
          <ProjectCreations :lastTimeDataFetchedTime="client.updatedAt" :clientID="client._id"
            :facebookAdsAccount="facebookAdsAccount" :googleAdsAccounts="googleAdsAccounts" />
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'CreativeAnalysisTable'">
        <b-col cols="12">
          <b-card>
            <div>
              <b-card-title>
                <h3 class="app-title mdsm">
                  Meta-Ads Campaignwise Creative Analysis
                </h3>
              </b-card-title>
              <b-card-text></b-card-text>
            </div>
            <CreativeAnalysisTable :isAnalysisDataFetched="isCreativesAnalysisData" TableType="creativeAnalysisData"
              :PaidData="creativesAnalysisData" />
            <b-row>
              <b-col cols="9"></b-col>
              <b-col cols="3">
                <div class="d-flex justify-content-between align-items-center" style="margin-right:5px">
                  <b-button variant="link" :disabled="creativeAnalysisPageIndex <= 1" @click="prevCreativePage">
                    <feather-icon icon="ArrowLeftCircleIcon" size="35" />
                  </b-button>
                  <span>Page {{ creativeAnalysisPageIndex }} of
                    {{ creativeAnalysisPageSize }}</span>
                  <b-button variant="link" :disabled="creativeAnalysisPageIndex >= creativeAnalysisPageSize
                    " @click="nextCreativePage">
                    <feather-icon icon="ArrowRightCircleIcon" size="35" />
                  </b-button>
                </div>

                <!-- Pagination Component -->
                <!-- <b-pagination
              v-model="creativeAnalysisPageIndex"
              :total-rows="creativeAnalysisPageSize"
              :per-page="creativeAnalysisPageSize"
              @input="getCreativesAnalysisTableData"
              align="center"
            ></b-pagination> -->
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'FilterCategory'">
        <b-col cols="12">
          <CategoryCreation :clientID="client._id" />
        </b-col>
      </b-row>
      <b-row  v-if="this.tabMultiChannelName === 'FilterData'">
        <b-col cols="12">
          <FilterData :clientID="client._id" :startDate="dateRange.startDate" :endDate="dateRange.endDate"
            :businessType="client.businessType[0]" />
        </b-col>
      </b-row>
      <b-row class="mainpage-ui-alignment-fixes" v-if="this.tabMultiChannelName === 'CampaignFootfallInsights'">
        <b-col cols="12">
          <b-card class="multiHeaderMulti" style="width:100%;margin-left:2px; margin-right: 2px;">
            <FootfallDataInsights :clientId="client._id" :sheetUrl="client.gsheet" :userType="client.role" />
          </b-card>
        </b-col>
      </b-row>
    </div>
    <!-- Spends Tracker modal -->
    <b-modal v-model="addSpendsModal" id="showShopNameModal" hide-footer hide-header centered size="lg">
      <b-card-text>
        <div class="header text-right">
          <feather-icon style="cursor: pointer" @click="closeAddSpendsModal" class="Xicon" icon="XIcon"></feather-icon>
        </div>
        <div class="modal-item">
          <div class="pr-1 edit-form">
            <h6 class="app-title lg">
              {{ this.editTriggerLists ? "Update Your Spends" : "Add a" }}
              transaction to start spends tracking!
            </h6>
          </div>
        </div>
        <div class="col-12 col-md-12 mt-1 col-lg-12 formdiv">
          <b-form-group label-for="Campaign-Reference"><label>Reason for tracking?<span
                class="validation">*</span></label>
            <b-form-input v-model="triggerName" placeholder="Enter reason..." />
          </b-form-group>
        </div>
        <div style="display:flex;gap:140px;padding-left:1rem !important ">
          <div class="spends-radio-label" style="display: flex;align-items: center;">
            <input class="spends-readio-lable-input" style="margin-right: 8px" type="radio"
              v-model="selectedFetchByClient" value="overall"
              :disabled="this.editTriggerLists === true" /><span>Overall</span>
          </div>
          <div class="spends-radio-label" style="display: flex;align-items: center;">
            <input class="spends-readio-lable-input" style="margin-right:8px" type="radio"
              v-model="selectedFetchByClient" value="account"
              :disabled="this.editTriggerLists === true" /><span>Account</span>
          </div>
        </div>

        <!-- {{ client.googleAds }} -->
        <!-- {{ client.facebook }} -->
      </b-card-text>
      <div class="row  pt-0 pr-1 pl-1 pb-0 addSpendsFormContainer">
        <div class="col-12 col-md-4 mb-1 formdiv">
          <div>
            <label>Enter amount<span class="validation">*</span></label>
            <b-form-input id="project-name" type="number" v-model="spendsAmount" placeholder="" required></b-form-input>
          </div>
        </div>
        <div class="col-12 col-md-8 formdiv ">
          <b-form-group style="margin-top: 0.2rem;" label="Select Channels" label-for="spend-channel-select">
            <v-select id="spend-channel-select" placeholder="Select Channel(s)" v-model="selectedSpendsChannel"
              :multiple="true" :options="channelOptions" :clearable="true" :deselect-from-dropdown="true" :class="isTrackingDateDisabled
                  ? 'campaigns-select-disable-state'
                  : 'campaigns-select'
              " @input="
                selectedFetchByClient === 'overall' ? fetchAllCampaign() : ''
              " />
          </b-form-group>
        </div>
        <div class="col-12 formdiv">
          <b-form-group v-if="fetchByClient === 'account'" label="Account" label-for="Campaign-select">
            <v-select label="Account(s)" v-model="selectedAccount" placeholder="Select Account(s)" :multiple="true"
              :options="this.accountOverallNames" :clearable="true" :deselectFromDropdown="true" :closeOnSelect="false"
              :disabled="
                this.selectedSpendsChannel.length === 0 &&
                  fetchByClient === 'account'
              " />
          </b-form-group>
        </div>
        <div class="col-12 formdiv">
          <b-form-group border:1px solid label="Campaign(s)" label-for="Campaign-select">
            <div class="spendTracker_campaign_options">
              <v-select label="name" v-model="selectedCampaigns" placeholder="Select Campaign(s)" :multiple="true"
                :options="this.campaignOverallNames" :clearable="true" :deselectFromDropdown="true"
                :closeOnSelect="false" :disabled="
                  selectedFetchByClient === 'account'
                    ? isChannelsDisabled || this.selectedAccount.length === 0
                    : isChannelsDisabled
                " />
            </div>
          </b-form-group>
        </div>
        <div class="col-12 col-md-12 col-lg-4 formdiv">
          <label>Received Date<span class="validation">*</span></label>
          <b-form-datepicker id="received-date" class="date-range-formatting" :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }" locale="en" v-model="receivedDate" size="sm"></b-form-datepicker>
        </div>
        <div class="col-12 col-md-12 col-lg-4 formdiv">
          <label>Start Tracking From<span class="validation">*</span></label>
          <b-form-datepicker id="start-tracking-date" v-model="startTrackingDate" size="sm" :date-format-options="{
            year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }" locale="en"></b-form-datepicker>
        </div>
        <div class="col-12 col-md-12 col-lg-4 mb-1 formdiv">
          <label>End Tracking At<span class="validation">*</span></label>
          <b-form-datepicker id="end-tracking-date" v-model="endTrackingDate" size="sm" :date-format-options="{
            year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }" locale="en" :min="startTrackingDate"></b-form-datepicker>
        </div>
        <div v-if="isWhatsAppNotificationEnable" class="col-12 col-md-12 mb-1 formdiv">
          <label>Whatsapp Numbers<span class="validation">*</span></label>
          <b-form-tags placeholder="Enter Phone Number(s)" v-model="whatsAppNumbers" input-id="whatsapp-numbers"
            remove-on-delete :tag-validator="tagValidator" :state="state" add-button-variant="primary"
            invalid-tag-text="Please enter a valid phone number." />
        </div>
        <div class="col-12 col-md-12 formdiv">
          <div style="margin-bottom: 0px !important" label-for="is-whatsapp-enabled">
            <label>Whatsapp Numbers<span class="validation">*</span></label>

            <b-form-checkbox v-model="isWhatsAppNotificationEnable" switch></b-form-checkbox>
          </div>
        </div>
        <div class="col-12 col-md-12 tracker-btn-container formdiv text-right">
          <!-- <b-button
            v-if="this.hasActiveTransaction"
            variant="primary"
            size="lg"
            class="w-9 saveButton spend-modal-btn"
            @click="updateSpendScheduledTracker"
            :disabled="isUpdatingTransaction"
          >
            <span> Edit </span>
          </b-button> -->
          <b-button variant="primary" size="lg" class=" w-9 saveButton spend-modal-btn" @click="getAllCampaignId()"
            :disabled="isCreateButtonDisabled">
            <span>
              <span v-if="this.editTriggerLists == true"> Update</span>
              <span v-else> Save</span>
              <b-spinner v-if="addingSpends" small></b-spinner></span>
          </b-button>
        </div>
      </div>
      <!-- {{ spendsTrasactionData }} -->
      <div style="display: flex; justify-content: center;padding: 2px;"></div>
      <div v-if="this.isSpendsLoading" class="spinnerCentered">
        <span>
          <multiChannelSpinner />
        </span>
      </div>
      <!-- <div
        v-else-if="
          Object.keys(this.spendsTrasactionData).length > 0 &&
            this.filteredDataBasedOnAct
        "
        class="spinnerCentered"
      >
        <span>No transactions found!</span>
      </div> -->
      <!-- v-if="
            this.spendsTrasactionData && this.spendsTrasactionData.length > 0
          " -->
      <!-- <div v-else-if="Object.keys(this.spendsTracker).length === 0">
            Tr
      </div> -->
      <div v-else>
        <div v-if="Object.keys(this.spendsTracker).length > 0">
          <div>
            <div style="display: flex; justify-content: space-between; font-size: 13px;">
              <p><strong>Spends Triggers History</strong></p>
              <p>
                <strong>Tracking start date:</strong>
                {{
                  this.startDateForTriggers &&
                this.startDateForTriggers.length > 0
                ? this.startDateForTriggers
                : "NA"
                }},
                <span>
                  <span v-if="
                      this.spendsCurrencyOnTop &&
                        this.spendsCurrencyOnTop.length > 0
                    ">
                    <strong>Total Amount</strong>:{{ this.spendsCurrencyOnTop }}
                    {{ this.spendsAmountOnTop }}
                  </span>
                </span>
              </p>
            </div>
            <div style="overflow: auto !important; padding: 0px !important ;" class="transaction-table">
              <b-table striped hover :items="paginatedScheduleTriggerHistoryData" :fields="fields"
                class="custom-spends-table">
                <!-- Status Column -->
                <template v-slot:cell(status)="row">
                  <b-badge v-if="row.item.status === 'Ongoing'" pill variant="primary">
                    ONGOING
                  </b-badge>
                  <b-badge v-if="row.item.status === 'Upcoming'" pill variant="success">
                    UPCOMING
                  </b-badge>
                  <b-badge v-if="row.item.status === 'Ended'" pill variant="danger">
                    ENDED
                  </b-badge>
                </template>
                <template v-slot:cell(disableTrigger)="row">
                  <b-form-checkbox :checked="row.item.isActive ? true : false" switch
                    @change="toggleTrigger(row.item)"></b-form-checkbox>
                </template>
                <!--   @change="toggleTriggerStatus(row.item)" -->
                <!-- Actions Column -->
                <template v-slot:cell(actions)="row">
                  <div class="action-buttons">
                    <span @click="openEditSpendsModal(row.item)" class="action-icon">
                      <feather-icon icon="EditIcon" size="18"></feather-icon>
                    </span>

                    <span @click="openViewSpendsModal(row.item)" class="action-icon">
                      <feather-icon color="green" icon="EyeIcon" size="18"></feather-icon>
                    </span>
                  </div>
                </template>
              </b-table>
            </div>
            <b-pagination class="mt-1" v-model="SpendsTriggersCurrentPage"
              :total-rows="filteredDataBasedOnIsActive.length" :per-page="SpendsTriggersItems"
              aria-controls="spends-table" align="center" first-number last-number prev-class="prev-item"
              next-class="next-item"></b-pagination>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- Edit Spends Tracker modal -->
    <b-modal v-model="editSpendsModal" id="showShopNameModal" hide-footer hide-header centered size="sm">
      <b-card-text>
        <div class="header text-right">
          <feather-icon style="cursor: pointer" @click="closeEditSpendsModal" class="Xicon" icon="XIcon"></feather-icon>
        </div>
        <div class="modal-item">
          <div class="pr-1 edit-form">
            <div class="pr-1 edit-form">
              <h6 class="app-title lg" v-if="this.savenewSpendsModal">
                Add budget to track spends
              </h6>
              <h6 class="app-title lg" v-else>
                Edit budget to track spends
              </h6>
            </div>
          </div>
        </div>
      </b-card-text>
      <b-row style="padding:6px;">
        <b-col cols="12" style="padding-bottom:6px;">
          <label>Enter amount<span class="validation">*</span></label>
          <b-form-input id="project-name" v-model="editSpendsAmount" placeholder="" type="number"
            required></b-form-input>
        </b-col>
        <b-col cols="12">
          <label>Received Date<span class="validation">*</span></label>
          <!-- <b-form-datepicker
              id="rules-select"
              v-model="receivedDate"
              required
            ></b-form-datepicker> -->
          <b-form-datepicker class="date-range-formatting" :date-format-options="{
              year: 'numeric',
              month: 'short',
              day: '2-digit',
              weekday: 'short',
            }" locale="en" v-model="receivedDate" id="datepicker-sm" size="sm"></b-form-datepicker>
        </b-col>
        <!-- <b-col cols="12" v-if="isEditSpends">
          <b-form-group label="*Start Tracking From" label-for="tag-input">
            <b-form-datepicker  v-model="startTrackingDate" id="datepicker-sm" size="sm" :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
      locale="en"></b-form-datepicker>
          </b-form-group> -->
        <!-- </b-col> -->
        <b-col cols="12">
          <b-button v-if="savenewSpendsModal" style="float: right;" variant="primary" class="mt-2"
            @click="addTransaction" :disabled="isEditButtonDisabled">
            <b-col class="d-flex justify-content-center align-items-center">
              <span>Save <b-spinner v-if="isEditSpends" small></b-spinner></span>
              <!-- <b-spinner v-if="isCreatedProject" small></b-spinner> -->
            </b-col>
          </b-button>
          <b-button v-else style="float: right;" variant="primary" class="mt-2" @click="updateSpendsTransaction"
            :disabled="isEditButtonDisabled">
            <b-col class="d-flex justify-content-center align-items-center">
              <span>Update <b-spinner v-if="isEditSpends" small></b-spinner></span>
              <!-- <b-spinner v-if="isCreatedProject" small></b-spinner> -->
            </b-col>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal v-model="showTransactions" id="showShopNameModal" hide-footer hide-header centered size="sm">
      <b-card-text>
        <div class="header text-right">
          <feather-icon @click="closeTranscationsModal" style="cursor: pointer" class="Xicon"
            icon="XIcon"></feather-icon>
        </div>
        <div class="modal-item">
          <div class="pr-1 edit-form">
            <div style="display:flex; justify-content: space-between;">
              <h6 class="app-title lg">
                Transcations History
              </h6>
              <feather-icon v-if="this.status === 'Ongoing'" class="ml-1 text-primary"
                v-b-tooltip.hover.top="'Add More Transaction'" @click="AddSpendsModal()" icon="PlusCircleIcon"
                size="16" />
            </div>
          </div>
        </div>
      </b-card-text>
      <div v-if="this.spendsTrasactionFetch" class="spinnerCentered">
        <multiChannelSpinner />
      </div>
      <div v-else-if="this.transactions && this.transactions.length === 0" class="spinnerCentered">
        No transactions found.
      </div>
      <div v-else>
        <b-table :items="paginatedTriggerSpendsHistoryData" :fields="trascationHistoryFields"
          class="custom-spends-table">
          <template v-slot:cell(receivedAt)="row">
            {{ formatDate(row.item.receivedAt) }}
          </template>
          <!-- Actions Column -->
          <template v-slot:cell(actions)="row">
            <div class="action-buttons" style="display: flex;justify-content: space-around;">
              <span @click="openEditSpendsTranscationModal(row.item)" class="action-icon">
                <feather-icon color="blue" icon="EditIcon" size="18"></feather-icon>
              </span>
              <span @click="openDeleteSpendsModal(row.item)" class="action-icon">
                <feather-icon color="red" icon="TrashIcon" size="18"></feather-icon>
              </span>
            </div>
          </template>
        </b-table>

        <b-pagination v-if="this.transactions.length > 0" v-model="TriggersHistoryCurrentPage"
          :total-rows="transactions.length" :per-page="TriggersHistoryItems" first-number last-number align="center"
          prev-class="prev-item" next-class="next-item"></b-pagination>
      </div>
    </b-modal>

    <b-modal v-model="spendsDeleteModal" id="showShopNameModal" hide-footer hide-header centered size="sm">
      <div class="header text-right">
        <feather-icon style="cursor: pointer" @click="closeDeleteSpendsModal" class="Xicon" icon="XIcon"></feather-icon>
      </div>
      <div v-if="!toggle" class="d-block text-center">
        <h6>Are you sure you want to delete the transaction entry?</h6>
      </div>
      <div v-else class="d-block text-center">
        <h6>Are you sure you want to update the tracker status?</h6>
      </div>
      <div class="popup-modal ml-2">
        <b-button class="mt-2" @click="closeDeleteSpendsModal" style="min-width: 150px">Cancel</b-button>
        <b-button v-if="toggle" style="min-width: 150px" class="ml-1 mt-2" variant="danger"
          @click="toggleTriggerStatus()">
          Yes<b-spinner v-if="isDeleteSpends" small></b-spinner>
        </b-button>
        <b-button v-else style="min-width: 150px" class="ml-1 mt-2" variant="danger" @click="deleteASpends()">
          Delete <b-spinner v-if="isDeleteSpends" small></b-spinner>
        </b-button>
      </div>
      <div></div>
    </b-modal>
    <b-modal v-model="addDataToZonewiseModal" id="showShopNameModal" hide-footer hide-header centered size="lg">
      <b-card-text>
        <div style="display: flex; justify-content:space-between; padding:10px">
          <h5 class="app-title mdsm">
            Get Target VS Achieved Value:
          </h5>
          <div class="header text-right">
            <feather-icon style="cursor: pointer" @click="CloseDataToZonewiseModal" class="Xicon"
              icon="XIcon"></feather-icon>
          </div>
        </div>
      </b-card-text>
      <div v-if="isFetchTargetValue" class="spinnerCentered" style="margin-top: 15%;">
        <multiChannelSpinner />
      </div>
      <div v-else style="padding: 5px 30px">
        <b-row>
          <b-col cols="3" style="margin-top: 25px;">
            <h5>Targeted Value:</h5>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Spends:" label-for="input-1">
              <b-form-input id="input-1" type="number" v-model="targetSpends" placeholder="Enter Spends"
                required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Leads:" label-for="input-1">
              <b-form-input id="input-1" type="number" v-model="targetLeads" placeholder="Enter Leads"
                required></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="CPL:" label-for="input-1">
              <b-form-input id="input-1" type="number" placeholder="Enter CPL" v-model="targetCpl"
                required></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col cols="3" style="margin-top: 25px;">
            <h5>Achieved Value:</h5>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Spends:" label-for="input-1">
              <b-form-input id="input-1" type="number" v-model="achievedSpends" placeholder="Enter Spends" required
                disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Leads:" label-for="input-1">
              <b-form-input id="input-1" type="number" v-model="achievedLeads" placeholder="Enter Leads" required
                disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="CPL:" label-for="input-1">
              <b-form-input id="input-1" type="number" v-model="achievedCpl" placeholder="Enter CPL" required
                disabled></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row style="margin-right: -5px; margin-top:5px">
          <div style="display: flex; width: 100%;">
            <div style="flex-grow: 1;"></div>
            <b-button variant="primary" style="margin-left: auto;" :disabled="this.targetCpl === 0 ||
                  this.targetLeads === 0 ||
                  this.targetLeads === 0
              " @click="calculateTargetVsAchieved">
              Calculate Difference
              <b-spinner v-if="isCalculating" small></b-spinner>
            </b-button>
          </div>
        </b-row>
        <hr v-if="isCalulatedSuccessFully" />
        <b-row v-if="isCalulatedSuccessFully">
          <b-col cols="3" style="margin-top: 25px;">
            <h5>Difference :</h5>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Spends:" label-for="input-1">
              <b-form-input id="input-1" type="number" v-model="differenceSpends" placeholder="Enter Spends" required
                disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Leads:" label-for="input-1">
              <b-form-input id="input-1" type="number" v-model="differenceLeads" placeholder="Enter Leads" required
                disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="CPL:" label-for="input-1">
              <b-form-input id="input-1" type="number" v-model="differenceCpl" placeholder="Enter CPL" required
                disabled></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isCalulatedSuccessFully">
          <b-col cols="3" style="margin-top: 25px;">
            <h5>Difference % :</h5>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Spends:" label-for="input-1">
              <b-form-input id="input-1" type="number" v-model="differencePerSpends" placeholder="Enter Spends" required
                disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="Leads:" label-for="input-1">
              <b-form-input id="input-1" type="number" placeholder="Enter Leads" v-model="differencePerLeads" required
                disabled></b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="3">
            <b-form-group id="input-group-1" label="CPL:" label-for="input-1">
              <b-form-input id="input-1" type="email" placeholder="Enter CPL" v-model="differencePerCpl" required
                disabled></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <hr />
      <b-row class="mt-2 p-1">
        <div style="display: flex; width: 100%;">
          <div style="flex-grow: 1;"></div>
          <b-button variant="primary" style="margin-left: auto;" @click="CloseDataToZonewiseModal">
            Done
          </b-button>
        </div>
      </b-row>
    </b-modal>
    <transition name="modal">
      <b-modal id="modal-center" hide-header hide-footer centered size="xs" v-model="dataSyncing">
        <b-card-text>
          <div style="display: flex; justify-content:space-between; padding:2px 0px">
            <div>
              <h6>Sync Your Data</h6>
            </div>
            <div style="float: right">
              <b-button variant="flat-primary" @click="closeDataSyncModal"
                style="margin-top: -10px; margin-right: -18px"><feather-icon icon="XIcon"></feather-icon></b-button>
            </div>
          </div>
        </b-card-text>
        <div>
          <b-row style="padding: 0 10px">
            <b-col cols="12">
              <div v-if="
                this.client &&
                this.client.integrations &&
                this.client.integrations.googleAds
                ">
                <label for="business-type">Select Duration for Google-Ads data</label>
                <v-select :clearable="false" label="name" v-model="googleSync" :options="syncType" />
              </div>
              <div v-if="
                  this.client &&
                    this.client.integrations &&
                    this.client.integrations.facebook
                " style="margin-top: 10px;">
                <label for="business-type">Select Duration for Meta-Ads data</label>
                <v-select :clearable="false" label="name" v-model="metaSync" :options="syncType" />
              </div>
            </b-col>
          </b-row>
        </div>
        <div style="display: flex; justify-content:center; gap:10px" class="mt-2">
          <b-button variant="outline-primary" class="custom-primary-with-border" @click="closeDataSyncModal" size="sm">
            Cancel
          </b-button>
          <b-button :disabled="this.metaSync.value.length === 0 &&
                this.googleSync.value.length === 0
            " variant="primary" @click="syncData" size="sm">
            Proceed
            <b-spinner v-if="isGoogleAdsDataSyncing || isMetaAdsDataSyncing" small />
          </b-button>
        </div>
      </b-modal>
    </transition>
    <transition name="modal">
      <b-modal id="modal-center" hide-header hide-footer centered size="xs" v-model="submitSheetModal">
        <b-card-text>
          <div style="display: flex; justify-content:space-between; padding:2px 0px">
            <div>
              <h6>
                <img src="@/assets/images/logo/sheets.png" alt="Icon"
                  style="width: 17px; height: 17px; margin-right: 2px;" />Connect Your Google Sheet
              </h6>
            </div>
            <div style="float: right">
              <b-button variant="flat-primary" @click="closeViewSheet"
                style="margin-top: -10px; margin-right: -18px"><feather-icon icon="XIcon"></feather-icon></b-button>
            </div>
          </div>
        </b-card-text>
        <div>
          <b-row style="padding: 0 10px">
            <b-col cols="12">
              <label>Sheet Url</label>
              <b-form-input v-model="sheetUrlSubmit"> </b-form-input>
            </b-col>
          </b-row>
        </div>
        <div style="display: flex; justify-content:center; gap:10px" class="mt-2">
          <b-button variant="outline-primary" class="custom-primary-with-border" @click="closeViewSheet" size="sm">
            Cancel
          </b-button>
          <b-button variant="primary" @click="submitSheet" :disabled="this.sheetUrlSubmit.length === 0" size="sm">
            Proceed <b-spinner v-if="isSheetUrlSubmit" small />
          </b-button>
        </div>
      </b-modal>
    </transition>
  </div>
</template>

<script>
import {
  BOverlay,
  BCarousel,
  BCarouselSlide,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BRow,
  BCol,
  BFormSelect,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BCardTitle,
  BPagination,
  BLink,
  BFormCheckbox,
  BBreadcrumb,
  BBreadcrumbItem,
  BIcon,
  BSpinner,
  BFormGroup,
  BFormInput,
  BTable,
  BFormDatepicker,
  BBadge,
  BTabs,
  BTab,
  BFormRadio,
  BInputGroup,
  BFormTags,
} from "bootstrap-vue";
import FootfallDataInsights from "./FootfallDataInsights.vue";
import ProjectCreations from "./ProjectCreations.vue";
import CreativeAnalysisTable from "../Client/CreativeAnalysisTable.vue";
import ProductOutCome from "./ProductOutCome.vue";
import AffilateInsights from "./AffilateInsights.vue";
import TrendsTab from "./TrendsTab.vue";
import CreativeAnalysis from "./CreativeAnalysis.vue";
import MultiHeader from "./MultiHeader.vue";
import Spinner from "@/components/client/Spinner.vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import ProjectOutXL from "../clientFeature/ProjectOutXL.vue";
import VueApexCharts from "vue-apexcharts";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
const $trackBgColor = "#EBEBEB";
import millify from "millify";
import axios from "axios";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserToken } from "@/auth/utils";
import vSelect from "vue-select";
import MultiHeaderProjectChannel from "../clientFeature/MultiheaderProjectChannel.vue";
import client from "@/router/client";
import { data } from "vue2-dropzone";
import TargetVsAchieved from "./TargetVsAchieved.vue";
import Cookies from "js-cookie";
import useJwt from "@/auth/jwt/useJwt";
import validator from "validator";
import { useResponsiveAppLeftSidebarVisibility } from "@/@core/comp-functions/ui/app";
import CategoryCreation from "./CategoryCreation.vue";
import FilterData from "./FilterData.vue";
import store from "@/store";
import { color } from "echarts/lib/theme/light";
import ComparisonDashboardForInsighs from '../../components/client/ComparisonDashboardForInsighs.vue';
export default {
  data() {
    return {

      selectedDateRange: "yesterday",
      comparisonCustomSpinner: false,
      selectorDirections: {},
      spinner_comparisonData: false,
      opens: true,
      dateRange1: {
        startDate: moment().subtract(14, "days").format("YYYY-MM-DD"), // 14 days ago
        endDate: moment().subtract(8, "days").format("YYYY-MM-DD"), // 8 days ago
      },
      dateRange2: {
        startDate: moment().subtract(7, "days").format("YYYY-MM-DD"), // 7 days ago
        endDate: moment().subtract(1, "days").format("YYYY-MM-DD"), // 1 day ago (yesterday)
      },
      switchChannelSheetData: true,
      multiChannelSpendsChartOptions: {
        chart: {
          type: "bar",
          height: 100,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: { horizontal: false, columnWidth: "10%" },
        },
        xaxis: { categories: ["Yesterday", "Day Before Yesterday"] },
        dataLabels: { enabled: false },
        colors: ["#008FFB", "#00E396"], // Customize colors
        legend: { position: "bottom" },
      },
      fetchData: {
        comparison_type: "daily",
        date_range: {
          current_period: { start: "2025-03-20", end: "2025-03-20" },
          previous_period: { start: "2025-03-19", end: "2025-03-19" },
        },
        metrics: {
          overall: {
            spend: { current: 1000, previous: 950, change: "+5.3%" },
            clicks: { current: 1500, previous: 1700, change: "-11.8%" },
            leads: { current: 50, previous: 60, change: "-16.7%" },
          },
          meta: {
            spend: { current: 500, previous: 450, change: "+11.1%" },
            clicks: { current: 800, previous: 900, change: "+11.1%" },
            leads: { current: 25, previous: 30, change: "-16.7%" },
          },
          googleads: {
            spend: { current: 600, previous: 650, change: "-7.7%" },
            clicks: { current: 1200, previous: 1300, change: "-7.7%" },
            leads: { current: 40, previous: 50, change: "+20%" },
          },
        },
        meta: {
          spend: {
            yesterday: { current: 1000, previous: 950, change: "+5.3%" },
            dayBeforeYesterday: {
              current: 950,
              previous: 900,
              change: "+5.6%",
            },
          },
          clicks: {
            yesterday: { current: 1500, previous: 1700, change: "-11.8%" },
            dayBeforeYesterday: {
              current: 1600,
              previous: 1800,
              change: "-11.1%",
            },
          },
          leads: {
            yesterday: { current: 50, previous: 60, change: "-16.7%" },
            dayBeforeYesterday: { current: 55, previous: 65, change: "-15.4%" },
          },
        },
        googleads: {
          spend: {
            yesterday: { current: 1000, previous: 950, change: "+5.3%" },
            dayBeforeYesterday: {
              current: 980,
              previous: 940,
              change: "+4.3%",
            },
          },
          clicks: {
            yesterday: { current: 1500, previous: 1700, change: "-11.8%" },
            dayBeforeYesterday: {
              current: 1550,
              previous: 1650,
              change: "-6.1%",
            },
          },
          leads: {
            yesterday: { current: 50, previous: 60, change: "-16.7%" },
            dayBeforeYesterday: { current: 52, previous: 62, change: "-16.1%" },
          },
        },
        overall: {
          spend: {
            yesterday: { current: 2000, previous: 1900, change: "+5.3%" },
            dayBeforeYesterday: {
              current: 1900,
              previous: 1800,
              change: "+5.6%",
            },
          },
          clicks: {
            yesterday: { current: 3000, previous: 3400, change: "-11.8%" },
            dayBeforeYesterday: {
              current: 3100,
              previous: 3300,
              change: "-6.1%",
            },
          },
          leads: {
            yesterday: { current: 100, previous: 120, change: "-16.7%" },
            dayBeforeYesterday: {
              current: 110,
              previous: 130,
              change: "-15.4%",
            },
          },
        },
      },
      comparisonWeekendApiData: {},
      comparisonDailyApiData: {},
      comparisonCustomApiData: {},
      performanceInsights: [],
      performanceData: [
        {
          channel: "Overall",
          metric: "Spend",
          yesterday: "$10,000",
          dayBeforeYesterday: "$9,500",
          change: "+5.3%",
        },
        {
          channel: "Meta",
          metric: "Spend",
          yesterday: "$15,000",
          dayBeforeYesterday: "$16,000",
          change: "-6.3%",
        },
        {
          channel: "Google",
          metric: "Spend",
          yesterday: "$600",
          dayBeforeYesterday: "$650",
          change: "-7.7%",
        },
        {
          channel: "Overall",
          metric: "Clicks",
          yesterday: "$6,000",
          dayBeforeYesterday: "$5,800",
          change: "+3.4%",
        },
        {
          channel: "Meta",
          metric: "Clicks",
          yesterday: "9,000",
          dayBeforeYesterday: "9,500",
          change: "-5.3%",
        },
        {
          channel: "Google",
          metric: "Clicks",
          yesterday: "400",
          dayBeforeYesterday: "430",
          change: "-7.0%",
        },
        {
          channel: "Overall",
          metric: "Leads",
          yesterday: "4,000",
          dayBeforeYesterday: "3,700",
          change: "+8.1%",
        },
        {
          channel: "Meta",
          metric: "Leads",
          yesterday: "6,000",
          dayBeforeYesterday: "6,500",
          change: "-7.7%",
        },
        {
          channel: "Google",
          metric: "Leads",
          yesterday: "200",
          dayBeforeYesterday: "220",
          change: "-9.1%",
        },
      ],
      SpendsTriggersCurrentPage: 1,
      SpendsTriggersItems: 10,
      TriggersHistoryCurrentPage: 1,
      TriggersHistoryItems: 10,
      selectedCampaignData: {},
      googleAdsspenddata: "",
      metaAdsspenddata: "",
      otherAdsspenddata: "",
      toggledata: [],
      toggle: false,
      startDateForTriggers: "",
      endDateForTriggers: "",
      allSpendTrackersSpentSoFarFormatted: 0,
      allSpendTrackersRemainingBudgetFormatted: 0,
      allSpendTrackersPlannedBudgetFormatted: 0,
      totalActiveTriggers: 0,
      status: "",
      storeGoogleAccountCampaign: {},
      storeMetaAccountCampaign: {},
      metaAccountCampaiginName: [],
      googleAccountCampaiginName: [],
      triggerName: "",
      transcationId: "",
      triggerId: "",
      transactions: [],
      trascationHistoryFields: [
        { key: "amount", label: "Enter Amount" },
        { key: "receivedAt", label: "Received Date" },
        { key: "actions", label: "Actions" },
      ],
      showTransactions: false,
      editTriggerLists: false,
      // AccountDatavalues
      fetchByClient: "overall",
      isExternalUser: "false",
      storeAccount: {
        Google: [],
        Meta: [],
      },
      allAccountData: {},
      accountNames: [],
      selectedAccount: [],
      AmountIdList: {},

      monthlyAffSheet: "",
      spendScheduledTrackerId: "",
      isUpdatingTransaction: false,
      isMTDFetched: true,
      sheetUrlSubmit: "",
      isSheetUrlSubmit: false,
      submitSheetModal: false,
      sheetUrl: "",
      isGoogleAdsDataSyncing: false,
      isMetaAdsDataSyncing: false,
      syncTypeValue: "",
      selectedSyncType: 1,
      isDataSyncing: false,
      dataSyncing: false,
      syncType: [
        { name: "Please Select Duration", value: "" },
        { name: "Last 7 Days", value: "Last_7_DAYS" },
        { name: "Last 30 Days", value: "Last_30_DAYS" },
        { name: "Last 60 Days", value: "Last_60_DAYS" },
        { name: "Last 90 Days", value: "Last_90_DAYS" },
      ],
      googleSync: { name: "Please Select Duration", value: "" },
      metaSync: { name: "Please Select Duration", value: "" },
      creativeAnalysisPageIndex: 1,
      creativeAnalysisPageSize: 0,
      creativesAnalysisData: [],
      isCreativesAnalysisData: false,
      searchTerm: "",
      isKeywordDataFetched: false,
      facebookAdsAccount: {},
      googleAdsAccounts: {},
      isNewUser: null,
      selectedXLMonth: new Date().getMonth(),
      selectedXLYear: "2024",
      projectOutComeXL: {},
      projectOutComeCol: 0,
      projectOutComeRow: 0,
      isXLDataFetched: false,
      targetType: "custom",
      channelType: "all",
      getChangedProjectNameTarget: "all",
      targetVsAchivedData: [],
      isTargetValueFetched: false,
      isCalulatedSuccessFully: false,
      isCalculating: false,
      selectedTargetVsAchivedVal: "",
      selectedZoneDate: "",
      achievedCpl: 0,
      achievedSpends: 0,
      achievedLeads: 0,
      targetCpl: 0,
      targetSpends: 0,
      targetLeads: 0,
      differenceCpl: 0,
      differenceSpends: 0,
      differenceLeads: 0,
      differencePerCpl: 0,
      differencePerSpends: 0,
      differencePerLeads: 0,
      isFetchTargetValue: false,
      isCalculatedTargetVsAchieved: false,
      addDataToZonewiseModal: false,
      productOutWiseData: [],
      affilatesWiseOptions: [],
      affilatesWiseOptionsIntial: "",
      affilatesAllOptions: [],
      fetchProductOutWiseData: false,
      fetchAffilatestOutWiseData: false,
      affilatesOutWiseData: [],
      selectedProductSourceType: "all",
      reportModeSelected: "daily",
      reportModeAffiliateSelected: "monthly",
      productProjectNamesChange: [],
      productProjectIntialNamesChange: [],
      productWiseTypeOptions: [],
      intiallOptionsForGroup: [],
      initiallProjectName: "",
      CreativesProjectName: "",
      selectedCreativesMetricType: "",
      selectedCreativesChannelType: "",
      creativeOptions: [],
      isCreativesFlag: false,
      creativesData: [],
      TrendsData: [],
      TrendsTableDataFetched: [],
      isTrendsDataFetched: false,
      isTrendsTableDataFetched: false,
      isQualityVolumeFetched: false,
      isLeadsFetched: false,
      LeadsData: [],
      isQualityVolumeData: [],
      optionsLength: 0,
      subProductOptions: "all",
      zoneListChange: [],
      selectedProductType: "all",
      zoneWiseTypeOptions: null,
      zoneWiseTypeOptionsFlag: false,
      searchByCampaignName: "",
      // selectedFilterCampaign:[],
      isProductDatafetched: false,
      productWiseData: [],
      zoneWiseData: [],
      tabMultiChannelName: "",
      conditionalSpends: "",
      spendsMessage: "",
      spendsTracker: {},
      isSpendsLoading: false,
      isZonewiseDatafetched: false,
      isEditSpends: false,
      editSpendsAmount: 0,
      spendsAmountOnTop: 0,
      spendsCreatedAtOnTop: "",
      spendsCurrencyOnTop: "",
      isDeleteSpends: false,
      selectedTrasactionId: "",
      addingSpends: false,
      transactionStarts: "",
      spendsTrasactionFetch: false,
      spendsTrasactionData: [],
      spendsDeleteModal: false,
      editSpendsModal: false,
      spendsAmount: 0,
      receivedDate: new Date().toISOString().substr(0, 10),
      startTrackingDate: new Date().toISOString().substr(0, 10),
      endTrackingDate: new Date().toISOString().substr(0, 10),
      whatsAppNumbers: [],
      isWhatsAppNotificationEnable: false,
      selectedSpendsChannel: [],
      selectedCampaigns: [],
      storeCampaign: {},
      allCampaignNames: {},
      campaignNames: [],
      campaignIdList: {},
      campaignList: [],
      users: [
        { id: 1, name: 202, email: 4232 },
        { id: 2, name: 202, email: 343242 },
        { id: 3, name: 234242, email: 34234 },
        { id: 4, name: 4313424, email: 34234 },
      ],
      fields: [
        { key: "name", label: "Name" },
        { key: "trackingStartedFrom", label: "Start Date" },
        { key: "trackingEndsAt", label: "End Date" },
        { key: "plannedBudget", label: "Planned Budget" },
        { key: "remainingBudget", label: "Remaining Budget" },
        { key: "status", label: "Status" },
        { key: "actions", label: "Actions" },
        { key: "disableTrigger", label: "Disable Trigger" },
      ],
      addSpendsModal: false,
      breadcrumbs: [
        {
          text: "Overview",
          to: "/dashboard",
        },
      ],
      selectedReportID: "",
      reportType: ["Daily", "Weekly"],
      selectedReportType: "Daily",
      selectedReportMonth: new Date().getMonth(),
      months: [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
      ],
      selectedYear: new Date().getFullYear(),
      year: [new Date().getFullYear()],
      idClientEntireLoad: false,
      projectOverViewLoad: false,
      hideOverallProjectsTab: false,
      isProjectWiseIsLoad: false,
      projectWiseData: {},
      salesforceDataFound: false,
      filterDataLength: 0,
      googleAdsId: "",
      metaAdsId: "",
      amazonAdsId: "",
      salesforceConnected: false,
      dv360Connected: false,
      addSpendsModal: false,
      savenewSpendsModal: false,
      shopifyAdsConnected: false,
      leadSquaredConnected: false,
      googleAdsConnected: false,
      amazonAdsConnected: false,
      facebookAdsConnected: false,
      gmbConnected: false,
      analyticsConnected: false,
      isRevenueEmpty: true,
      isLiveDataIsLoad: false,
      amazonConversionToday: 0,
      amazonConversionYeserday: 0,
      amazonConversionDelta: 0,
      amazonSpendsToday: 0,
      amazonSpendsYeserday: 0,
      amazonSpendsDelta: 0,
      amazonWebPurchasesToday: 0,
      amazonWebPurchasesYeserday: 0,
      amazonWebPurchasesDelta: 0,
      metaConversionToday: "",
      metaConversionYeserday: 0,
      metaConversionDelta: 0,
      metaSpendsToday: 0,
      metaSpendsYeserday: 0,
      metaSpendsDelta: 0,
      metaWebPurchasesToday: 0,
      metaWebPurchasesYeserday: 0,
      metaWebPurchasesDelta: 0,
      googleConversionToday: 0,
      googleConversionYeserday: 0,
      googleConversionDelta: 0,
      googleSpendsToday: 0,
      googleSpendsYeserday: 0,
      googleSpendsDelta: 0,
      googleWebPurchasesToday: "",
      googleWebPurchasesYeserday: "",
      googleWebPurchasesDelta: "",
      //dv360
      dv360clicksToday: 0,
      dv360clicksYeserday: 0,
      dv360clicksDelta: 0,
      dv360SpendsToday: 0,
      dv360SpendsYeserday: 0,
      dv360SpendsDelta: 0,
      dv360impressionsToday: "",
      dv360impressionsYeserday: "",
      dv360impressionsDelta: "",
      client: [],
      costArray: [],
      datesArray: [],
      conversionsArray: [],
      webPurchasesTotal: 0,
      googleConversion: 0,
      googleSpends: 0,
      googleImpressions: 0,
      googleClicks: 0,
      //dv360
      dv360Spends: 0,
      dv360Impressions: 0,
      dv360Clicks: 0,
      googleRevenue: 0,
      googleWebPurchases: 0,
      impressionsTotal: 0,
      metaConversion: 0,
      metaWebPurchases: 0,
      metaSpends: 0,
      metaRevenue: 0,
      metaImpressions: 0,
      metaClicks: 0,
      spendsTotal: 0,
      amazonConversion: 0,
      amazonSpends: 0,
      amazonImpressions: 0,
      amazonClicks: 0,
      amazonRevenue: 0,
      revenueTotal: 0,
      cplTotal: 0,
      metaCPL: 0,
      amazonCPL: 0,
      googleCPL: 0,
      ctrTotal: 0,
      metaCTR: 0,
      amazonCRT: 0,
      googleCRT: 0,
      clicksTotal: 0,
      conversionsTotal: 0,
      fetchBenchMarkDataValue: [],
      fetchBenchMarkAllDataValue: [],
      fetchRealTime: [],
      startDateForAll: "",
      endDateForAll: "",
      seriesCostBenchMark: [
        {
          name: "Spends",
          data: [],
        },
      ],
      seriesConversionsBenchMark: [
        {
          name: "Leads",
          data: [],
        },
      ],
      chartOptionsBench: {
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        grid: {
          borderColor: $trackBgColor,
          strokeDashArray: 5,
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: -30,
            bottom: -12,
          },
        },
        stroke: {
          width: 3,
        },
        colors: ["#7ab240"],
        markers: {
          size: 2,
          colors: "#7ab240",
          strokeColors: "#7ab240",
          strokeWidth: 2,
          strokeOpacity: 1,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [
            {
              seriesIndex: 0,
              // dataPointIndex: this.dataPointIndex(),
              fillColor: "#ffffff",
              strokeColor: "#7ab240",
              size: 5,
            },
          ],
          shape: "circle",
          radius: 2,
          hover: {
            size: 3,
          },
        },
        xaxis: {
          categories: [],
          labels: {
            show: true,
          },
        },
        yaxis: {
          show: false,
        },
      },
      showSummaryDashboardLoading: false,
      showSummaryDashboardLoadingGraph: false,
      fetchOverViewAllData: [],
      currentPage: 1,
      selectedSourceType: "all",
      selectedTrendsSourceType: "all",
      perPage: 10,
      // seriesClicks: [this.metaClicks, this.amazonClicks, this.googleClicks],
      seriesClicks: [],
      seriesSpends: [],
      seriesImpressions: [],
      seriesConversions: [],
      seriesRevenue: [],
      seriesCPL: [],
      seriesCTR: [],
      seriesWebPurchases: [],

      chartOptions: {
        grid: {
          show: false,
          padding: {
            left: 0,
            right: 0,
          },
        },
        chart: {
          type: "donut",
          toolbar: {
            show: false,
          },

          sparkline: {
            enabled: true,
          },
          zoom: {
            enabled: false,
          },
        },
        colors: ["#0273eb", "#ff9900", "#7ab240", "#66D2CE"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "55%",
          },
        },
        dataLabels: {
          enabled: true,
        },
        labels: ["Meta", "Amazon", "Google", "DV360"],
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 200,
        //       },
        //       legend: {
        //         position: "bottom",
        //       },
        //     },
        //   },
        // ],
      },
      firstColumnColor: "#8B865F",
      columns: [
        { title: "Campaign", color: "#8B865F" },
        { title: "Data Source", color: "#ffffff" },
        { title: "Clicks", color: "#fd800d" },
        { title: "Impressions", color: "#2c5784" },
        { title: "Spends", color: "#26673d" },
        { title: "Conversions", color: "#f0f0f0" },
      ],
      slide: 0,
      summaryData: {
        clicks: 0,
        conversions: 0,
        costMicros: 0,
        impressions: 0,
        ctr: 0,
        avg_cpm: 0,
        cpc: 0,
        avg_cpc: 0,
        cost: 0,
        commonStore: 100,
        revenvue: 199200,
      },
      selectedClient: "",
      fetchClientsList: [],
      selectedSlug: "",
      isLoadingIndividualData: false,
      customIntialValue: true,
      selected: {
        label: "Last 7 days",
        code: "LAST_7_DAYS",
      },
      dateRange: {
        endDate: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
        startDate: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
      },
      selectedAdsOption: "all",
      selectedProjectsOption: "overall",
      selectedZoneTypeForFilter: [],
      selectedAffilatedForFilter: [],
      selectedAffilatedChannelForFilter: [],
      graphsWebPurchases: [],
    };
  },
  components: {
    BFormTags,
    BInputGroup,
    vSelect,
    BTabs,
    BTab,
    BBadge,
    ToastificationContent,
    BPagination,
    BCarousel,
    BCarouselSlide,
    BButton,
    BCard,
    BCardBody,
    BCardText,
    BRow,
    BCol,
    BFormSelect,
    DateRangePicker,
    BAvatar,
    BMedia,
    BMediaAside,
    BMediaBody,
    VueGoodTable,
    BCardTitle,
    apexchart: VueApexCharts,
    BOverlay,
    BLink,
    BFormCheckbox,
    BBreadcrumb,
    BBreadcrumbItem,
    MultiHeaderProjectChannel,
    BIcon,
    BSpinner,
    Spinner,
    multiChannelSpinner,
    BFormGroup,
    BFormInput,
    BTable,
    BFormDatepicker,
    MultiHeader,
    TrendsTab,
    CreativeAnalysis,
    BFormRadio,
    ProductOutCome,
    AffilateInsights,
    TargetVsAchieved,
    ProjectOutXL,
    ProjectCreations,
    CreativeAnalysisTable,
    CategoryCreation,
    FilterData,
    FootfallDataInsights,
    ComparisonDashboardForInsighs
  },
  methods: {
    handleDaterange(rangeName, startDate, endDate) {
      console.log(`Updated ${rangeName}: ${startDate} - ${endDate}`);
      if (rangeName === "dateRange1") {
        this.dateRange1 = { startDate, endDate };
        
      } else if (rangeName === "dateRange2") {
        this.dateRange2 = { startDate, endDate };
      }
    },
    fetchPerformanceData() {
      console.log("Fetching new data with", this.dateRange1, this.dateRange2);
      
      // Your API call logic here
    },

    handleDateRangeChange() {
      if (this.selectedDateRange === "yesterday") {
        this.fetchPerformanceData('daily');
      }
      if (this.selectedDateRange === "weekend") {
        this.fetchPerformanceData('weekend');
      }
      if (this.selectedDateRange === "custom") {
        this.fetchPerformanceData('custom');
      }
    },
    calculateMinDate() {
      const minDate = new Date(this.dateRange1.endDate);
      minDate.setDate(minDate.getDate() + 1);

      return minDate;
    },
    calculateMaxDate() {
      const maxDate = new Date();
      maxDate.setHours(23, 59, 59, 999);
      return maxDate;
    },
    dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("YYYY-MM-DD");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "YYYY-MM-DD"
      );
    },

    updateRangeValues(eventName, values) {
      if (values && values.startDate && values.endDate) {
        let startDate = moment(values.startDate).format("YYYY-MM-DD");
        let endDate = moment(values.endDate).format("YYYY-MM-DD");
        this.$emit(eventName, startDate, endDate);
      }
    },
    getChartOptions(channel, metric) {
      const barColors = {
        spend: ["#FF5733", "#FF8C66"],
        clicks: ["#33FF57", "#66FF8C"],
        leads: ["#3357FF", "#6699FF"],
      };

      return {
        chart: {
          type: "bar",
          height: 150,
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        plotOptions: {
          bar: {
            columnWidth: "40%", // Make the bars narrower
          },
        },
        colors: barColors[metric], // Set two different colors based on Yesterday/Day Before Yesterday
        xaxis: {
          categories: ["Yesterday", "Day Before Yesterday"],
        },
        yaxis: {
          title: {
            text: metric.charAt(0).toUpperCase() + metric.slice(1), // Set y-axis title to the metric
          },
        },
      };
    },

    getChartSeries(channel, metric) {
      const metricData = this.fetchData[channel]?.[metric];
      if (!metricData) return [];

      return [
        {
          name: "Yesterday",
          data: [metricData.yesterday.current],
        },
        {
          name: "Day Before Yesterday",
          data: [metricData.dayBeforeYesterday.current],
        },
      ];
    },

    fetchPerformanceData(comparisonType) {

      if (comparisonType === "custom") {
        this.comparisonCustomSpinner = true;

      }
      this.spinner_comparisonData = true;

      let date_range = {
        current_period: {
          start: this.dateRange1?.startDate || null,
          end: this.dateRange1?.endDate || null,
        },
        previous_period: {
          start: this.dateRange2?.startDate || null,
          end: this.dateRange2?.endDate || null,
        }
      };
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/performance/comparison`,
          {
            clientID: this.client._id,
            comparison_type: comparisonType,
            ...(comparisonType === "custom"
              ? {
                date_range, date_range
              }
              : {})
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          if (response?.data) {
            if (response.data.comparison_type === "daily")
              this.comparisonDailyApiData = response.data;
            else if (response.data.comparison_type === "weekend") this.comparisonWeekendApiData = response.data;
            else {
              this.comparisonCustomApiData = response.data;
              this.comparisonCustomSpinner = false;

            }

            this.spinner_comparisonData = false;
          }

          if (response?.data?.insights) {
            this.performanceInsights = response.data.insights;
          }
        })
        .catch((error) => {
          console.error("Error fetching performance data:", error);
        });
    },

    formatNumber(value) {
      return value ? value.toLocaleString() : "0";
    },

    getChangeClass(change) {
      if (!change) return "text-light"; // Default color if no data
      return change.includes("-")
        ? "text-danger font-weight-bold"
        : "text-success font-weight-bold";
    },
    formattedPerformanceData() {
      return {
        Overall: this.performanceData.overall,
        Meta: this.performanceData.meta,
        Google: this.performanceData.google,
      };
    },
    formattedDateRange() {
      return {
        current: `${this.performanceData.date_range.current_period.start} - ${this.performanceData.date_range.current_period.end}`,
        previous: `${this.performanceData.date_range.previous_period.start} - ${this.performanceData.date_range.previous_period.end}`,
      };
    },

    toggleTrigger(item) {
      this.spendsDeleteModal = true;
      this.toggledata = item;
      this.toggle = true;
    },
    toggleTriggerStatus() {
      var item = this.toggledata.isActive;
      item = !item;

      axios
        .patch(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-tracker/${this.toggledata.spendTrackerId}`,
          {
            isActive: item,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then(() => {
          this.showToast("Success", "Status updated successfully", "success");
          this.spendsDeleteModal = false;
          this.fetchSpends();
        })
        .catch((error) => {
          console.error("Error updating status:", error);
          this.showToast("Error", "Failed to update status", "danger");
        });
    },
    closeTranscationsModal() {
      this.showTransactions = false;
    },
    formatDate(date) {
      if (!date) return "-";
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "2-digit",
      });
    },
    fetchMetaAddsCampaign(accountId) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/get-campaigns`,
          {
            clientID: this.client._id,
            accountIDs: accountId,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          if (
            Object.keys(this.storeMetaAccountCampaign || {}).length === 0 &&
            response?.data?.campaigns
          ) {
            this.storeMetaAccountCampaign = response.data.campaigns;
          }

          if (
            Object.keys(this.metaAccountCampaiginName || {}).length === 0 &&
            response?.data?.campaigns &&
            typeof response.data.campaigns === "object"
          ) {
            this.metaAccountCampaiginName = Object.values(
              response.data.campaigns
            ).map((item) => item.campaignName);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchGoogleAddsCampaign(accountId) {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/get-campaigns`,
          {
            clientID: this.client._id,
            accountIDs: accountId,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          if (Object.keys(this.storeGoogleAccountCampaign).length === 0) {
            this.storeGoogleAccountCampaign = response.data;
          }

          if (
            Object.keys(this.googleAccountCampaiginName).length === 0 &&
            response.data &&
            typeof response.data === "object"
          ) {
            this.googleAccountCampaiginName = Object.values(response.data).map(
              (item) => item.campaignName
            );
          }
        })
        .catch((error) => {
          this.isCollapsed = false;
          this.isUpdateSuccessFully = false;
          this.closeSelectProjectConfig();
          this.showToast("Error", "Unknown error occurred!", "danger");
          console.log(error.response.data);
        });
    },
    updateSpendScheduledTracker() {
      this.isUpdatingTransaction = true;
    },
    tagValidator(tag) {
      return /^[6789]\d{9}$/.test(tag);
    },
    openViewSheet() {
      this.submitSheetModal = true;
    },
    closeViewSheet() {
      this.submitSheetModal = false;
    },
    submitSheet() {
      this.isSheetUrlSubmit = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/submit_google_sheet_link`,
          { clientID: this.client._id, gsheet: this.sheetUrlSubmit },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Submited",
            "Google Sheet submitted successfully!",
            "success"
          );
          this.isSheetUrlSubmit = false;
          this.submitSheetModal = false;
          this.getClient();
        })
        .catch((error) => {
          this.isSheetUrlSubmit = false;
          this.submitSheetModal = false;
          console.log(error.response);
          this.showToast("Error", "Error updating the graph", "danger");
        });
    },
    isUpdatedToday(updatedAt) {
      if (!updatedAt) return false;
      const updatedDate = new Date(updatedAt).toISOString().split("T")[0];
      const today = new Date().toISOString().split("T")[0];
      return updatedDate === today;
    },

    formatDateAndTimeForSync(isoString) {
      const date = new Date(isoString);

      // Extract individual components
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
      const day = String(date.getDate()).padStart(2, "0");
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const seconds = String(date.getSeconds()).padStart(2, "0");

      // Format as 'YYYY-MM-DD HH:mm:ss'
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    syncData() {
      if (
        this.client.integrations.googleAds &&
        this.googleSync.value.length > 0
      ) {
        this.syncGoogleAdsData();
      }
      if (this.client.integrations.facebook && this.metaSync.value.length > 0) {
        this.syncMetaAdsData();
      }
    },
    syncGoogleAdsData() {
      this.isGoogleAdsDataSyncing = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/google-ads/sync-data`,
          {
            clientID: this.client._id,
            dateRange: this.googleSync.value,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isGoogleAdsDataSyncing = false;
          this.showToast("Success!", "Google Ads Sync Started!", "success");
          this.closeDataSyncModal();
        })
        .catch((error) => {
          this.isGoogleAdsDataSyncing = false;
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error.response.data);
          this.closeDataSyncModal();
        });
    },
    syncMetaAdsData() {
      this.isMetaAdsDataSyncing = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/sync-data`,
          {
            clientID: this.client._id,
            dateRange: this.metaSync.value,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isMetaAdsDataSyncing = false;
          this.showToast("Success!", "Meta Ads Sync Started!", "success");
          this.isMetaAdsDataSyncing = false;
          this.closeDataSyncModal();
        })
        .catch((error) => {
          this.isMetaAdsDataSyncing = false;
          this.showToast("Error", "Unknown error occured!", "danger");
          console.log(error.response.data);
          this.closeDataSyncModal();
        });
    },
    openDataSyncModal() {
      this.dataSyncing = true;
    },
    closeDataSyncModal() {
      this.dataSyncing = false;
    },
    getReportMetaForCreativeAnalysisData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      this.selectedStartDate = startDate;
      this.selectedEndDate = endDate;
      if (campaignType) {
        additionalQueryParams =
          additionalQueryParams + `&campaign-type=${campaignType}`;
      }
      if (campaigns) {
        additionalQueryParams =
          additionalQueryParams + `&campaigns=${campaigns}`;
      }
      if (adGroups) {
        additionalQueryParams =
          additionalQueryParams + `&ad-groups=${adGroups}`;
      }
      if (!startDate && !endDate) {
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        this.startDateProjectName = formattedStartDate;
        this.endDateProjectName = formattedEndDate;
      }
      if (startDate && endDate) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${startDate}&endDate=${endDate}`;
        this.startDateProjectName = " ";
        this.endDateProjectName = " ";
        this.startDateProjectName = startDate;
        this.endDateProjectName = endDate;
      }
      let facebookAdAccountId = this.client.facebook.adAccountIDs[0];
      this.campaignsData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/facebook/get-reports?clientID=${this.client._id}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isReportDataLoading = false;
          this.campaignsData = response.data.reports;
        })
        .catch((error) => {
          this.isReportDataLoading = false;
          let errorText =
            error.response.data && error.response.data.error
              ? error.response.data.error
              : "Unknown error occured";
          // this.isReportDataLoading = false;
          this.showToast("Error", errorText, "danger");
        });
    },
    nextCreativePage() {
      if (this.creativeAnalysisPageIndex < this.creativeAnalysisPageSize) {
        this.creativeAnalysisPageIndex++;
        this.getCreativesAnalysisTableData(); // Fetch new data for the next page
      }
    },
    prevCreativePage() {
      if (this.creativeAnalysisPageIndex > 1) {
        this.creativeAnalysisPageIndex--;
        this.getCreativesAnalysisTableData(); // Fetch new data for the previous page
      }
    },
    getCreativesAnalysisTableData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isCreativesAnalysisData = true;
      this.creativesAnalysisData = [];
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/creative-insight?clientID=${this.selectedClient}${additionalQueryParams}&pageSize=10&pageIndex=${this.creativeAnalysisPageIndex}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.creativesAnalysisData =
            response && response.data && response.data.campaign;
          this.creativeAnalysisPageSize = Number(
            response && response.data && response.data.totalPages
          );
          this.creativeAnalysisPageIndex = Number(
            response && response.data && response.data.pageIndex
          );
          this.isCreativesAnalysisData = false;
        })
        .catch((error) => {
          this.creativesData = [];
          console.log(error);
          this.isCreativesAnalysisData = false;
        });
    },
    getXLMonthDataChange(val) {
      this.selectedXLMonth = val;
    },
    getChangedTarget(val) {
      this.targetType = val;
    },
    getChangedProjectNameTargetCall(val) {
      this.getChangedProjectNameTarget = val;
    },
    getChangedChannelTypeTarget(val) {
      this.channelType = val;
    },
    fetchProjectOutComeXL() {
      this.projectOutComeXL = {};
      this.isXLDataFetched = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projectOutcome/data/?clientID=${this.selectedClient}&month=${this.selectedXLMonth}&year=${this.selectedXLYear}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.projectOutComeCol =
            response && response.data && response.data.maxCol;
          this.projectOutComeRow =
            response && response.data && response.data.maxRow;
          this.projectOutComeXL =
            response && response.data && response.data.data;
          this.isXLDataFetched = false;
        })
        .catch((error) => {
          this.isXLDataFetched = false;
          console.log(error);
        });
    },
    fetchTargetVsAchived() {
      this.targetVsAchivedData = [];
      this.isTargetValueFetched = true;
      let targetTypeRadio = this.targetType === "custom" ? "weekly" : "monthly";
      let zoneListSeletion = this.getChangedProjectNameTarget;
      zoneListSeletion = encodeURIComponent(zoneListSeletion);
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/targetVsAchieved/fetchTargetVsAchieved?clientID=${this.selectedClient}&targetType=${targetTypeRadio}&channel=${this.channelType}&project=${zoneListSeletion}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.targetVsAchivedData = response.data;
          this.isTargetValueFetched = false;
        })
        .catch((error) => {
          this.isTargetValueFetched = false;
          console.log(error);
        });
    },
    CloseDataToZonewiseModal() {
      this.isCalulatedSuccessFully = false;
      this.addDataToZonewiseModal = false;
    },
    openDataToZonewiseModal(data, date) {
      this.selectedTargetVsAchivedVal = "";
      this.selectedZoneDate = "";
      this.selectedZoneDate = date;
      this.selectedTargetVsAchivedVal = data;
      this.fetchTargetValues();
      // if(this.targetCpl > 0 || this.targetLeads > 0 || this.targetLeads > 0){
      //   this.calculateTargetVsAchieved();
      // }
      this.addDataToZonewiseModal = true;
    },
    handleCallOutComeGetApiChange(data) {
      this.fetchProductOutComeData();
    },
    handleCallAffilatesGetApiChange(data) {
      this.fetchAffilatesComeData();
      this.getFetchAffilateOptions();
    },
    handleAffilatesGetApiChange(data) {
      this.fetchAffilatesComeData();
    },
    getAllSelectedAmountId() {
      let selectedIds = { google: [], meta: [] }; // Object to store IDs

      // Ensure client data exists
      if (!this.client) return selectedIds;

      let clientData = this.client;

      // --- Google Ads Processing ---
      if (clientData.googleAds && typeof clientData.googleAds === "object") {
        let { customerNames, customerClientIDs } = clientData.googleAds;

        if (Array.isArray(customerNames) && Array.isArray(customerClientIDs)) {
          let googleIds = customerNames
            .map((name, index) =>
              (this.selectedSpendsChannel.includes("GoogleAds") ||
                this.selectedSpendsChannel.includes("google")) &&
                this.selectedAccount.includes(name) // Use selectedAccount instead of newval
                ? customerClientIDs[index]
                : null
            )
            .filter((id) => id !== null);

          selectedIds.google = googleIds;
        }
      }

      // --- Meta (Facebook) Processing ---
      if (clientData.facebook && typeof clientData.facebook === "object") {
        let { adAccountNames, adAccountIDs } = clientData.facebook;

        if (Array.isArray(adAccountNames) && Array.isArray(adAccountIDs)) {
          let metaIds = adAccountNames
            .map((name, index) =>
              (this.selectedSpendsChannel.includes("MetaAds") ||
                this.selectedSpendsChannel.includes("meta")) &&
                this.selectedAccount.includes(name) // Use selectedAccount instead of newval
                ? adAccountIDs[index]
                : null
            )
            .filter((id) => id !== null);

          selectedIds.meta = metaIds;
        }
      }

      return selectedIds;
    },

    getAllCampaignId() {
      if (this.selectedFetchByClient === "overall") {
        const selectedCampaignData = {};

        this.selectedCampaigns.forEach((campaign) => {
          Object.keys(this.storeCampaign).forEach((channel) => {
            var campaignsInChannel = this.storeCampaign[channel];

            var foundCampaign = campaignsInChannel?.find(
              (camp) => camp.campaignName === campaign
            );
            if (foundCampaign) {
              selectedCampaignData[channel] =
                selectedCampaignData[channel] || [];
              selectedCampaignData[channel].push(foundCampaign.campaignId);
            }
          });
        });
        this.campaignIdList = {
          ...this.campaignIdList,
          ...selectedCampaignData,
        };
      } else {
        if (
          this.selectedSpendsChannel.includes("GoogleAds") ||
          this.selectedSpendsChannel.includes("google")
        ) {
          if (Array.isArray(this.storeGoogleAccountCampaign)) {
            // Extract campaign names and their corresponding IDs from objects
            const campaignNames = this.storeGoogleAccountCampaign.map(
              (camp) => camp.campaignName
            );
            const campaignIds = this.storeGoogleAccountCampaign.map(
              (camp) => camp.campaignID
            );

            this.selectedCampaignData["google"] = this.selectedCampaigns
              .map((campaign) => {
                const index = campaignNames.indexOf(campaign); // Find index of campaign in the extracted list
                return index !== -1 ? campaignIds[index] : null; // Get the corresponding campaignId
              })
              .filter((id) => id !== null);
          }
        }

        // 🔹 Check for Meta Ads campaigns
        if (
          this.selectedSpendsChannel.includes("MetaAds") ||
          this.selectedSpendsChannel.includes("meta")
        ) {
          if (Array.isArray(this.storeMetaAccountCampaign)) {
            // Extract campaign names and their corresponding IDs from objects
            const campaignNames =
              this.storeMetaAccountCampaign &&
              this.storeMetaAccountCampaign.map((camp) => camp.campaignName);
            const campaignIds =
              this.storeMetaAccountCampaign &&
              this.storeMetaAccountCampaign.map((camp) => camp.campaignID);

            this.selectedCampaignData["meta"] = this.selectedCampaigns
              .map((campaign) => {
                const index = campaignNames.indexOf(campaign); // Find index in extracted list
                return index !== -1 ? campaignIds[index] : null; // Get corresponding campaignId
              })
              .filter((id) => id !== null);
          }
        }
        this.campaignIdList = {
          ...this.campaignIdList,
          ...this.selectedCampaignData,
        };
      }
      // // Merge with existing campaign ID list
      // this.campaignIdList = {
      //   ...this.campaignIdList,
      //   ...selectedCampaignData,
      // };

      if (this.isUpdatingTransaction || this.editTriggerLists) {
        this.updateTransaction();
      } else {
        this.createSpendTracker();
      }
    },
    fetchAllCampaign(val = "") {
      if (val.length == 0 && this.selectedSpendsChannel.length == 0) return;

      let latestSelectedChannel = "";
      if (val != "") {
        if (typeof val !== "string" && Array.isArray(val)) {
          latestSelectedChannel = val[val.length - 1];
        } else latestSelectedChannel = val;
      } else {
        if (val.length === 0 && this.selectedSpendsChannel?.length > 0) {
          latestSelectedChannel = this.selectedSpendsChannel[
            this.selectedSpendsChannel.length - 1
          ];
        }
      }
      if (latestSelectedChannel == "GoogleAds")
        latestSelectedChannel = "google";
      else if (latestSelectedChannel == "MetaAds")
        latestSelectedChannel = "meta";

      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7); // 7 days back

      const endDate = new Date();
      endDate.setDate(endDate.getDate() - 1); // Yesterday

      const formattedStartDate = startDate.toISOString().split("T")[0];
      const formattedEndDate = endDate.toISOString().split("T")[0];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/get-level-data?clientID=${this.selectedClient}&startDate=${formattedStartDate}&endDate=${formattedEndDate}&level=campaign&channel=${latestSelectedChannel}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          if (this.allCampaignNames) {
            this.allCampaignNames = {
              ...this.allCampaignNames,
              [latestSelectedChannel]: response.data,
            };
          } else {
            this.allCampaignNames = {
              [latestSelectedChannel]: response.data,
            };
          }

          this.campaignList = response.data;
          this.storeCampaign = {
            ...this.storeCampaign,
            [latestSelectedChannel]: response.data,
          };
          let Fetched_campaignid = Object.keys(this.campaignIdList).reduce(
            (acc, key) => {
              return acc.concat(this.campaignIdList[key]);
            },
            []
          );

          if (Fetched_campaignid.length > 0 && val != "") {
            this.selectedCampaigns = Object.entries(
              this.allCampaignNames
            ).flatMap(([platform, campaigns]) => {
              return campaigns
                .filter((campaign) =>
                  Fetched_campaignid.includes(campaign.campaignId)
                )
                .map((campaign) => campaign.campaignName);
            });
          }
        })
        .catch((error) => {
          console.error("Error fetching level data:", error);
        });
    },

    fetchAffilatesComeData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      let selectedAffilatesChannel =
        this.selectedAffilatedChannelForFilter.length === 0
          ? "all"
          : this.selectedAffilatedChannelForFilter;
      let selectedProjects =
        this.selectedAffilatedForFilter.length === 0
          ? "all"
          : this.selectedAffilatedForFilter;
      this.affilatesOutWiseData = [];
      this.fetchAffilatestOutWiseData = true;
      selectedProjects = encodeURIComponent(selectedProjects);
      selectedAffilatesChannel = encodeURIComponent(selectedAffilatesChannel);
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/affiliate/insights/?clientID=${this.selectedClient}${additionalQueryParams}&projects=${selectedProjects}&affiliatedChannel=${selectedAffilatesChannel}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.affilatesOutWiseData = response && response.data;
          this.fetchAffilatestOutWiseData = false;
        })
        .catch((error) => {
          console.log(error);
          this.fetchAffilatestOutWiseData = false;
        });
    },
    fetchProductOutComeData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.productOutWiseData = [];
      this.fetchProductOutWiseData = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/project-outcome?clientID=${this.selectedClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.productOutWiseData = response && response.data;
          this.fetchProductOutWiseData = false;
        })
        .catch((error) => {
          console.log(error);
          this.fetchProductOutWiseData = false;
        });
    },
    handleReportModeChange() {
      this.fetchZonewiseData();
      this.getFetchOptions();
    },
    changeCreativesProjectName(data) {
      this.CreativesProjectName = data;
    },

    changeCreativeMetricChange(data) {
      this.selectedCreativesMetricType = data;
    },
    changedChannelChange(data) {
      this.selectedCreativesChannelType = data;
    },
    getSourceData(data) {
      this.selectedSourceType = data;
    },
    getProductSourceData(data) {
      this.selectedProductSourceType = data;
    },
    getTrendsSourceData(data) {
      this.selectedTrendsSourceType = data;
    },
    getZoneTypeData(data) {
      this.selectedProductType = data;
    },
    getSubOptions(data) {
      this.subProductOptions = data;
    },
    handleZoneList(data) {
      this.zoneListChange = data;
    },
    handleProductProjectList(data) {
      this.productProjectNamesChange = data;
    },
    handledZoneType(data) {
      this.selectedZoneTypeForFilter = data;
    },
    handledAffilatesType(data) {
      this.selectedAffilatedForFilter = data;
    },
    handledAffilatesChannelType(data) {
      this.selectedAffilatedChannelForFilter = data;
    },
    changeTab(name) {
      this.tabMultiChannelName = name;
      if (this.tabMultiChannelName === "Overview") {
        this.reportModeSelected = "daily";
        this.fetchBenchMarkAllData();
        this.fetchRealTimeData();
        this.fetchSpends();
      }
      if (this.tabMultiChannelName === "ProjectInsights") {
        this.reportModeSelected = "daily";
        this.fetchProjectWiseData();
      }
      if (this.tabMultiChannelName === "TargetVSAchieved") {
        this.reportModeSelected = "daily";
        this.fetchTargetVsAchived();
        this.getFetchOptions();
      }
      if (this.tabMultiChannelName === "ProductOutCome") {
        this.reportModeSelected = "monthly";
        this.fetchProjectOutComeXL();
        this.fetchProductOutComeData();
      }
      if (this.tabMultiChannelName === "ZoneInsights") {
        this.reportModeSelected = "daily";
        this.fetchZonewiseData();
        this.getFetchOptions();
      }
      if (this.tabMultiChannelName === "AffiliateInsights") {
        this.reportModeSelected = "daily";
        this.fetchAffilatesComeData();
        this.getFetchAffilateOptions();
      }
      if (this.tabMultiChannelName === "ProductInsights") {
        this.reportModeSelected = "daily";
        this.getFetchOptions();
        this.fetchProjectProductWiseData();
      }
      if (this.tabMultiChannelName === "CreativeAnalysis") {
        this.reportModeSelected = "daily";
        this.getFetchOptions();
        this.getCreativesData();
      }
      if (this.tabMultiChannelName === "Trends") {
        this.reportModeSelected = "daily";
        this.getTrendsData();
        this.getTrendsTableData();
        this.getQualityVolumeTableData();
        this.getProductWiseForLaadsTableData();
      }
      if (this.tabMultiChannelName === "ChannelPerformanceOverview") {
        this.reportModeSelected = "daily";
        this.fetchOverViewData();
      }
      if (this.tabMultiChannelName === "CreativeAnalysisTable") {
        this.getCreativesAnalysisTableData();
      }
      if (this.tabMultiChannelName === "MetaCreativeAnalysisTable") {
        this.getReportMetaForCreativeAnalysisData();
      }
      if (this.tabMultiChannelName === "FilterCategory") {
        this.reportModeSelected = "daily";
        this.getTrendsData();
        this.getTrendsTableData();
        this.getQualityVolumeTableData();
        this.getProductWiseForLaadsTableData();
        this.isMTDFetched = true;
        setTimeout(() => (this.isMTDFetched = false), 1000);
      }
      if (this.tabMultiChannelName === "FilterData") {
        this.reportModeSelected = "daily";
      }
    },
    getTrendsData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isTrendsDataFetched = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      this.TrendsData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/trends/?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedTrendsSourceType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.TrendsData = response.data;
          this.isTrendsDataFetched = false;
        })
        .catch((error) => {
          this.TrendsData = [];
          console.log(error);
          this.isTrendsDataFetched = false;
        });
    },
    getQualityVolumeTableData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isQualityVolumeFetched = true;
      this.isQualityVolumeData = [];
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/subProductTrends?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedTrendsSourceType}&product=LeadGen&projects=all`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isQualityVolumeData = response.data;
          this.isQualityVolumeFetched = false;
        })
        .catch((error) => {
          this.isQualityVolumeData = [];
          console.log(error);
          this.isQualityVolumeFetched = false;
        });
    },
    getProductWiseForLaadsTableData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isLeadsFetched = true;
      this.LeadsData = [];
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/productWiseTrends?clientID=${this.selectedClient}${additionalQueryParams}&channel=all`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.LeadsData = response.data;
          this.isLeadsFetched = false;
        })
        .catch((error) => {
          this.LeadsData = [];
          console.log(error);
          this.isLeadsFetched = false;
        });
    },
    getTrendsTableData() {
      this.isTrendsTableDataFetched = true;
      this.TrendsTableDataFetched = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/productTrendChanges/?clientID=${this.selectedClient}&channel=${this.selectedTrendsSourceType}&tag=Zone&groupName=allZones`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.TrendsTableDataFetched = response.data;
          this.isTrendsTableDataFetched = false;
        })
        .catch((error) => {
          this.TrendsTableData = [];
          console.log(error);
          this.isTrendsTableDataFetched = false;
        });
    },
    getFetchAffilateOptions() {
      this.affilatesWiseOptions = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/affiliate-options?clientID=${this.selectedClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          const combinedProjectArray = Object.values(
            response.data.Projects
          ).flat();
          this.affilatesAllOptions = [...combinedProjectArray];
          const combinedArray = Object.values(
            response.data.affiliateProjectMapping
          ).flat();
          this.affilatesWiseOptions = [...combinedArray];
          this.affilatesWiseOptionsIntial = this.affilatesWiseOptions[0];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getFetchOptions() {
      this.creativeOptions = [];
      this.zoneWiseTypeOptionsFlag = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/options?clientID=${this.selectedClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          this.zoneWiseTypeOptionsFlag = false;
          this.zoneWiseTypeOptions = response && response.data;
          const combinedArray = Object.values(
            this.zoneWiseTypeOptions && this.zoneWiseTypeOptions.projectNames
          ).flat();
          this.intiallOptionsForGroup = [
            ...(response && response.data && response.data.groups),
          ];
          this.creativeOptions = [...combinedArray];
          this.productProjectIntialNamesChange = [...combinedArray];
          this.productWiseTypeOptions = [...combinedArray];
          this.initiallProjectName = this.creativeOptions[0];
          this.optionsLength = Object.keys(response && response.data).length;
        })
        .catch((error) => {
          this.zoneWiseTypeOptionsFlag = false;
          console.log(error);
        });
    },
    getCreativesData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      this.isCreativesFlag = true;
      this.creativesData = [];
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.currentDateRange = dateRange;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      let channelType = this.selectedCreativesChannelType
        ? this.selectedCreativesChannelType
        : "all";
      let projectName = this.CreativesProjectName
        ? this.CreativesProjectName
        : "Atlantis";
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/creative-analysis/overall-category-insights?clientID=${this.selectedClient}&project=${projectName}${additionalQueryParams}&channel=${channelType}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.creativesData = response && response.data;
          this.isCreativesFlag = false;
        })
        .catch((error) => {
          this.creativesData = [];
          console.log(error);
          this.isCreativesFlag = false;
        });
    },
    updateSpendsTransaction() {
      this.isEditSpends = true;
      axios
        .patch(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/transaction/`,
          {
            transactionID: this.transcationId,
            spendTrackerId: this.triggerId,
            transactionAmount: Number(this.editSpendsAmount),
            receivedAt: this.receivedDate,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          const data = response.data || {};
          this.transactions = data.transactions;
          this.fetchSpends();
          this.showToast(
            "Updated",
            "Spends Transaction updated successfully!",
            "success"
          );
          this.getSpendsTriggersLists();
          this.closeEditSpendsModal();
          this.isEditSpends = false;
        })
        .catch((error) => {
          console.log(error.response.data);
          this.closeEditSpendsModal();
          this.isEditSpends = false;
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
          selectorDirections: {
            type: String,
          },
        },
      });
    },
    deleteASpends() {
      this.isDeleteSpends = true;
      axios
        .delete(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-transaction/?spendTrackerId=${this.triggerId}&transactionID=${this.selectedTrasactionId}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.isDeleteSpends = false;
          this.showToast(
            "Success!",
            "Delete Spends amount Successfully",
            "success"
          );
          this.fetchSpends();
          this.getSpendsTriggersLists();
          this.closeDeleteSpendsModal();
        })
        .catch((error) => {
          this.isDeleteSpends = false;
          this.showToast("Error", `${error.response.data.error}`, "danger");
          this.closeDeleteSpendsModal();
        });
    },
    fetchSpends(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.spendsTracker = {};
      this.isSpendsLoading = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-tracking/?clientID=${this.selectedClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          // dates
          this.startDateForTriggers =
            response.data && response.data.allSpendsTrackerStartsDate;
          this.endDateForTriggers =
            response.data && response.data.allSpendsTrackerEndDate;

          this.googleAdsspenddata =
            response.data && response.data.allSpendTrackerSpends.google;
          this.metaAdsspenddata =
            response.data && response.data.allSpendTrackerSpends.meta;
          this.otherAdsspenddata =
            response.data && response.data.allSpendTrackerSpends.amazon;
          this.allSpendTrackersRemainingBudgetFormatted =
            response.data &&
            response.data.allSpendTrackersRemainingBudgetFormatted;
          this.allSpendTrackersPlannedBudgetFormatted =
            response.data &&
            response.data.allSpendTrackersPlannedBudgetFormatted;
          this.allSpendTrackersSpentSoFarFormatted =
            response.data && response.data.allSpendTrackersSpentSoFarFormatted;
          this.allSpendTrackersSpentSoFarFormatted =
            response.data && response.data.allSpendTrackersSpentSoFarFormatted;
          this.spendsTracker = response.data && response.data.spendTrackers;
          this.totalActiveTriggers =
            response.data && response.data.onGoingSpendTrackerCount;
          if (!response && response.data && response.data.spendTrackerId) {
            this.spendTrackerId =
              response && response.data && response.data.result.spendTrackerId;
          } else {
            this.spendTrackerId =
              response && response.data && response.data.spendTrackerId;
          }
          this.spendsMessage =
            response.data && response.data.error && response.data.error.message;
          this.conditionalSpends =
            response.data && response.data.plannedBudgetFormatted;
          this.isSpendsLoading = false;
          this.resetForm();
        })
        .catch((error) => {
          console.log(error);
          this.isSpendsLoading = false;
        });
    },
    calculateTargetVsAchieved() {
      this.isCalulatedSuccessFully = false;
      this.isCalculating = true;
      let startDate, endDate;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        startDate = prevWeek.toISOString().split("T")[0];
        endDate = yesterday.toISOString().split("T")[0];
      } else {
        startDate = this.startDateForAll;
        endDate = this.endDateForAll;
      }
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/targetVsAcheived/`,
          {
            clientID: this.selectedClient,
            startDate: this.convertDate(this.selectedZoneDate),
            // endDate: endDate,
            channel: this.selectedSourceType,
            project: this.selectedTargetVsAchivedVal,
            targetedValues: {
              spends: this.targetSpends,
              leads: this.targetLeads,
              cpl: this.targetCpl,
            },
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let targetVal = response.data;
          if (targetVal && Object.keys(targetVal).length > 0) {
            this.targetCpl = targetVal.targetedValues.cpl;
            this.targetSpends = targetVal.targetedValues.spends;
            this.targetLeads = targetVal.targetedValues.leads;

            this.achievedCpl = targetVal.achievedValues.cpl;
            this.achievedSpends = targetVal.achievedValues.spends;
            this.achievedLeads = targetVal.achievedValues.leads;

            this.differenceCpl = targetVal.differenceValues.cpl;
            this.differenceSpends = targetVal.differenceValues.spends;
            this.differenceLeads = targetVal.differenceValues.leads;

            this.differencePerCpl = targetVal.differencePercentValues.cpl;
            this.differencePerSpends = targetVal.differencePercentValues.spends;
            this.differencePerLeads = targetVal.differencePercentValues.leads;

            this.isCalulatedSuccessFully = true;
          }
          this.isCalculating = false;
          this.showToast(
            "Success!",
            "Calculated Target Vs Achieved Successfully!",
            "success"
          );
        })
        .catch((error) => {
          this.isCalculating = false;
          this.isCalulatedSuccessFully = false;
          this.showToast("Error", "Unknown error occured!", "danger");
        });
    },
    convertDate(dateStr) {
      const [day, month, year] = dateStr.split("-");
      return `${year}-${month}-${day}`;
    },
    fetchTargetValues(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.isFetchTargetValue = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      let convertedIntiallDate = this.convertDate(this.selectedZoneDate);
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${convertedIntiallDate}`;
      }
      if (this.selectedZoneDate && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams + `&startDate=${convertedIntiallDate}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/targetedValues/?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedSourceType}&project=${this.selectedTargetVsAchivedVal}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          let targetVal = response.data;
          if (Object.keys(targetVal).length > 0) {
            this.targetCpl = targetVal.targetedValues.cpl;
            this.targetSpends = targetVal.targetedValues.spends;
            this.targetLeads = targetVal.targetedValues.leads;
            this.achievedCpl = targetVal.achievedValues.cpl;
            this.achievedSpends = targetVal.achievedValues.spends;
            this.achievedLeads = targetVal.achievedValues.leads;
          }
          this.isFetchTargetValue = false;
        })
        .catch((error) => {
          console.log(error);
          this.isFetchTargetValue = false;
        });
    },
    fetchZonewiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.zoneWiseData = [];
      this.isZonewiseDatafetched = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      let zoneListSeletion =
        this.zoneListChange.join(",") == ""
          ? this.creativeOptions
          : this.zoneListChange.join(",");
      let zoneData =
        this.selectedZoneTypeForFilter.length === 0
          ? this.intiallOptionsForGroup
          : this.selectedZoneTypeForFilter;
      let subProductOption = this.subProductOptions;
      // ${process.env.VUE_APP_SERVER_URL}/api/projects/group/insights/?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedSourceType}&tag=Zone&product=${this.selectedProductType}&projects=${zoneListSeletion}&groupName=${zoneData}&subProductOption=${subProductOption}`,

      zoneListSeletion = encodeURIComponent(zoneListSeletion);

      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/group/insights/?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedSourceType}&tag=Zone&product=${this.selectedProductType}&projects=${zoneListSeletion}&groupName=${zoneData}&subProductOption=${subProductOption}&frequency=${this.reportModeSelected}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.salesforceDataFound = false;
          if (
            response.data[0]?.dateWiseData[0]?.insights?.salesforce &&
            typeof response.data[0].dateWiseData[0].insights.salesforce ===
            "object"
          ) {
            this.salesforceDataFound = true;
          }
          this.zoneWiseData = response && response.data;

          this.isZonewiseDatafetched = false;
        })
        .catch((error) => {
          console.log(error);
          this.isZonewiseDatafetched = false;
        });
    },
    fetchProjectProductWiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      // this.zoneWiseData = []
      this.isProductDatafetched = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }

      let projectNames =
        this.productProjectNamesChange.length === 0
          ? this.productProjectIntialNamesChange
          : this.productProjectNamesChange;

      projectNames = encodeURIComponent(projectNames);

      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/products/insights/?clientID=${this.selectedClient}${additionalQueryParams}&channel=${this.selectedProductSourceType}&projects=${projectNames}&frequency=${this.reportModeSelected}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.salesforceDataFound = false;
          if (
            response.data[0]?.dateWiseData[0]?.insights?.salesforce &&
            typeof response.data[0].dateWiseData[0].insights.salesforce ===
            "object"
          ) {
            this.salesforceDataFound = true;
          }
          this.productWiseData = response && response.data;
          // this.zoneWiseData = response && response.data
          this.isProductDatafetched = false;
        })
        .catch((error) => {
          console.log(error);
          this.isProductDatafetched = false;
        });
    },
    formatDateTime(value) {
      const date = new Date(value).toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      return date;
    },
    processCampaigns() {
      // Ensure campaign lists exist
      if (!this.campaignIdList.google && !this.campaignIdList.meta) return;

      // Process Google Campaigns
      let matchedGoogleCampaigns = [];
      if (
        Array.isArray(this.campaignIdList.google) &&
        this.campaignIdList.google.length > 0
      ) {
        if (Array.isArray(this.storeGoogleAccountCampaign)) {
          matchedGoogleCampaigns = this.storeGoogleAccountCampaign
            .filter(
              (campaign) =>
                campaign.channel === "google" &&
                this.campaignIdList.google.includes(String(campaign.campaignID))
            )
            .map((campaign) => campaign.campaignName);
        }
      }

      // Process Meta Campaigns
      let matchedMetaCampaigns = [];
      if (
        Array.isArray(this.campaignIdList.meta) &&
        this.campaignIdList.meta.length > 0
      ) {
        if (
          Array.isArray(this.storeMetaAccountCampaign) &&
          Array.isArray(this.campaignIdList?.meta)
        ) {
          const metaCampaignIds = new Set(this.campaignIdList.meta.map(String));

          matchedMetaCampaigns = this.storeMetaAccountCampaign
            .filter(
              (campaign) =>
                campaign.channel === "meta" &&
                metaCampaignIds.has(String(campaign.campaignID))
            )
            .map((campaign) => campaign.campaignName);
        }
      }

      this.selectedCampaigns = [
        ...matchedGoogleCampaigns,
        ...matchedMetaCampaigns,
      ];
    },
    getSpendsTriggersLists(spendstransactionsId = "", showTransactions = "") {
      this.accountIdList = [];
      this.spendsTrasactionFetch = true;
      this.spendsTrasactionData = [];
      this.transactions = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-transactions/?spendTrackerId=${this.triggerId}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-type": "application/json",
            },
          }
        )
        .then((response) => {
          const data = response.data || {};
          if (this.showTransactions) {
            this.transactions = data.transactions;
          } else {
            if (
              data.accountIDList &&
              Object.values(data.accountIDList).some((arr) => arr.length > 0)
            ) {
              this.selectedFetchByClient = "account";
            } else {
              this.selectedFetchByClient = "overall";
            }
            this.status = data.status;
            this.triggerName = data.name;
            this.spendsAmount = data.totalAmount;
            this.transactions = data.transactions;
            this.spendScheduledTrackerId = data._id;
            this.accountIdList = data.accountIDList;
            this.selectedSpendsChannel = (data.channels || []).map(
              (channel) => {
                if (channel === "google") return "GoogleAds";
                if (channel === "meta") return "MetaAds";
                return channel;
              }
            );
            this.campaignIdList = data.campaignIDList || [];
            this.transactionStarts = data.trackingStartsAt || "";
            this.startTrackingDate = data.trackingStartsAt?.length
              ? data.trackingStartsAt
              : new Date().toISOString().substr(0, 10);
            this.endTrackingDate = data.trackingEndsAt?.length
              ? data.trackingEndsAt
              : new Date().toISOString().substr(0, 10);
            this.whatsAppNumbers = data.whatsAppNumbers?.length
              ? data.whatsAppNumbers
              : [];
            this.isWhatsAppNotificationEnable =
              data.isWhatsAppNotificationEnable || false;
            this.spendsAmountOnTop = data.totalAmount || 0;
            this.spendsCurrencyOnTop = data.currencyCode || "";
            // this.spendsTrasactionData = data.transactions || [];

            if (
              this.selectedFetchByClient === "overall" &&
              this.selectedSpendsChannel.length > 0
            )
              for (let i = 0; i < this.selectedSpendsChannel.length; i++) {
                this.fetchAllCampaign(this.selectedSpendsChannel[i]);
              }
            else {
              let account = data.accountIDList;
              let googleAccountOptionsId =
                this.client.googleAds?.customerClientIDs || [];
              let googleAccountNames =
                this.client.googleAds?.customerNames || [];
              let metaAccountIds = this.client.facebook?.adAccountIDs || [];
              let metaAccountNames = this.client.facebook?.adAccountNames || [];

              let matchedGoogleNames = [];
              let matchedMetaNames = [];

              // Check if Google account IDs match and return names
              if (account.google && account.google.length > 0) {
                matchedGoogleNames = account.google
                  .map((id) => {
                    let index = googleAccountOptionsId.indexOf(id);
                    return index !== -1 ? googleAccountNames[index] : null;
                  })
                  .filter((name) => name !== null);
              }

              // Check if Meta account IDs match and return names
              if (account.meta && account.meta.length > 0) {
                matchedMetaNames = account.meta
                  .map((id) => {
                    let index = metaAccountIds.indexOf(id);
                    return index !== -1 ? metaAccountNames[index] : null;
                  })
                  .filter((name) => name !== null);
              }

              this.selectedAccount = [
                ...matchedGoogleNames,
                ...matchedMetaNames,
              ];
            }
          }
          this.spendsTrasactionFetch = false;
        })
        .catch((error) => {
          console.error("Error fetching transactions:", error);
          this.accountIdList = [];
          this.spendsTrasactionData = [];
          this.transactions = [];
          this.spendsTrasactionFetch = false;
        });
    },
    addTransaction() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spend-tracker/transaction`,
          {
            spendTrackerId: this.triggerId,
            transactionAmount: this.editSpendsAmount,
            receivedAt: this.receivedDate,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          const data = response.data || {};
          this.transactioSns = data.transactions;
          this.editSpendsModal = false;
          this.editSpendsAmount = 0;
          this.receivedDate = new Date().toISOString().substr(0, 10);
          this.getSpendsTriggersLists();
        })
        .catch((err) => console.log(err));
    },
    createSpendTracker() {
      let selectedAccountIDs = {};

      if (this.selectedAccount.length) {
        selectedAccountIDs = this.getAllSelectedAmountId();
      }

      if (this.isWhatsAppNotificationEnable === false) {
        this.whatsAppNumbers = [];
      }

      let channels = this.selectedSpendsChannel.map((channel) => {
        if (channel === "GoogleAds") return "google";
        if (channel === "MetaAds") return "meta";
        return channel;
      });

      this.addingSpends = true;

      // Create payload object
      let payload = {
        clientID: this.selectedClient,
        name: this.triggerName,
        amount:
          this.editSpendsAmount > 0 ? this.editSpendsAmount : this.spendsAmount,
        receivedAt: this.receivedDate,
        trackingStartsAt: this.startTrackingDate,
        trackingEndsAt: this.endTrackingDate,
        isWhatsAppNotificationEnable: this.isWhatsAppNotificationEnable,
        whatsAppNumbers: this.whatsAppNumbers,
        channels: channels,
        campaignIDList: this.campaignIdList,
      };

      // Conditionally add `accountIDList` if `selectedFetchByClient` is 'account'
      if (this.selectedFetchByClient === "account") {
        payload.accountIDList = selectedAccountIDs;
      }

      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-tracker/create/`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          return this.fetchSpends(); // Ensures fetchSpends() runs before the next .then()
        })
        .then(() => {
          this.resetForm(); // Reset form fields
          this.closeEditSpendsModal();
          this.showToast(
            "Success!",
            "Added Spends amount Successfully",
            "success"
          );
          selectedAccountIDs = {};
          this.selectedAccount = {};
        })
        .catch((error) => {
          this.addingSpends = false;
          selectedAccountIDs = {};
          this.selectedAccount = {};
          this.showToast("Error", "Unknown error occurred!", "danger");
          console.error("Error adding transaction:", error);
        });
    },

    resetForm() {
      this.selectedSpendsChannel = [];
      this.spendsAmount = 0;
      this.receivedDate = new Date().toISOString().substr(0, 10);
      this.startTrackingDate = new Date().toISOString().substr(0, 10);
      this.endTrackingDate = this.startTrackingDate;
      this.whatsAppNumbers = [];
      this.campaignIdList = {};
      this.storeCampaign = {};
      this.allCampaignNames = {};
      this.campaignNames = [];
      this.selectedCampaigns = [];
      this.isWhatsAppNotificationEnable = false;
      this.addingSpends = false;
      this.selectedAccount = {};
    },

    resetForm() {
      this.triggerName = "";
      this.selectedSpendsChannel = [];
      this.spendsAmount = 0;
      this.receivedDate = new Date().toISOString().substr(0, 10);
      this.startTrackingDate = new Date().toISOString().substr(0, 10);
      this.endTrackingDate = this.startTrackingDate;
      this.whatsAppNumbers = [];
      this.campaignIdList = {};
      this.storeCampaign = {};
      this.allCampaignNames = {};
      this.campaignNames = [];
      this.selectedCampaigns = [];
      this.isWhatsAppNotificationEnable = false;
      this.addingSpends = false;
    },
    updateTransaction() {
      if (this.isWhatsAppNotificationEnable === false) {
        this.whatsAppNumbers = [];
      }
      let chennel = this.selectedSpendsChannel.map((chennel) => {
        if (chennel === "GoogleAds" || chennel === "google") return "google";
        if (chennel === "MetaAds" || chennel === "meta") return "meta";
      });

      this.addingSpends = true;
      let selectedAccountIDs = {};

      if (this.selectedAccount.length) {
        selectedAccountIDs = this.getAllSelectedAmountId();
      }

      axios
        .patch(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/spends-transaction`,
          {
            name: this.triggerName,
            spendTrackerId: this.triggerId,
            clientID: this.selectedClient,
            amount: this.spendsAmount,
            receivedAt: this.receivedDate,
            trackingStartsAt: this.startTrackingDate,
            trackingEndsAt: this.endTrackingDate,
            isWhatsAppNotificationEnable: this.isWhatsAppNotificationEnable,
            whatsAppNumbers: this.whatsAppNumbers,
            channels: chennel,
            campaignIDList: this.campaignIdList,
            accountIDList: selectedAccountIDs,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then(() => {
          return this.fetchSpends();
        })
        .then(() => {
          // Reset form fields
          this.editTriggerLists = false;
          this.triggerName = "";
          this.selectedSpendsChannel = [];
          this.spendsAmount = 0;
          this.receivedDate = new Date().toISOString().substr(0, 10);
          this.startTrackingDate = new Date().toISOString().substr(0, 10);
          this.endTrackingDate = this.startTrackingDate;
          this.whatsAppNumbers = [];
          this.campaignIdList = {};
          this.storeCampaign = {};
          this.allCampaignNames = {};
          this.campaignNames = [];
          this.selectedCampaigns = [];
          this.isWhatsAppNotificationEnable = false;
          this.addingSpends = false;
          this.isUpdatingTransaction = false;
          this.selectedAccount = [];
          selectedAccountIDs = {};
          this.showToast(
            "Success!",
            "Updated Spends Trigger Successfully",
            "success"
          );
        })
        .catch((error) => {
          this.addingSpends = false;
          this.isUpdatingTransaction = false;
          selectedAccountIDs = {};
          this.showToast("Error", `${error.response.data.error}`, "danger");
          console.error("Error updating transaction:", error);
        });
    },
    closeDeleteSpendsModal() {
      this.spendsDeleteModal = false;
      this.toggle = false;
    },
    openDeleteSpendsModal(item) {
      this.selectedTrasactionId = item.transactionID;
      this.spendsDeleteModal = true;
    },
    closeAddSpendsModal() {
      this.triggerName = "";
      this.selectedSpendsChannel = [];
      this.spendsAmount = 0;
      this.receivedDate = new Date().toISOString().substr(0, 10);
      this.startTrackingDate = new Date().toISOString().substr(0, 10);
      this.endTrackingDate = this.startTrackingDate;
      this.whatsAppNumbers = [];
      this.campaignIdList = {};
      this.storeCampaign = {};
      this.allCampaignNames = {};
      this.campaignNames = [];
      this.selectedCampaigns = [];
      this.isWhatsAppNotificationEnable = false;
      this.addingSpends = false;
      this.isUpdatingTransaction = false;
      this.isUpdatingTransaction = false;
      this.addSpendsModal = false;
      this.editTriggerLists = false;
      this.selectedFetchByClient = "overall";
    },
    closeEditSpendsModal() {
      this.editSpendsModal = false;
      this.savenewSpendsModal = false;
    },
    AddSpendsModal() {
      this.savenewSpendsModal = true;
      this.editSpendsModal = true;
    },
    openViewSpendsModal(item) {
      this.status = item.status;
      this.triggerId = item.spendTrackerId;
      this.showTransactions = true;
      this.getSpendsTriggersLists(item.spendTrackerId, this.showTransactions);
    },
    openEditSpendsTranscationModal(item) {
      this.editSpendsAmount = item.amount;
      this.receivedDate = item.receivedAt;
      this.transcationId = item.transactionID;
      this.editSpendsModal = true;
    },

    openEditSpendsModal(item) {
      let spendTrackerId = item.spendTrackerId;
      this.triggerId = item.spendTrackerId;
      this.editTriggerLists = true;
      this.editSpends = true;
      this.getSpendsTriggersLists(spendTrackerId);
    },
    openAddSpendsModal() {
      // this.getSpendsTriggersLists();
      this.addSpendsModal = true;
    },
    updateReportID() {
      const reportTypeKey = this.selectedReportType.toLowerCase();
      if (reportTypeKey == "weekly") {
        let report = this.client.googleSheetReports.weekly;
        this.selectedReportID = report;
        return;
      }
      let reportID = this.client.googleSheetReports[reportTypeKey][
        `${this.selectedReportMonth}-${this.selectedYear}`
      ];
      this.selectedReportID = reportID;
    },
    totalOverviewData(data) {
      return (this.filterDataLength = data);
    },
    orderedLinks() {
      if (this.isExternalUser === "true" && this.isNewUser === "false") {
        const links = [
          {
            name: "googleAds",
            to: `/client/${this.client.slug}/dashboard/google-ads`,
            connected: this.googleAdsConnected,
            disabled: !this.googleAdsConnected,
            imageSrc: "../../assets/images/logo/ads-logo.png",
          },
          {
            name: "facebookAds",
            to: `/client/${this.client.slug}/dashboard/meta-ads`,
            connected: this.facebookAdsConnected,
            disabled: !this.facebookAdsConnected,
            imageSrc: "../../assets/images/logo/meta.png",
          },
        ];
        // Move disabled links to the end
        const orderedLinks = links.sort((a, b) => {
          if (a.connected && !b.connected) return -1;
          if (!a.connected && b.connected) return 1;
          return 0;
        });
        return orderedLinks;
      } else {
        const links = [
          {
            name: "googleAds",
            to: `/client/${this.client.slug}/dashboard/google-ads`,
            connected: this.googleAdsConnected,
            disabled: !this.googleAdsConnected,
            imageSrc: "../../assets/images/logo/ads-logo.png",
          },
          {
            name: "amazonAds",
            to: `/client/${this.client.slug}/dashboard/amazon-ads`,
            connected: this.amazonAdsConnected,
            disabled: !this.amazonAdsConnected,
            imageSrc: "../../assets/images/logo/amazon.png",
          },
          {
            name: "facebookAds",
            to: `/client/${this.client.slug}/dashboard/meta-ads`,
            connected: this.facebookAdsConnected,
            disabled: !this.facebookAdsConnected,
            imageSrc: "../../assets/images/logo/meta.png",
          },
          {
            name: "googleAnalytics",
            to: `/client/${this.client.slug}/dashboard/google-analytics`,
            connected: this.analyticsConnected,
            disabled: !this.analyticsConnected,
            imageSrc: "../../assets/images/portrait/small/analytics-avatar.png",
          },
          {
            name: "gmb",
            to: `/gmb/${this.client.slug}`,
            connected: this.gmbConnected,
            disabled: !this.gmbConnected,
            imageSrc: "../../assets/images/logo/gmb.jpeg",
          },
          {
            name: "shopifyAds",
            to: `/stores/${this.client.slug}`,
            connected: this.shopifyAdsConnected,
            disabled: !this.shopifyAdsConnected,
            imageSrc: "../../assets/images/logo/Shopify.png",
          },
          {
            name: "leadSquared",
            to: `/client/${this.client.slug}/dashboard/leadsquared`,
            connected: this.leadSquaredConnected,
            disabled: !this.leadSquaredConnected,
            imageSrc: "../../assets/images/logo/leadSqaured.png",
          },
          {
            name: "salesforce",
            to: `/client/${this.client.slug}/dashboard/salesforce`,
            connected: this.salesforceConnected,
            disabled: !this.salesforceConnected,
            imageSrc: "../../assets/images/logo/salesforce.svg",
          },
          {
            name: "dv360",
            to: `/client/${this.client.slug}/dashboard/dv360`,
            connected: this.dv360Connected,
            disabled: !this.dv360Connected,
            imageSrc: "../../assets/images/logo/dv360-logo.svg",
          },
        ];
        // Move disabled links to the end
        const orderedLinks = links.sort((a, b) => {
          if (a.connected && !b.connected) return -1;
          if (!a.connected && b.connected) return 1;
          return 0;
        });
        return orderedLinks;
      }
    },
    avatarText(value) {
      if (!value) return "";
      const nameArray = value.split(" ");
      return nameArray.map((word) => word.charAt(0).toUpperCase()).join("");
    },
    updateChartData() {
      let conversionsLabel = "";
      if (
        this.client.businessType[0] == "leadgen" ||
        this.client.businessType[0] == "leadform"
      ) {
        conversionsLabel = "Leads";
        this.seriesConversionsBenchMark = [
          {
            name: conversionsLabel,
            data: this.conversionsArray,
          },
        ];
      } else if (this.client.businessType[0] == "ecommerce") {
        conversionsLabel = "Purchases";
        this.seriesConversionsBenchMark = [
          {
            name: conversionsLabel,
            data: this.graphsWebPurchases,
          },
        ];
      } else {
        this.seriesConversionsBenchMark = [
          {
            name: conversionsLabel,
            data: this.conversionsArray,
          },
        ];
        conversionsLabel = "Conversions";
      }
      this.chartOptionsBench = {
        chart: {
          type: "line",
          toolbar: {
            show: false,
          },
          zoom: {
            enabled: false,
          },
        },
        grid: {
          borderColor: $trackBgColor,
          strokeDashArray: 5,
          yaxis: {
            lines: {
              show: false,
            },
          },
          padding: {
            top: -30,
            bottom: -12,
          },
        },
        stroke: {
          width: 3,
        },
        colors: ["#7ab240"],
        markers: {
          size: 2,
          colors: "#7ab240",
          strokeColors: "#7ab240",
          strokeWidth: 2,
          strokeOpacity: 1,
          strokeDashArray: 0,
          fillOpacity: 1,
          discrete: [
            {
              seriesIndex: 0,
              fillColor: "#ffffff",
              strokeColor: "#7ab240",
              size: 5,
            },
          ],
          shape: "circle",
          radius: 2,
          hover: {
            size: 3,
          },
        },
        xaxis: {
          categories: this.datesArray,
          labels: {
            show: true,
            style: {
              fontSize: "0px",
            },
          },
        },
        yaxis: {
          show: false,
        },
      };

      this.seriesCostBenchMark = [
        {
          name: "Spends",
          data: this.costArray,
        },
      ];
    },
    updateBenchMarkData() {
      this.seriesClicks = [
        parseFloat(this.metaClicks),
        parseFloat(this.amazonClicks),
        parseFloat(this.googleClicks),
        parseFloat(this.dv360Clicks),
      ];
      this.seriesSpends = [
        parseFloat(this.metaSpends),
        parseFloat(this.amazonSpends),
        parseFloat(this.googleSpends),
        parseFloat(this.dv360Spends),
      ];
      this.seriesImpressions = [
        parseFloat(this.metaImpressions),
        parseFloat(this.amazonImpressions),
        parseFloat(this.googleImpressions),
        parseFloat(this.dv360Impressions),
      ];
      this.seriesConversions = [
        parseFloat(this.metaConversion),
        parseFloat(this.amazonConversion),
        parseFloat(this.googleConversion),
      ];
      this.seriesWebPurchases = [
        parseFloat(this.metaWebPurchases),
        parseFloat(this.amazonConversion),
        parseFloat(this.googleConversion),
      ];
      this.seriesRevenue = [
        parseFloat(this.metaRevenue),
        parseFloat(this.amazonRevenue),
        parseFloat(this.googleRevenue),
      ];
      this.seriesCPL = [
        parseFloat(this.metaCPL),
        parseFloat(this.amazonCPL),
        parseFloat(this.googleCPL),
      ];
      this.seriesCTR = [
        parseFloat(this.metaCTR),
        parseFloat(this.amazonCRT),
        parseFloat(this.googleCRT),
      ];
    },
    getCellStyle(columnColor, value) {
      if (value === 0) {
        return `background-color: white; text-align: center; color: #000000;`;
      }
      const ranges = [
        { min: 1, max: 999999 },
        { min: 100000, max: 99999999 },
      ];
      let opacity = 0;
      let rgbColor = "";
      for (const range of ranges) {
        if (value >= range.min && value <= range.max) {
          const rangeSize = range.max - range.min;
          opacity = (value - range.min) / rangeSize;
          opacity = opacity * 0.5 + 0.5;
          opacity = opacity.toFixed(2);
          rgbColor = this.hexToRgb(columnColor);
          break;
        }
      }
      return `background-color: rgba(${rgbColor}, ${opacity}); text-align: center; color: #555555;`;
    },
    formatNumberToTwoDecimals(number, decimal = 2) {
      try {
        if (number == undefined || number == null) {
          number = 0;
        }
        return new Intl.NumberFormat("en-IN", {
          minimumFractionDigits: 0,
          maximumFractionDigits: decimal,
        }).format(number);
      } catch (err) {
        return 0;
      }
    },
    hexToRgb(hex) {
      hex = hex.replace(/^#/, "");
      const bigint = parseInt(hex, 16);
      const r = (bigint >> 16) & 255;
      const g = (bigint >> 8) & 255;
      const b = bigint & 255;

      return `${r}, ${g}, ${b}`;
    },
    updateValues(values) {
      let startDate = moment(values.startDate).format("YYYY-MM-DD");
      let endDate = moment(values.endDate).format("YYYY-MM-DD");
      this.startDateForAll = startDate;
      this.endDateForAll = endDate;
      if (this.tabMultiChannelName === "Overview") {
        this.fetchBenchMarkAllData();
        this.fetchRealTimeData();
        this.fetchSpends();
      }
      if (
        this.client.accessConfiguration &&
        this.client.accessConfiguration.projects &&
        this.client.accessConfiguration.projects.enabled
      ) {
        if (this.tabMultiChannelName === "ProjectInsights") {
          this.fetchProjectWiseData();
          // this.getProductWiseForLaadsTableData();
        }
        if (this.tabMultiChannelName === "ZoneInsights") {
          this.fetchZonewiseData();
          this.getFetchOptions();
        }
        if (this.tabMultiChannelName === "AffiliateInsights") {
          this.fetchAffilatesComeData();
          this.getFetchAffilateOptions();
        }
        if (this.tabMultiChannelName === "ProductInsights") {
          this.fetchProjectProductWiseData();
          this.getFetchOptions();
        }
        if (this.tabMultiChannelName === "CreativeAnalysis") {
          this.getFetchOptions();
          this.getCreativesData();
        }
        if (this.tabMultiChannelName === "Trends") {
          this.getTrendsData();
          this.getTrendsTableData();
          this.getQualityVolumeTableData();
          this.getProductWiseForLaadsTableData();
        }
      }
      if (this.tabMultiChannelName === "ChannelPerformanceOverview") {
        this.fetchOverViewData();
      }
    },
    dateChanged(val) {
      this.$emit("fetchNewData", val.code);
    },
    dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("DD-MM-YYYY");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "DD-MM-YYYY"
      );
      return classes;
    },
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    setIntialClient() {
      this.selectedClient = this.client._id;
    },
    getClient() {
      const slug = this.$route.params.slug;
      this.idClientEntireLoad = true;
      this.showSummaryDashboardLoading = true;
      (this.googleAdsConnected = false),
        (this.amazonAdsConnected = false),
        (this.facebookAdsConnected = false),
        (this.googleAdsId = "");
      this.metaAdsId = "";
      this.amazonAdsId = "";
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/api/client/${slug}`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.client = { ...response.data.client, status: true };
          (this.googleAdsConnected = this.client.integrations.googleAds),
            (this.amazonAdsConnected = this.client.integrations.amazon),
            (this.facebookAdsConnected = this.client.integrations.facebook),
            (this.gmbConnected = this.client.integrations.gmb),
            (this.analyticsConnected = this.client.integrations.google),
            (this.shopifyAdsConnected = this.client.integrations.shopify);
          this.salesforceConnected = this.client.integrations.salesforce;
          this.dv360Connected = this.client.integrations.dv360;
          this.leadSquaredConnected = this.client.integrations.leadSquared;
          this.googleAdsId = response.data.client.googleAds.customerClientID;

          this.metaAdsId = response.data.client.facebook.adAccountIDs;

          this.amazonAdsId = response.data.client.amazon.profileIDs;
          this.facebookAdsAccount = response.data.client.facebook;
          this.googleAdsAccounts = response.data.client.googleAds;
          this.selectedReportID =
            response.data.client.googleSheetReports.daily &&
            response.data.client.googleSheetReports.daily[
            Object.keys(response.data.client.googleSheetReports.daily)
              .sort()
              .pop()
            ];
          this.idClientEntireLoad = false;
          this.breadcrumbs = [
            {
              text: "Overview",
              to: "/dashboard",
            },
          ];
          this.sheetUrl = this.client.gsheet;
          this.setIntialClient();
          //     if(this.client.accessConfiguration && this.client.accessConfiguration.projects && this.client.accessConfiguration.projects.enabled){
          //     // this.fetchProjectProductWiseData();
          //     // this.fetchZonewiseData();
          //     // this.getTrendsData();
          //     // this.fetchProjectWiseData();
          //     // this.getQualityVolumeTableData();
          //     // this.getProductWiseForLaadsTableData();
          //     // this.getTrendsTableData();
          //     // this.getFetchOptions();
          //     if(this.tabMultiChannelName = "ProjectInsights"){
          //   this.fetchProjectWiseData();
          //   this.getProductWiseForLaadsTableData();
          // }
          // if(this.tabMultiChannelName = "ZoneInsights"){
          //   this.fetchZonewiseData();
          //   this.getFetchOptions();
          // }
          // if(this.tabMultiChannelName = "ProductInsights"){
          //   this.fetchProjectProductWiseData()
          // }
          // if(this.tabMultiChannelName = "Trends"){
          //   this.getTrendsData();
          //   this.getTrendsTableData();
          //   this.getQualityVolumeTableData();
          // }
          //     }
          if ((this.tabMultiChannelName = "Overview")) {
            this.fetchBenchMarkAllData();
            this.fetchRealTimeData();
            this.fetchSpends();
            if(this.client.businessType[0] === 'leadgen'){
              this.fetchPerformanceData("daily");
            }
            
          }

          // if(this.tabMultiChannelName = "ChannelPerformanceOverview"){
          //   this.fetchOverViewData()
          // }
          // this.fetchZonewiseData();
          // this.getTrendsData();
          // this.getQualityVolumeTableData();
          // this.getProductWiseForLaadsTableData()
          // this.getTrendsTableData();
          // this.fetchProjectProductWiseData();

          // this.getFetchOptions();
          this.breadcrumbs.push({
            text: response.data.client.name,
            to: "/client",
            active: true,
          });
          this.showSummaryDashboardLoading = false;
          setTimeout(() => (this.isMTDFetched = false), 1000);
        })
        .catch((error) => {
          this.idClientEntireLoad = false;
          console.log(error.response.data);
          this.showSummaryDashboardLoading = false;
          this.client.status = false;
          this.errorMessage = error.response.data.error;
        });
    },
    fetchRealTimeData() {
      // this.showSummaryDashboardLoading = true;
      this.isLiveDataIsLoad = true;
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/realtime?clientID=${this.selectedClient}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchRealTime = response.data;
          if (this.fetchRealTime.google) {
            this.googleConversionToday = this.fetchRealTime.google.conversions.today;
            this.googleConversionYeserday = this.fetchRealTime.google.conversions.yesterday;
            this.googleConversionDelta = this.fetchRealTime.google.conversions.delta;
            this.googleSpendsToday = this.fetchRealTime.google.spends.today;
            this.googleSpendsYeserday = this.fetchRealTime.google.spends.yesterday;
            this.googleSpendsDelta = this.fetchRealTime.google.spends.delta;
            this.googleWebPurchasesToday = this.fetchRealTime.google.webPurchases.today;
            this.googleWebPurchasesYeserday = this.fetchRealTime.google.webPurchases.yesterday;
            this.googleWebPurchasesDelta = this.fetchRealTime.google.webPurchases.delta;
          }
          if (this.fetchRealTime.amazon) {
            this.amazonConversionToday = this.fetchRealTime.amazon.conversions.today;
            this.amazonConversionYeserday = this.fetchRealTime.amazon.conversions.yesterday;
            this.amazonConversionDelta = this.fetchRealTime.amazon.conversions.delta;
            this.amazonSpendsToday = this.fetchRealTime.amazon.spends.today;
            this.amazonSpendsYeserday = this.fetchRealTime.amazon.spends.yesterday;
            this.amazonSpendsDelta = this.fetchRealTime.amazon.spends.delta;
            this.amazonWebPurchasesToday = this.fetchRealTime.amazon.webPurchases.today;
            this.amazonWebPurchasesYeserday = this.fetchRealTime.amazon.webPurchases.yesterday;
            this.amazonWebPurchasesDelta = this.fetchRealTime.amazon.webPurchases.delta;
          }
          if (this.fetchRealTime.Meta) {
            this.metaConversionToday = this.fetchRealTime.Meta.conversions.today;
            this.metaConversionYeserday = this.fetchRealTime.Meta.conversions.yesterday;
            this.metaConversionDelta = this.fetchRealTime.Meta.conversions.delta;
            this.metaSpendsToday = this.fetchRealTime.Meta.spends.today;
            this.metaSpendsYeserday = this.fetchRealTime.Meta.spends.yesterday;
            this.metaSpendsDelta = this.fetchRealTime.Meta.spends.delta;
            this.metaWebPurchasesToday = this.fetchRealTime.Meta.webPurchases.today;
            this.metaWebPurchasesYeserday = this.fetchRealTime.Meta.webPurchases.yesterday;
            this.metaWebPurchasesDelta = this.fetchRealTime.Meta.webPurchases.delta;
          } // dv360 - code
          if (this.fetchRealTime.dv360) {
            this.dv360SpendsToday = this.fetchRealTime.dv360.spends.today;
            this.dv360SpendsYeserday = this.fetchRealTime.dv360.spends.yesterday;
            this.dv360SpendsDelta = this.fetchRealTime.dv360.spends.delta;
            this.dv360clicksToday = this.fetchRealTime.dv360.clicks.today;
            this.dv360clicksYeserday = this.fetchRealTime.dv360.clicks.yesterday;
            this.dv360clicksDelta = this.fetchRealTime.dv360.clicks.delta;
            this.dv360impressionsToday = this.fetchRealTime.dv360.impressions.today;
            this.dv360impressionsYeserday = this.fetchRealTime.dv360.impressions.yesterday;
            this.dv360impressionsDelta = this.fetchRealTime.dv360.impressions.delta;
          }
          // this.showSummaryDashboardLoading = false;
          this.isLiveDataIsLoad = false;
        })
        .catch((error) => {
          this.isLiveDataIsLoad = false;
          console.log(error);
          // this.showSummaryDashboardLoading = false;
        });
    },
    fetchProjectWiseData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.showSummaryDashboardLoading = true;
      this.isProjectWiseIsLoad = true;
      this.projectWiseData = {};
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.hideOverallProjectsTab = false;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/projects/overall-project-insights?clientID=${this.selectedClient}&channel=all&${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.salesforceDataFound = false;
          if (
            response.data?.overall?.[0]?.insights?.salesforce &&
            typeof response.data.overall[0].insights.salesforce === "object"
          ) {
            this.salesforceDataFound = true;
          }
          this.projectWiseData = response.data;
          if (Object.keys(this.projectWiseData).length == 0) {
            this.hideOverallProjectsTab = true;
          }
          this.isProjectWiseIsLoad = false;
        })
        .catch((error) => {
          this.isProjectWiseIsLoad = false;
          console.log(error);
          // this.showSummaryDashboardLoading = false;
        });
    },
    fetchOverViewData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.showSummaryDashboardLoading = true;
      this.projectOverViewLoad = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/performance_overview?clientID=${this.selectedClient}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchOverViewAllData = response.data;
          this.updateChartData();
          this.updateBenchMarkData();
          this.showSummaryDashboardLoading = false;
          this.projectOverViewLoad = false;
        })
        .catch((error) => {
          this.showSummaryDashboardLoading = false;
          this.projectOverViewLoad = false;
          console.log(error);
        });
    },
    fetchBenchMarkAllData(
      dateRange,
      campaignType = null,
      campaigns = null,
      adGroups = null,
      startDate = null,
      endDate = null
    ) {
      // this.isReportDataLoading = true;
      if (!dateRange) {
        dateRange = "LAST_7_DAYS";
      }
      this.costArray = [];
      this.isRevenueEmpty = true;
      this.datesArray = [];
      this.conversionsArray = [];
      this.showSummaryDashboardLoadingGraph = true;
      this.currentDateRange = dateRange;
      let additionalQueryParams = ``;
      if (!this.endDateForAll && !this.startDateForAll) {
        const today = new Date();
        const prevWeek = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
        const yesterday = new Date(today.getTime() - 1 * 24 * 60 * 60 * 1000);
        const formattedStartDate = prevWeek.toISOString().split("T")[0];
        const formattedEndDate = yesterday.toISOString().split("T")[0];

        additionalQueryParams = `${additionalQueryParams}&startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
      }
      if (this.startDateForAll && this.endDateForAll) {
        additionalQueryParams =
          additionalQueryParams +
          `&startDate=${this.startDateForAll}&endDate=${this.endDateForAll}`;
      }
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/benchmark?clientID=${this.selectedClient}${additionalQueryParams}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.fetchBenchMarkAllDataValue = response.data.dateWise.datewise;
          const datesArray = Object.keys(this.fetchBenchMarkAllDataValue);
          const datesAllArray = [];
          const totalCostArray = [];
          const totalConversionsArray = [];
          const totalWebPurchases = [];
          const dataArray = datesArray.map((date) => {
            const totalData = this.fetchBenchMarkAllDataValue[date].total;
            datesAllArray.push(date);
            const totalCost = parseFloat(totalData.cost);
            const totalConversions = parseFloat(totalData.conversions);
            const totalBenchWebConversions = parseFloat(totalData.webPurchase);
            totalWebPurchases.push(totalBenchWebConversions);
            totalCostArray.push(totalCost);
            totalConversionsArray.push(totalConversions);
            return {
              date,
              total: { ...totalData },
            };
          });
          this.costArray = totalCostArray;
          this.datesArray = datesAllArray;
          this.conversionsArray = totalConversionsArray;
          this.graphsWebPurchases = totalWebPurchases;
          this.chartDataValues;
          this.fetchBenchMarkDataValue = response.data.metricWise;
          this.googleConversion = this.fetchBenchMarkDataValue.conversions.google;
          this.googleSpends = this.fetchBenchMarkDataValue.spends.google;
          this.googleImpressions = this.fetchBenchMarkDataValue.impressions.google;
          this.googleClicks = this.fetchBenchMarkDataValue.clicks.google;
          //DV-360
          this.dv360Spends = this.fetchBenchMarkDataValue.spends.dv360;
          this.dv360Impressions = this.fetchBenchMarkDataValue.impressions.dv360;
          this.dv360Clicks = this.fetchBenchMarkDataValue.clicks.dv360;
          this.googleWebPurchases = this.fetchBenchMarkDataValue.webPurchase.google;
          this.clicksTotal = this.fetchBenchMarkDataValue.clicks.total;
          this.webPurchasesTotal = this.fetchBenchMarkDataValue.webPurchase.total;
          this.metaConversion = this.fetchBenchMarkDataValue.conversions.meta;
          this.metaSpends = this.fetchBenchMarkDataValue.spends.meta;
          this.metaImpressions = this.fetchBenchMarkDataValue.impressions.meta;
          this.metaClicks = this.fetchBenchMarkDataValue.clicks.meta;
          this.metaWebPurchases = this.fetchBenchMarkDataValue.webPurchase.meta;
          this.amazonConversion =
            this.fetchBenchMarkDataValue.conversions.amazon == "NA"
              ? 0
              : this.fetchBenchMarkDataValue.conversions.amazon;
          this.amazonSpends = this.fetchBenchMarkDataValue.spends.amazon;
          this.amazonImpressions = this.fetchBenchMarkDataValue.impressions.amazon;
          this.amazonClicks = this.fetchBenchMarkDataValue.clicks.amazon;
          // this.impressionsTotal = this.fetchBenchMarkDataValue.impressions.total
          this.impressionsTotal = this.fetchBenchMarkDataValue.impressions.total;
          this.spendsTotal = this.fetchBenchMarkDataValue.spends.total;
          this.conversionsTotal = this.fetchBenchMarkDataValue.conversions.total;
          this.revenueTotal = this.fetchBenchMarkDataValue.revenue.total;
          this.cplTotal = this.fetchBenchMarkDataValue.cpl.total;
          this.metaCPL = this.fetchBenchMarkDataValue.cpl.meta;
          this.amazonCPL = this.fetchBenchMarkDataValue.cpl.amazon;
          this.googleCPL = this.fetchBenchMarkDataValue.cpl.google;
          this.ctrTotal = this.fetchBenchMarkDataValue.ctr.total;
          this.metaCTR = this.fetchBenchMarkDataValue.ctr.meta;
          this.amazonCRT = this.fetchBenchMarkDataValue.ctr.amazon;
          this.googleCRT = this.fetchBenchMarkDataValue.ctr.google;
          if (this.revenueTotal == 0) {
            this.isRevenueEmpty = false;
          }
          this.metaRevenue = this.fetchBenchMarkDataValue.revenue.meta;
          this.googleRevenue = this.fetchBenchMarkDataValue.revenue.google;
          this.amazonRevenue = this.fetchBenchMarkDataValue.revenue.amazon;
          this.updateChartData();
          this.updateBenchMarkData();
          this.showSummaryDashboardLoadingGraph = false;
        })
        .catch((error) => {
          console.log(error);
          this.showSummaryDashboardLoadingGraph = false;
        });
    },
    fetchMultiChannelDashboardData() {
      this.fetchBenchMarkAllData();
      if (
        this.client.accessConfiguration &&
        this.client.accessConfiguration.projects &&
        this.client.accessConfiguration.projects.enabled
      ) {
        this.fetchProjectProductWiseData();
        this.fetchZonewiseData();
        this.fetchAffilatesComeData();
        this.getTrendsData();
        this.fetchProjectWiseData();
        this.fetchProductOutComeData();
        this.getQualityVolumeTableData();
        this.getProductWiseForLaadsTableData();
        this.getTrendsTableData();
        this.getFetchOptions();
        this.getCreativesData();
      }
      this.fetchOverViewData();
      this.fetchRealTimeData();
      // this.fetchZonewiseData();
      // this.getTrendsData();
      // this.getQualityVolumeTableData();
      // this.getProductWiseForLaadsTableData()
      // this.getTrendsTableData();
      // this.fetchProjectProductWiseData();
      // this.updateChartData();
      // this.updateBenchMarkData();
    },
    prevSlide() {
      this.slide = (this.slide - 1 + 3) % 3;
    },
    nextSlide() {
      this.slide = (this.slide + 1) % 3;
    },
    resetData() {
      this.spendsAmount = 0;
      (this.triggerName = ""), (this.selectedCampaigns = []);
      this.selectedSpendsChannel = [];
      this.matchingIds = [];
      this.clientData = {};
      this.receivedDate = new Date().toISOString().substr(0, 10);
      this.startTrackingDate = new Date().toISOString().substr(0, 10);
      this.endTrackingDate = new Date().toISOString().substr(0, 10);
    },
    // },
  },
  filters: {
    date(val) {
      return val ? moment(val).format("DD-MM-YYYY") : "";
    },
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [new Date(today.getFullYear(), 0, 1), new Date(today)],
      };

      return ranges;
    },
    groupedData() {
      const grouped = {};
      this.performanceData.forEach((entry) => {
        if (!grouped[entry.metric]) {
          grouped[entry.metric] = { metric: entry.metric, channels: [] };
        }
        grouped[entry.metric].channels.push(entry);
      });
      return Object.values(grouped);
    },
    paginatedScheduleTriggerHistoryData() {
      const start =
        (this.SpendsTriggersCurrentPage - 1) * this.SpendsTriggersItems;
      return (
        this.filteredDataBasedOnIsActive.length > 0 &&
        this.filteredDataBasedOnIsActive.slice(
          start,
          start + this.SpendsTriggersItems
        )
      );
    },
    paginatedTriggerSpendsHistoryData() {
      const start =
        (this.TriggersHistoryCurrentPage - 1) * this.TriggersHistoryItems;
      return (
        this.transactions.length > 0 &&
        this.transactions.slice(start, start + this.TriggersHistoryItems)
      );
    },
    selectedFetchByClient: {
      get() {
        return this.fetchByClient;
      },
      set(value) {
        this.fetchByClient = value === "overall" ? "overall" : "account";
        this.resetData();
      },
    },
    channelOptions() {
      if (!this.client.integrations) {
        return [];
      }

      let selectedGoogleAccounts = this.client.googleAds.customerNames;
      let selectedMetaAccounts = this.client.facebook.adAccountNames;

      // this.selectedAccount = [...selectedGoogleAccounts, ...selectedMetaAccounts];
      let optionToShow = ["googleAds", "facebook"]
        .filter((key) => this.client.integrations[key])
        .map((key) => (key === "googleAds" ? "GoogleAds" : "MetaAds"));
      return optionToShow;
    },

    hasActiveTransaction() {
      return this.spendsTrasactionData.some((tx) => tx.isActive);
    },
    campaignOverallNames() {
      // this.campaignNames = this.allCampaignNames.map(campaign => campaign.campaignName);
      return this.selectedFetchByClient === "account"
        ? [...this.metaAccountCampaiginName, ...this.googleAccountCampaiginName] // Merge arrays
        : Object.values(this.storeCampaign || {}) // Ensure storeCampaign is an object
          .flat()
          .map((campaign) => campaign.campaignName);
    },
    accountOverallNames() {
      if (!this.client || !this.client.integrations) {
        return [];
      }

      let googleAccountOptions = this.client.googleAds?.customerNames || [];
      let metaAccountOptions = this.client.facebook?.adAccountNames || [];

      // Dono arrays empty hain to empty array return karenge
      if (
        googleAccountOptions.length === 0 &&
        metaAccountOptions.length === 0
      ) {
        return [];
      }

      if (this.selectedSpendsChannel.length > 0) {
        if (
          (this.selectedSpendsChannel.includes("GoogleAds") ||
            this.selectedSpendsChannel.includes("google")) &&
          (this.selectedSpendsChannel.includes("MetaAds") ||
            this.selectedSpendsChannel.includes("meta"))
        ) {
          return [...googleAccountOptions, ...metaAccountOptions]; // Merge both options
        } else if (
          this.selectedSpendsChannel.includes("GoogleAds") ||
          this.selectedSpendsChannel.includes("google")
        ) {
          return googleAccountOptions;
        } else if (
          this.selectedSpendsChannel.includes("MetaAds") ||
          this.selectedSpendsChannel.includes("meta")
        ) {
          return metaAccountOptions;
        }
      }

      return [];
    },

    state() {
      return this.dirty
        ? this.emailAddresses.length > 2 && this.emailAddresses.length < 9
        : null;
    },
    dynamicStyle() {
      return this.tabMultiChannelName === "ZoneInsights" ||
        this.tabMultiChannelName === "ProductOutCome"
        ? { marginTop: "-10px" }
        : {};
    },
    getChannelOverviewData() {
      let columns = [];
      if (
        this.client.businessType[0] == "leadgen" ||
        this.client.businessType[0] == "leadform"
      ) {
        columns = [
          { title: "Campaign", color: "#8B865F" },
          { title: "Data Source", color: "#ffffff" },
          { title: "Clicks", color: "#fd800d" },
          { title: "Impressions", color: "#2c5784" },
          { title: "Spends", color: "#26673d" },
          { title: "Leads", color: "#f0f0f0" },
        ];
      } else if (this.client.businessType[0] === "ecommerce") {
        columns = [
          { title: "Campaign", color: "#8B865F" },
          { title: "Data Source", color: "#ffffff" },
          { title: " Spends ", color: "#26673d " },
          { title: "Impressions", color: "#2c5784" },
          { title: "Clicks", color: "#fd800d" },
          { title: "Purchases", color: "#f0f0f0" },
          { title: "CTR", color: "#BFECFF" },
          { title: "CPC", color: "#CB9DF0" },
        ];
      } else if (this.client.businessType[0] === "branding") {
        columns = [
          { title: "Campaign", color: "#8B865F" },
          { title: "Data Source", color: "#ffffff" },
          { title: "Spends", color: "#26673d" },
          { title: "Impressions", color: "#D0E8C5" },
          { title: "Viewable Impressions", color: "#BFECFF" },
          // { title: "CPM", color: "#37AFE1" },
          { title: "Views", color: "#CB9DF0" },
          { title: "CPV", color: "#f0f0f0" },
          { title: "Avg. Impr. freq./ user", color: "#F4F6FF" },
          { title: "VTR", color: "#26673d" },
          { title: "Clicks", color: "#37AFE1" },
          { title: "CTR", color: "#fd800d" },
        ];
      } else {
        columns = [
          { title: "Campaign", color: "#8B865F" },
          { title: "Data Source", color: "#ffffff" },
          { title: "Clicks", color: "#fd800d" },
          { title: "Impressions", color: "#2c5784" },
          { title: "Spends", color: "#26673d" },
          { title: "Conversions", color: "#f0f0f0" },
        ];
      }
      return columns;
    },
    maxDate() {
      const today = new Date();
      return today.toISOString().split("T")[0]; // Convert to ISO date string
    },
    startTrackingDateConditionally() {
      return this.transactionStarts && this.transactionStarts.length > 0
        ? this.transactionStarts
        : this.startTrackingDate;
    },
    isTrackingDateDisabled() {
      if (!this.isUpdatingTransaction) {
        return !this.filteredDataBasedOnAct;
      }
    },
    isChannelsDisabled() {
      return this.selectedSpendsChannel.length <= 0;
    },
    isEdittrackButtonDisabled() {
      if (
        this.isCreateButtonDisabled === true &&
        this.selectedSpendsChannel.length > 0
      ) {
        return true;
      }
      return false;
    },
    isCreateButtonDisabled() {
      if (this.hasActiveTransaction && !this.isUpdatingTransaction) {
        return true;
      }
      if (this.isWhatsAppNotificationEnable) {
        return this.spendsAmount <= 0 || this.whatsAppNumbers.length <= 0;
      } else {
        return this.spendsAmount <= 0;
      }
    },
    isEditButtonDisabled() {
      return this.editSpendsAmount <= 0;
    },
    isCreateButtonDisabled() {
      return this.spendsAmount <= 0 || this.triggerName.length === 0;
    },
    processedData() {
      // Add the "extraField" to each item with the fixed value "aks"
      return this.spendsTrasactionData.map((item) => {
        return {
          ...item,
          // extraField:  this.transactionStarts
        };
      });
    },
    filteredDataBasedOnIsActive() {
      return this.spendsTracker;
    },
    filteredDataBasedOnAct() {
      const allInactive = this.processedData.every((item) => !item.isActive);
      return allInactive;
    },
    dynamicArray() {
      const dynamicArray = [];
      const dailyReports = this.client.googleSheetReports.daily;
      const months = [
        { label: "January", value: 1 },
        { label: "February", value: 2 },
        { label: "March", value: 3 },
        { label: "April", value: 4 },
        { label: "May", value: 5 },
        { label: "June", value: 6 },
        { label: "July", value: 7 },
        { label: "August", value: 8 },
        { label: "September", value: 9 },
        { label: "October", value: 10 },
        { label: "November", value: 11 },
        { label: "December", value: 12 },
      ];

      // Iterate through the keys (dates) of the dailyReports object
      for (const date in dailyReports) {
        if (Object.hasOwnProperty.call(dailyReports, date)) {
          const [month, year] = date.split("-");
          const monthObj = months.find((m) => m.value.toString() === month);
          if (monthObj) {
            dynamicArray.push({
              label: monthObj.label,
              value: parseInt(month),
            });
          }
        }
      }
      dynamicArray.sort((a, b) => a.value - b.value);
      return dynamicArray;
    },
    optionsAds() {
      const ads = [];
      // Check if the client data exists and is not empty
      if (this.client && Object.keys(this.client).length > 0) {
        const { integrations } = this.client;
        let count = 0;
        if (integrations.googleAds) count++;
        if (integrations.facebook) count++;
        if (integrations.amazon) count++;
        // If there are at least two integrations, populate the ads array
        if (count >= 2) {
          this.selectedAdsOption = "all";
          ads.push({ value: "all", text: "All" });
          if (integrations.googleAds) {
            ads.push({ value: "google-ads", text: "Google Ads" });
          }
          if (integrations.facebook) {
            ads.push({ value: "meta-ads", text: "Meta Ads" });
          }
          if (integrations.amazon) {
            ads.push({ value: "amazon-ads", text: "Amazon Ads" });
          }
        } else {
          if (integrations.googleAds) {
            this.selectedAdsOption = "google-ads";
            ads.push({ value: "google-ads", text: "Google Ads" });
          }
          if (integrations.facebook) {
            this.selectedAdsOption = "meta-ads";
            ads.push({ value: "meta-ads", text: "Meta Ads" });
          }
          if (integrations.amazon) {
            this.selectedAdsOption = "amazon-ads";
            ads.push({ value: "amazon-ads", text: "Amazon Ads" });
          }
        }
      }
      return ads;
    },
    // optionsCampaignNamses() {
    // return this.fetchOverViewAllData.map(entry => entry.campaign);
    //  },
    getCols() {
      const integrations = this.client.integrations;
      if (this.googleAdsConnected) {
        if (
          this.googleAdsConnected &&
          this.amazonAdsConnected &&
          this.facebookAdsConnected
        ) {
          return "4";
        } else if (this.googleAdsConnected && this.amazonAdsConnected) {
          return "3";
        } else if (this.googleAdsConnected && this.facebookAdsConnected) {
          return "3";
        } else if (this.amazonAdsConnected && this.facebookAdsConnected) {
          return "3";
        } else {
          return "4";
        }
      } else {
        return "";
      }
    },
    getReportsCols() {
      if (this.selectedReportType == "Weekly") {
        return "5";
      }
      if (this.selectedReportType == "Daily") {
        return "3";
      }
    },
    getCardCols() {
      const integrations = this.client.integrations;
      if (this.googleAdsConnected) {
        if (
          this.googleAdsConnected &&
          this.amazonAdsConnected &&
          this.facebookAdsConnected
        ) {
          return "6";
        } else if (this.googleAdsConnected && this.amazonAdsConnected) {
          return "3";
        } else if (this.googleAdsConnected && this.facebookAdsConnected) {
          return "3";
        } else if (this.amazonAdsConnected && this.facebookAdsConnected) {
          return "3";
        } else if (this.amazonAdsConnected) {
          return "4";
        } else if (this.googleAdsConnected) {
          return "4";
        } else if (this.facebookAdsConnected) {
          return "4";
        } else {
          return "3";
        }
      } else {
        return "3";
      }
    },
    chartDataValues() {
      let data = this.fetchBenchMarkAllDataValue;
    },
    displayedOverviewItems() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = start + this.perPage;
      let filteredData;

      // Apply filtering based on the selected ads option
      switch (this.selectedAdsOption) {
        case "google-ads":
          filteredData = this.fetchOverViewAllData.filter(
            (item) => item.source === "google"
          );
          break;
        case "meta-ads":
          filteredData = this.fetchOverViewAllData.filter(
            (item) => item.source === "meta"
          );
          break;
        case "amazon-ads":
          filteredData = this.fetchOverViewAllData.filter(
            (item) => item.source === "amazon"
          );
          break;
        default:
          filteredData = this.fetchOverViewAllData;
          break;
      }

      // Apply search filtering if campaign name search is specified
      if (
        this.searchByCampaignName &&
        this.searchByCampaignName.trim() !== ""
      ) {
        filteredData = filteredData.filter((item) =>
          item.campaign
            .toLowerCase()
            .includes(this.searchByCampaignName.toLowerCase())
        );
      }

      // Ensure "total" field is always included in the filtered data
      const totalField = this.fetchOverViewAllData.find(
        (item) => item.campaign === "total" && item.source === "all"
      );
      if (
        totalField &&
        !filteredData.some((item) => item.campaign === "total")
      ) {
        filteredData = [totalField, ...filteredData];
      }

      // Set the total number of filtered items and return the paginated results
      this.totalOverviewData(filteredData.length);
      return filteredData.slice(start, end);
    },
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, today],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [new Date(today.getFullYear(), 0, 1), new Date(today)],
      };

      return ranges;
    },
  },
  watch: {

    dateRange1: {
      handler() {
        if(this.client.businessType[0] === 'leadgen'){
              this.fetchPerformanceData("custom");
        }
      }
    },
    dateRange2: {
      deep: true,
      handler() {
        if(this.client.businessType[0] === 'leadgen'){
              this.fetchPerformanceData("custom");
         }
      }
    },
    storeMetaAccountCampaign: {
      // Watch Meta data as well
      handler(newVal) {
        if (Array.isArray(newVal) && newVal.length > 0) {
          this.processCampaigns();
        }
      },
      deep: true,
      immediate: true,
    },

    storeGoogleAccountCampaign: {
      handler(newVal) {
        if (Array.isArray(newVal) && newVal.length > 0) {
          this.processCampaigns();
        }
      },
      deep: true,
      immediate: true,
    },
    fetchByClient(newVal) {
      if (newVal !== "overall") {
        this.fetchByClient = "account";
      }
    },
    // fetchByClient(newval, oldval) {
    //   if (newval == false) this.fetchMetaAddsAccounts();
    //   this.fetchGoogleAddsAccoutns();
    // },
    selectedCampaigns(newval, oldval) {
      if (this.isUpdatingTransaction && newval.length < oldval.length) {
        const removedCampaigns = oldval.find((item) => !newval.includes(item));
        const removedCampaignId = this.campaignList.find((campaign) => {
          return campaign.campaignName === removedCampaigns;
        })?.campaignId;
        const removedIdString = String(removedCampaignId);
        Object.keys(this.campaignIdList).forEach((key) => {
          if (Array.isArray(this.campaignIdList[key])) {
            this.campaignIdList[key] = this.campaignIdList[key].filter(
              (campaignId) => String(campaignId) !== removedIdString
            );
          }
        });
      }
    },

    selectedAccount(newval, oldval) {
      if (!newval || !oldval || newval === oldval) return;

      let clientData = this.client;
      if (!clientData) return;

      let googleIds = [];
      let metaIds = [];

      // Convert values to sets for easy comparison
      const newSet = new Set(Array.isArray(newval) ? newval : [newval]);
      const oldSet = new Set(Array.isArray(oldval) ? oldval : [oldval]);

      // Identify added and removed accounts
      const addedAccounts = [...newSet].filter((acc) => !oldSet.has(acc));
      const removedAccounts = [...oldSet].filter((acc) => !newSet.has(acc));

      // Process GoogleAds selection changes
      if (
        this.selectedSpendsChannel.includes("GoogleAds") ||
        this.selectedSpendsChannel.includes("google")
      ) {
        if (clientData.googleAds && typeof clientData.googleAds === "object") {
          let { customerNames, customerClientIDs } = clientData.googleAds;

          if (
            Array.isArray(customerNames) &&
            Array.isArray(customerClientIDs)
          ) {
            addedAccounts.forEach((name) => {
              let index = customerNames.indexOf(name);
              if (index !== -1 && customerClientIDs[index])
                googleIds.push(customerClientIDs[index]);
            });

            // Remove campaigns for deselected accounts
            removedAccounts.forEach((name) => {
              let index = customerNames.indexOf(name);
              if (index !== -1 && customerClientIDs[index]) {
                this.campaignIdList["google"] = this.campaignIdList[
                  "google"
                ].filter((id) => id !== customerClientIDs[index]);
              }
            });
          }
        }

        if (googleIds.length) this.fetchGoogleAddsCampaign(googleIds);
      }

      // Process MetaAds selection changes
      if (
        this.selectedSpendsChannel.includes("MetaAds") ||
        this.selectedSpendsChannel.includes("meta")
      ) {
        if (clientData.facebook && typeof clientData.facebook === "object") {
          let { adAccountNames, adAccountIDs } = clientData.facebook;

          if (Array.isArray(adAccountNames) && Array.isArray(adAccountIDs)) {
            addedAccounts.forEach((name) => {
              let index = adAccountNames.indexOf(name);
              if (index !== -1 && adAccountIDs[index])
                metaIds.push(adAccountIDs[index]);
            });

            // Remove campaigns for deselected accounts
            removedAccounts.forEach((name) => {
              let index = adAccountNames.indexOf(name);
              if (index !== -1 && adAccountIDs[index]) {
                this.campaignIdList["meta"] = this.campaignIdList[
                  "meta"
                ].filter((id) => id !== adAccountIDs[index]);
              }
            });
          }
        }

        if (metaIds.length) this.fetchMetaAddsCampaign(metaIds);
      }
    },

    selectedSpendsChannel(newValue, oldValue) {
      if (this.selectedFetchByClient === "account") {
        if (newValue.length < oldValue.length) {
          // Find removed channels
          let removedChannels = oldValue.filter(
            (channel) => !newValue.includes(channel)
          );
          let channel = removedChannels[0];

          if (channel === "MetaAds" || channel == "meta") {
            channel = "meta";
            if (this.metaAccountCampaiginName) {
              this.selectedCampaigns = this.selectedCampaigns.filter(
                (campaign) => !this.metaAccountCampaiginName.includes(campaign)
              );
            }
            // Remove Meta-related campaigns from campaignIdList
            if (this.campaignIdList) {
              this.campaignIdList["meta"] = [];
              this.selectedCampaignData["meta"] = [];
            }

            if (this.client.facebook) {
              let { adAccountNames, adAccountIDs } = this.client.facebook;

              if (
                Array.isArray(adAccountNames) &&
                Array.isArray(adAccountIDs)
              ) {
                // Remove accounts from selectedAccount that exist in adAccountNames
                this.selectedAccount = this.selectedAccount.filter(
                  (name) => !adAccountNames.includes(name)
                );
              }
            }

            if (this.campaignIdList) {
              this.campaignIdList["meta"] = [];
            }
          } else if (channel === "GoogleAds" || channel === "google") {
            channel = "google";
            if (this.googleAccountCampaiginName) {
              this.selectedCampaigns = this.selectedCampaigns.filter(
                (campaign) =>
                  !this.googleAccountCampaiginName.includes(campaign)
              );
            }

            // Remove Google-related campaigns from campaignIdList
            if (this.campaignIdList) {
              this.campaignIdList["google"] = [];
            }

            if (this.client.googleAds) {
              let { customerNames } = this.client.googleAds;
              if (
                Array.isArray(this.selectedAccount) &&
                Array.isArray(customerNames)
              ) {
                this.selectedAccount = this.selectedAccount.filter(
                  (name) => !customerNames.includes(name)
                );
              }
            }
            if (this.campaignIdList) {
              this.campaignIdList["google"] = [];
              this.selectedCampaignData["google"] = [];
            }
          }
        }
      } else {
        const deletedChannel = oldValue.find(
          (channel) => !newValue.includes(channel)
        );

        if (deletedChannel) {
          this.campaignIdList[deletedChannel] = [];

          const removedChannels = oldValue.filter((channel) => {
            return !newValue.includes(channel);
          });
          if (removedChannels.length > 0) {
            removedChannels.forEach((channel) => {
              if (channel === "MetaAds" || channel === "meta") {
                channel = "meta";
                this.campaignIdList[channel] = [];
              } else if (channel === "GoogleAds" || channel === "google") {
                channel = "google";
                this.campaignIdList[channel] = [];
              }
              this.storeCampaign[channel]?.find((value) => {
                this.selectedCampaigns = this.selectedCampaigns.filter(
                  (campaign) => {
                    return campaign !== value.campaignName;
                  }
                );
              });

              if (this.storeCampaign.hasOwnProperty(channel)) {
                delete this.storeCampaign[channel];
              }
            });
          }
        }
      }
    },
    endTrackingDate(newEndDate) {
      if (this.startTrackingDate && newEndDate < this.startTrackingDate) {
        this.$bvToast.toast("End date cannot be earlier than start date!", {
          title: "Date Error",
          variant: "danger",
          solid: true,
        });

        // Reset the end date to start date to avoid invalid selection
        this.endTrackingDate = this.startTrackingDate;
      }
    },
    startTrackingDate(newEndDate, oldEndDate) {
      if (new Date(this.startTrackingDate) > new Date(this.endTrackingDate)) {
        this.endTrackingDate = this.startTrackingDate;
      }
    },
    selectedXLMonth(newVal, oldVal) {
      this.selectedXLMonth = 0;
      this.selectedXLMonth = newVal;
      this.fetchProjectOutComeXL();
    },
    getChangedProjectNameTarget(newVal, oldVal) {
      this.getChangedProjectNameTarget = "";
      this.getChangedProjectNameTarget = newVal;
      this.fetchTargetVsAchived();
    },
    targetType(newVal, oldVal) {
      this.targetType = "";
      this.targetType = newVal;
      this.fetchTargetVsAchived();
    },
    channelType(newVal, oldVal) {
      this.channelType = "";
      this.channelType = newVal;
      this.fetchTargetVsAchived();
    },
    CreativesProjectName(newVal, oldVal) {
      this.CreativesProjectName = "";
      this.CreativesProjectName = newVal;
      this.getCreativesData();
    },
    // selectedCreativesMetricType(newVal, oldVal) {
    //   this.selectedCreativesMetricType = ""
    //   this.selectedCreativesMetricType = newVal
    //   this.getCreativesData()
    // },
    selectedCreativesChannelType(newVal, oldVal) {
      this.selectedCreativesChannelType = "";
      this.selectedCreativesChannelType = newVal;
      this.getCreativesData();
    },
    selectedAffilatedForFilter(newVal, oldVal) {
      this.selectedAffilatedForFilter = newVal;
      this.fetchAffilatesComeData();
    },
    selectedAffilatedChannelForFilter(newVal, oldVal) {
      this.selectedAffilatedChannelForFilter = newVal;
      this.fetchAffilatesComeData();
    },
    selectedZoneTypeForFilter(newVal, oldVal) {
      this.selectedZoneTypeForFilter = newVal;
      this.zoneListChange = [];
      this.zoneWiseData = [];
      this.fetchZonewiseData();
    },
    zoneListChange(newVal, oldVal) {
      this.zoneListChange = newVal;
      this.fetchZonewiseData();
    },
    productProjectNamesChange(newVal, oldVal) {
      this.productProjectNamesChange = newVal;
      this.fetchProjectProductWiseData();
    },
    selectedSourceType(newVal, oldVal) {
      this.selectedSourceType = "";
      this.selectedSourceType = newVal;
      this.fetchZonewiseData();
    },
    selectedProductSourceType(newVal, oldVal) {
      this.selectedProductSourceType = "";
      this.selectedProductSourceType = newVal;
      this.fetchProjectProductWiseData();
    },
    selectedTrendsSourceType(newVal, oldVal) {
      this.selectedTrendsSourceType = "";
      this.selectedTrendsSourceType = newVal;
      // this.getTrendsTableData()
      // this.getQualityVolumeTableData();
      // this.getProductWiseForLaadsTableData();
      this.getTrendsData();
    },
    selectedProductType(newVal, oldVal) {
      this.selectedProductType = "";
      this.selectedProductType = newVal;
      this.fetchZonewiseData();
    },
    subProductOptions(newVal, oldVal) {
      this.subProductOptions = "";
      this.subProductOptions = newVal;
      this.fetchZonewiseData();
    },
  },
  mounted() {
    this.isNewUser = Cookies.get(useJwt.jwtConfig.userNewUser);
    this.isExternalUser = Cookies.get(useJwt.jwtConfig.userExternalUser);
    this.tabMultiChannelName = "Overview";
    this.getClient();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.MultiChannelConnected {
  .mainpage-ui-alignment-fixes{
    margin-left: -7px;
    margin-right: -5px;
  }
  .vs__selected_disabled_state {
    color: #989898 !important;
    background-color: #efefef !important;
  }

  .infoIconLive {
    cursor: pointer;
    margin-left: 0px !important;
  }

  .custom-tooltip {
    text-align: left;
    width: 500px;
    font-size: 11.5px;
    padding: 0px;
  }

  .multichanneldashbpa {
    .nav-tabs .nav-link {
      border: none;
      padding: 4px !important;
      border-radius: 0;
      font-size: 14px;
    }
  }

  .multi-range-select {
    margin-bottom: 0px !important;
  }

  .checkbox-weekly {
    width: 14px !important;
    height: 14px !important;
  }

  .text-red {
    color: #b4b4b8;
    font-weight: bold;
  }

  .blink-soft {
    animation: blinker 1.2s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  // Date selector UI break fixes.
  .multiDateSelector {

    .daterangepicker .calendar-table td,
    .daterangepicker .calendar-table th {
      white-space: nowrap;
      vertical-align: middle;
      min-width: 44px !important;
      width: 41px;
      height: 24px;
      line-height: 24px;
      font-size: 12px;
    }

    .daterangepicker .monthselect {
      margin-right: 0.2rem;
      margin-left: -1.2rem;
    }

    .daterangepicker.show-ranges.show-weeknumbers[data-v-1ebd09d2],
    .daterangepicker.show-ranges[data-v-1ebd09d2] {
      min-width: 840px !important;
    }

    .daterangepicker.ltr .drp-calendar.left {
      clear: left !important;
      margin-right: 15% !important;
    }
  }

  .multiHeaderMulti {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

    .card-body {
      padding: 1rem !important;
    }
  }

  .multiHeaderMultihide {
    display: none;
  }

  .screenAlignmentMultiChannel {
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    .card-body {
      padding: 0.5rem !important;
    }
  }

  .animated-message-text {
    width: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
    margin: 0;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  .animated-message-text span {
    position: absolute;
    width: 90%;
    font-size: 12px;
    font-weight: 600;
    color: transparent;
    background-image: linear-gradient(to right,
        #553c9a,
        #ee4b2b,
        #00c2cb,
        #ff7f50,
        #553c9a);
    -webkit-background-clip: text;
    background-clip: text;
    background-size: 200%;
    background-position: -200%;
    animation: animated-gradient 2s infinite alternate-reverse;
  }

  @keyframes animated-gradient {
    to {
      background-position: 200%;
    }
  }

  .transaction-table {
    overflow-x: auto;
  }

  .custom-spends-table th,
  .custom-spends-table td {
    font-size: 12px;
  }

  #datepicker-sm-spends,
  #datepicker-sm-tracking {
    height: 36px !important;
    /* Adjust the height as needed */
  }

  .b-form-btn-label-control.form-control>label {
    padding-left: 0.25rem !important;
    margin-top: 5px !important;
  }

  .transaction-table {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    overflow: auto;

    td {
      padding: 0.5rem 1.5rem !important;
    }

    .custom-table {
      border: none;
    }

    .action-buttons {
      display: flex;
      gap: 10px;
    }

    .action-icon {
      cursor: pointer;
      color: #007bff;
      /* Blue color for icons */
    }

    .action-icon:hover {
      color: #0056b3;
      /* Darker blue color on hover */
    }
  }

  .spendsTrackerContainer {
    .card-body {
      padding: 0.4rem !important;
    }
  }

  .custom-report-select:disabled {
    background-color: #f8f9fa;
    /* Light gray background */
    color: #6c757d;
    /* Gray text color */
    cursor: not-allowed;
    /* Change cursor to indicate not interactive */
  }

  .spinnerCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    /* Set the height of the container */
  }

  .dotLive {
    margin-left: 5px;
    margin-right: 5px;
  }

  .blink_text {
    color: black;
    animation-name: blinker;
    animation-duration: 2.5s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  .disabledRanges {
    pointer-events: none;
    opacity: 0.6;
    background-color: #f5f5f5;
    cursor: not-allowed;
  }

  .disabledRanges .picker-controller {
    pointer-events: none;
  }

  .disabledRanges .mr-1 {
    pointer-events: none;
  }

  @keyframes blinker {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .todayText {
    margin-top: 35px !important;
    margin-left: -81%;
    margin-bottom: 20px;
    font-size: 12px;
    text-align: left !important;
    align-items: left;
    font-weight: bold;
  }

  .todayCardContainer {
    .card-body {
      padding: 0.2rem !important;
    }
  }

  .multichannelCardfilter {
    .card-body {
      padding: 1rem !important;
    }

    .col-3 {
      padding-right: 3px;
    }

    .custom-select {
      padding: 0.438rem 2rem 0.438rem 1rem;
      font-size: 12px;
    }
  }

  .avatar-section:disabled {
    background-color: #424141 !important;
    color: #b8c2cc !important;
    cursor: not-allowed !important;
    opacity: 0.4 !important;
  }

  .file-earmark-spreadsheet {
    color: white;
    margin-right: 10px;
  }

  .not-allowed {
    cursor: not-allowed !important;
  }

  .multi-dashboard-overlay {
    .rounded-sm {
      inset: -20px !important;
      height: auto !important;
    }
  }

  .apexcharts-svg {
    height: 100% !important;
    width: 100% !important;
    margin: 0.5rem;
  }

  .donutChartContainer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
  }

  .chartContainer {
    height: 100%;
  }

  .chartContainer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .benchmarkPie {
    flex: 1;
  }

  .apexcharts-canvas {
    height: 100% !important;
  }

  .mainPieChartContainer {
    .card-body {
      padding: 1.3rem 0px !important;
    }
  }

  .cardContainer {
    padding: 2px;
    height: 140px;

    .card-body {
      padding: 1.2rem;
    }
  }

  .cardItemContainer {
    /* padding: 10px; */
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .dateContainer {
    padding: 6px !important;
  }

  .vue-daterange-picker {
    width: 100%;

    svg {
      color: #4d21ff;
    }
  }

  .picker-controller {
    background-color: #f5f6fa;
    padding: 8px;
    /* margin-top: 6px; */
  }

  .allChannelContainer {
    justify-content: center;
    align-items: center;
    height: 60px;
  }

  .projectOverviewTable {
    width: 100%;
    overflow-x: auto;
    margin-top: 20px;

    table {
      width: 100%;
      border-collapse: collapse;
      border: 1px solid rgb(215, 215, 215);
    }

    th,
    td {
      color: black;
      padding: 8px;
      text-align: center;
      border: 0.5px solid #e7e7e7;
      font-size: 12px;
    }

    th {
      font-size: 13px;
      color: black;
      text-align: center;
      font-weight: bold;
      border: none;
      color: #868585;
      font-weight: bold;
      background-color: #ffffff !important;
    }
  }

  .client-selections {
    border-right: 10px solid transparent;
    outline: 1px solid #d8d6de;
    width: 100% !important;
    height: 35px;
    background-color: white;
    border: 0px;
    border-radius: 5px;
    padding-left: 8px;
    color: rgb(163, 153, 153);
  }

  @media only screen and (max-width: 700px) {
    .sync-btn {
      align-items: center !important;
    }

    .sendTrackerParent {
      padding-top: 1rem;
    }

    .iconGroupContainer {
      flex-wrap: wrap;
      padding-top: 1.5rem;
    }

    .donutChart {
      height: 11rem;
    }

    .filterContainer-Box {
      width: 160vw;
      overflow: hidden;
    }

    .apexcharts-svg {
      margin: 0rem;
    }

    .piechartAndSpendsContainer {
      display: grid;
      grid-template-columns: auto;
      align-items: center;
      justify-content: center;
    }

    .donutChartContainer {
      display: grid;
      grid-template-columns: auto;
      grid-gap: 20px;
      // justify-content: space-between;
      width: 80%;
      justify-content: center;
      align-items: center;

      .benchmarkPie {
        width: 300px !important;
        flex: 1;
      }
    }

    .filterContainer {
      display: grid;
      grid-template-columns: auto;
      width: 100% !important;
    }

    [dir="ltr"] .row {
      margin: 0px;
    }

    .filterCardIcons {
      .card-body {
        padding: 0.2rem !important;
      }
    }

    .filterCardSelect {
      width: 250px;
      margin-left: auto;
      margin-right: auto;
    }

    .filterCard {
      width: 100%;
    }

    .centered-form {
      display: grid;
      grid-template-columns: auto;
      width: 90%;
      margin-left: auto;
      margin-right: auto;
      height: auto;
    }

    .allChannelContainer {
      display: grid;
      grid-template-columns: auto;
      width: 500px !important;
      margin-left: auto;
      margin-right: auto;
      height: auto;
    }

    .headerContainerItem {
      margin-top: 50px !important;

      width: 100% !important;
      height: auto;
    }

    .mainContainer {
      display: grid;
      grid-template-columns: auto;
      width: 100%;
      height: auto;
    }

    .cardContainer {
      margin-top: 50px;
      padding: 2px;
      height: 140px;
      width: 300px !important;
      margin-left: auto;
      margin-right: auto;

      .card-body {
        padding: 1.2rem;
      }
    }

    .form-group {
      margin-bottom: 0rem !important;
    }

    .yesterdayChannelSheetData {
      width: 100% !important;
    }

  }

  @media only screen and (min-width: 700px) and (max-width: 1024px) {


    .yesterdayChannelSheetData {
      width: 100% !important;
    }

    .addSpendsFormContainer {
      grid-template-columns: auto;
      grid-gap: -20px !important;

      .w-100 {
        width: 710% !important;
      }

      .mb-1 {
        margin-bottom: 5px !important;
      }

      .sendTrackerParent {
        padding: 0px !important;
        padding-top: 1rem !important;
      }

      .formdiv {
        .form-control {
          padding: 0.438rem 1rem;
          background-color: #fff;
          background-clip: padding-box;
        }
      }
    }

    .mb-1 {
      margin-bottom: 5px !important;
    }

    .form-group {
      margin-bottom: 0rem !important;
    }

    .dotLive {
      margin-left: 20px !important;
    }

    .allChannelContainer {
      .picker-controller {
        background-color: #f5f6fa;
        font-size: 11px;
        /* padding: 8px; */
      }
    }

    .filterCardIcons {
      .card-body {
        padding: 0.2rem !important;
      }

      .iconGroupContainer {
        margin-top: 25px !important;
        flex-wrap: wrap;
      }

      .b-avatar .b-avatar-img img {
        width: 50%;
        height: 50%;
        max-height: auto;
        object-fit: cover;
      }
    }

    .piechartAndSpendsContainer {
      display: grid;
      grid-template-columns: auto;
    }

    .spendsTrackerContainer {
      padding-top: 1rem;
    }
  }

  @media only screen and (min-width: 1025px) and (max-width: 1401px) {

    .yesterdayChannelSheetData {
      width: 100% !important;
    }

    .dotLive {
      margin-left: 20px !important;
      margin-top: -10px !important;
    }

    // .blink_text{
    //   margin-left: 10px !important;
    // }
    .mainPieChartCardContainer {
      height: 100%;

      .card-body {
        height: 100%;
        padding: 0.2rem !important;
      }
    }

    .donutChartContainer {
      padding-top: 4vh;
      flex: 3;
    }

    .donutChartlable {
      flex: 1;
    }

    .spendsCardTrackerContainer {
      margin-top: 2vh;

      span {
        font-size: 11px !important;
      }
    }

    .benchmarkPie {
      height: 60px !important;
    }

    //   display: grid;
    //   grid-template-columns: auto;
    // }
    // .spendsTrackerContainer{
    //   width: 308%;
    // }
    // .mainPieChartContainer{
    //   width: 150%;
    // }
  }

  .validation {
    color: red;
    margin-left: 2px;
  }

  .disabled-button-data-sync {
    cursor: not-allowed !important;
    opacity: 0.6;
  }

  .tracker-btn-container {
    padding-top: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .spend-modal-btn {
    padding: 0.6rem 2rem !important;
    border-radius: 0.358rem;
    font-size: 1rem !important;
  }

  .campaigns-select-disable-state {
    .vs__selected-options {
      overflow-y: auto !important;

      .vs__selected {
        color: #989898 !important;
      }
    }
  }

  .campaigns-select {
    .vs__selected-options {
      overflow-y: auto !important;
    }
  }

  .track-radio-label input[type="radio"] {
    // transform: scale(0.6);
    margin: 0;
    padding: 0;
    width: 25px !important;
  }

  .track-radio-label {
    display: flex;
    align-items: center;
  }

  .spendTracker_campaign_options {
    .vs--multiple .vs__selected-options {
      overflow: auto !important;
    }
  }

  .track-client-radio {
    margin-left: -10px !important;
  }

  .spends-readio-lable-input {
    height: 30px !important;
    width: 15px !important;
  }

  .MultiChannelConnected .vs__selected-options input {
    height: 27px !important;
  }

  .multiChannelSpendSheet {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    flex-direction: column;
    border-radius: 0.428rem;
  }

  .yesterdayChannelSheetData {
    background-color: white;
    border-radius: 0.428rem;
    overflow: auto;
    padding: 1rem;
    padding-top: 0rem !important
  }

  .ChannelSheetDataCustomized {
    background-color: white;
    border-radius: 0.428rem;
    overflow: auto;
    width: 100%;
    padding-top: 0px !important
  }

  .multiChannelSpendSheet .border {
    color: black;
  }

  .performance-comparison table {
    width: 100%;
    text-align: center;
  }

  .yesterdayChannelSheetData h4 {
    color: #200e6b;
  }

  .yesterdayChannelSheetData .card .card-contant {
    padding: 2px;
  }

  .yesterdayChannelSheetData .card {
    margin-bottom: 10px !important;
  }

  .headerPerformanceComparison {
    color: #200e6b;
    font-weight: bold;
  }

  .yesterdayChannelSheetData .card-contant .card-Name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-Name .avatar-section {
    flex: 1;
  }

  .banchmarksights {
    background-color: #fbf1d4;
    border-radius: 0.428rem;
    padding: 1rem;
    padding-top: 3px !important;
    padding-bottom: 0rem !important;
    margin-bottom: 10px;
  }

  .spendTableBody {
    color: #585858;
  }

  .date-range {
    font-size: 10px;
    font-weight: 500;
  }

  .multiChannelSpendCustomizedSheetContainer {
    border-radius: 0.428rem;
    background-color: white;
  }

  .customeDataHeaders {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    padding-top: 0px !important;
    align-items: flex-start
  }

  .customeDataHeaders .customDateRangeSelector {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .customeDataHeaders .customDateRangeSelector .dateRanges {
    display: flex;
    gap: 1rem;
  }

  .picker1 .daterangepicker {
    left: -29rem !important;
  }

  .picker1 .daterangepicker:before,
  .picker1 .daterangepicker:after {
    left: 36rem !important;
  }

  .picker2 .daterangepicker {
    left: -39rem !important;
  }

  .picker2 .daterangepicker:before,
  .picker2 .daterangepicker:after {
    left: 43rem !important;
  }

  .app-content content {
    overflow-x: hidden;
  }

  .vertical-layout {
    overflow-x: hidden;
  }

  .date-range-options{
  display: flex;
    justify-content: flex-end;
    padding: 10px;
    margin-top:5px;
    padding-bottom: 0px !important;
    gap:15px

}
.date-range-options label{
    display: flex;
    align-items: center;
    gap: 3px;
}
.transaction-table{
  overflow: auto !important;
}
.transaction-table td{
  padding: 0px !important;

}
}
</style>
