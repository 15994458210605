<template>
  <div>
    <div style="display: flex; justify-content: space-between;">
      <div>
        <b-card-title>
          <h3 class="app-title mdsm">Campaign-wise Footfall Insights</h3>
        </b-card-title>
      </div>
      <div style="display: flex; justify-content: space-between; gap:10px ">
        <b-form-group style="margin-top: 0px;">
          <label>Platform:</label>
          <b-form-select
            v-model="selectedPlatform"
            :options="platformOptions"
          ></b-form-select>
        </b-form-group>
        <b-form-group style="margin-top: 0px;">
          <label>Month:</label>
          <b-form-select
            v-model="selectedMonth"
            :options="monthOptions"
          ></b-form-select>
        </b-form-group>
        <b-button
         
          style="height: 40px; margin-top:22px"
          @click="openViewSheet"
          size="sm"
          variant="outline-primary"
        >
          <img
            src="@/assets/images/logo/sheets.png"
            alt="Icon"
            style="width: 22px; height: 22px; margin-right: 5px;"
          /><strong>
            <span v-if="this.sheetUrl.length === 0">Connect</span>
            <span v-else>Update</span> Your Google Sheet</strong
          ></b-button
        >
      </div>
    </div>
    <div>
      <div v-if="isFootFallDataFetching" class="spinnerCentered">
        <multiChannelSpinner />
      </div>
      <div
        v-else-if="!footFallData || Object.keys(footFallData).length === 0"
        class="spinnerCentered"
      >
        No Footfall Data Found!
      </div>
      <div v-else>
        <vue-good-table
          class="customize-table"
          :columns="columns"
          :rows="filteredData"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
        >
        <template #emptystate>
            <div class="text-center p-4">
                No data found for the selected month.
            </div>
          </template>
          <template slot="table-row" slot-scope="props">
            <span style="font-size: 12.5px;">
              {{ props.formattedRow[props.column.field] }}</span
            >
          </template>
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1"></div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  v-model="paginatedDataNumber1"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
    <transition name="modal">
      <b-modal
        id="modal-center"
        hide-header
        hide-footer
        centered
        size="xs"
        v-model="submitSheetModal"
      >
        <b-card-text>
          <div
            style="display: flex; justify-content:space-between; padding:2px 0px"
          >
            <div>
              <h6>
                <img
                  src="@/assets/images/logo/sheets.png"
                  alt="Icon"
                  style="width: 17px; height: 17px; margin-right: 2px;"
                />Connect Your Google Sheet
              </h6>
            </div>
            <div style="float: right">
              <b-button
                variant="flat-primary"
                @click="closeViewSheet"
                style="margin-top: -10px; margin-right: -18px"
                ><feather-icon icon="XIcon"></feather-icon
              ></b-button>
            </div>
          </div>
        </b-card-text>
        <div>
          <b-row v-if="this.sheetUrl.length > 0" style="padding: 0 10px">
            <div v-if="this.sheetUrl.length > 0"
              style="width: 100%; background-color: #f0f0f0; padding: 10px; border-radius: 5px; text-align: left; word-wrap: break-word; overflow-wrap: break-word; font-size:11px; margin-bottom: 10px;">
              <strong style="text-align: left !important;">Current Sheet :</strong>
              <a target="_blank" rel="noopener noreferrer" :href="this.sheetUrl"> {{ this.sheetUrl }}</a>
            </div>
          </b-row>
          <b-row style="padding: 0 10px">
            <b-col cols="12">
              <label><span v-if="this.sheetUrl.length > 0">New </span>Sheet Url</label>
              <b-form-input v-model="sheetUrlSubmit"> </b-form-input>
            </b-col>
          </b-row>
        </div>
        <div
          style="display: flex; justify-content:center; gap:10px"
          class="mt-2"
        >
          <b-button
            variant="outline-primary"
            class="custom-primary-with-border"
            @click="closeViewSheet"
            size="sm"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            @click="submitSheet"
            :disabled="this.sheetUrlSubmit.length === 0"
            size="sm"
          >
            Proceed <b-spinner v-if="isSheetUrlSubmit" small />
          </b-button>
        </div>
      </b-modal>
    </transition>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BButton,
  BModal,
  BFormInput,
  BFormSelect,
  BPagination,
  BFormGroup,
  BCardTitle,
  BSpinner,
} from "bootstrap-vue";
import {
  sortFuntionNumericVal,
  sortFuntionStringintoNumericVal,
} from "@/components/client/SortingFunction";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import Spinner from "@/components/client/Spinner.vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import { VueGoodTable } from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  data() {
    return {
      footFallData: null,
      isFootFallDataFetching: false,
      paginatedDataNumber1: 1,
      selectedMonth: null,
      pageLength: 10,
      selectedPlatform: null,
      isMTDFetched: true,
      sheetUrlSubmit: "",
      isSheetUrlSubmit: false,
      submitSheetModal: false,
      columns: [
        {
          label: "Campaign Name",
          field: "campaignName",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: false,
        },
        {
          label: "Platform",
          field: "Platform",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: false,
        },
        {
          label: "Spends",
          field: "Spends",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: true,
          sortFn: this.sortStringtoNumFn,
        },
        {
          label: "CPL",
          field: "CPL",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: true,
          sortFn: this.sortStringtoNumFn,
        },
        {
          label: "Leads",
          field: "Leads",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: true,
          sortFn: this.sortStringtoNumFn,
        },
        {
          label: "QL",
          field: "QL",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: true,
          sortFn: this.sortStringtoNumFn,
        },
        {
          label: "QL%",
          field: "QL%",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: true,
          sortFn: this.sortStringtoNumFn,
        },
        {
          label: "CPQL%",
          field: "CPQL",
          tdClass: "text-center custom-text",
          thClass: "text-center custom-text-th",
          sortable: true,
          sortFn: this.sortStringtoNumFn,
        },
      ],
    };
  },
  props: {
    clientId: {
      type: String,
    },
    sheetUrl: {
      type: String,
    },
    userType: {
      type: String,
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BModal,
    BFormInput,
    ToastificationContent,
    BButton,
    VueGoodTable,
    multiChannelSpinner,
    BFormSelect,
    BPagination,
    BFormGroup,
    BCardTitle,
    BSpinner,
    BCardText,
  },
  computed: {
    monthOptions() {
      if (!this.footFallData || Object.keys(this.footFallData).length === 0)
        return [{ value: null, text: "All" }];
      return [
        { value: null, text: "All" },
        ...Object.keys(this.footFallData || {}).map((month) => ({
          value: month,
          text: month,
        })),
      ];
    },
    platformOptions() {
      if (!this.footFallData || Object.keys(this.footFallData).length === 0)
        return [{ value: null, text: "All" }];
      const platforms = new Set();
      Object.values(this.footFallData || {}).forEach((monthData) => {
        Object.values(monthData || {})
          .flat()
          .forEach((campaign) => {
            if (campaign?.Platform) platforms.add(campaign.Platform);
          });
      });
      return [
        { value: null, text: "All" },
        ...Array.from(platforms).map((platform) => ({
          value: platform,
          text: platform,
        })),
      ];
    },
    filteredData() {
      if (!this.footFallData || Object.keys(this.footFallData).length === 0)
        return [];
      let data = Object.entries(this.footFallData || {}).flatMap(
        ([month, platforms]) =>
          Object.values(platforms || {})
            .flat()
            .map((campaign) => ({ ...campaign, month }))
      );

      if (this.selectedMonth) {
        data = data.filter((campaign) => campaign.month === this.selectedMonth);
      }
      if (this.selectedPlatform) {
        data = data.filter(
          (campaign) => campaign.Platform === this.selectedPlatform
        );
      }
      return data;
    },
  },
  methods: {
    sortFn(a, b) {
      return sortFuntionNumericVal(a, b);
    },
    sortStringtoNumFn(a, b) {
      return sortFuntionStringintoNumericVal(a, b);
    },
    footFallDataFetching() {
      this.isFootFallDataFetching = true;
      this.footFallData = [];
      axios
        .get(
          `${process.env.VUE_APP_SERVER_URL}/api/channel/campaign-wise-footfall-data?clientID=${this.clientId}&gSheetUrl=${this.sheetUrl}`,
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.footFallData = response && response.data;
          this.isFootFallDataFetching = false;
        })
        .catch((error) => {
          this.footFallData = [];
          this.isFootFallDataFetching = false;
          this.showToast("Error", "Unknown error occured.", "danger");
        });
    },
    openViewSheet() {
      this.submitSheetModal = true;
    },
    closeViewSheet() {
      this.submitSheetModal = false;
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    submitSheet() {
      this.isSheetUrlSubmit = true;
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/client/submit_google_sheet_link`,
          { clientID: this.clientId, gsheet: this.sheetUrlSubmit },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {
          this.showToast(
            "Submited",
            "Google Sheet submitted successfully!",
            "success"
          );
          this.isSheetUrlSubmit = false;
          this.submitSheetModal = false;
          this.getClient();
        })
        .catch((error) => {
          this.isSheetUrlSubmit = false;
          this.submitSheetModal = false;
          console.log(error.response);
        });
    },
  },
  mounted() {
    this.footFallDataFetching();
  },
};
</script>

<style>
.custom-text {
  font-size: 12px !important;
  color: #1a1a1d;
}

.custom-text-th {
  font-size: 13px !important;
  font-weight: 600 !important;
  padding: 30px !important;
  color: black;
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 20px;
}
</style>
