<template>
  <div class="banchingCardCamparison">
    <div v-if="!spinner_comparisonData && !comparisonCustomSpinner">
      <div
        class="customeDataHeaders"
        v-if="comparisonCustomApiData.comparison_type === 'custom'"
      >
        <h4>
          Comparing Insights <br />
          <span style="font-weight: 600; font-size: 11px;">
            ({{ comparisonCustomApiData.date_range.current_period.start }} -
            {{ comparisonCustomApiData.date_range.current_period.end }}) Vs ({{
              comparisonCustomApiData.date_range.previous_period.start
            }}
            - {{ comparisonCustomApiData.date_range.previous_period.end }})
          </span>
        </h4>
        <div class="customDateRangeSelector">
          <label for="monthSelect" class="form-label">
            Select Dates Range:
          </label>
          <div class="dateRanges">
            <div>
              <date-range-picker
                class="custom-date-picker"
                ref="picker1"
                opens="left"
                :locale-data="{ firstDay: 1, format: 'yyyy-dd-mm' }"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="true"
                v-model="dateRange1"
                 @update="updateRangeValues('dateRange1', $event)"
                :dateFormat="dateFormat"
                control-container-class="picker-controller small"
                :ranges="dateRanges"
                :auto-apply="false"
                :readonly="false"
                :max-date="calculateMaxDate()"
               
              >
                <template v-slot:input="picker">
                  <feather-icon
                    icon="CalendarIcon"
                    size="16"
                    class="mr-1"
                  ></feather-icon>
                  <span class="ranges">
                    {{ formatDateDisplay(picker.startDate) }} -
                    {{ formatDateDisplay(picker.endDate) }}
                  </span>
                </template>
              </date-range-picker>
            </div>
            <div>
              <date-range-picker
                class="custom-date-picker"
                ref="picker2"
                opens="left"
                :locale-data="{ firstDay: 1, format: 'yyyy-dd-mm' }"
                :showWeekNumbers="false"
                :showDropdowns="true"
                :autoApply="true"
                v-model="dateRange2"
                @update="updateRangeValues('dateRange2', $event)"
                :dateFormat="dateFormat"
                control-container-class="picker-controller small"
                :ranges="dateRanges"
                :auto-apply="false"
                :readonly="false"
                :min-date="calculateMinDate()"
                :max-date="calculateMaxDate()"
              >
                <template v-slot:input="picker">
                  <feather-icon
                    icon="CalendarIcon"
                    size="16"
                    class="mr-1"
                  ></feather-icon>
                  <span class="ranges">
                    {{ formatDateDisplay(picker.startDate) }} -
                    {{ formatDateDisplay(picker.endDate) }}
                  </span>
                </template>
              </date-range-picker>
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="comparisonCustomApiData.comparison_type === 'daily'">
        <h4>Yesterday's Performance</h4>
      </div>
      <div v-else-if="comparisonCustomApiData.comparison_type === 'weekend'">
        <h4>Weekend Performance</h4>
      </div>

      <div class="banchmarksights">
        <p class="highlight-text">
          {{
            comparisonCustomApiData && comparisonCustomApiData.insights
              ? comparisonCustomApiData.insights.highlight
              : "No Highlight"
          }}
        </p>
        <p>
          <img
            v-if="comparisonCustomApiData"
            src="@/assets/images/icons/bulb.png"
            alt="Icon"
            class="bulb-icon"
          />
          {{
            comparisonCustomApiData && comparisonCustomApiData.insights
              ? comparisonCustomApiData.insights.suggestion
              : "No Suggestion"
          }}
        </p>
      </div>

      <div class="performance-container">
        <div
          v-for="(data, channel) in comparisonCustomApiData.metrics"
          :key="channel"
          class="card"
        >
          <div  class="card-content">
            <table>
              <thead>
                <tr>
                  <th>
                    <div
                      class="card-Name"
                      style="display: flex; align-items: center; justify-content: center;"
                    >
                      <b-avatar
                        v-if="channel !== 'overall' && channel === 'meta'"
                        square
                        variant="white"
                        size="35"
                        src="@/assets/images/logo/meta.png"
                      ></b-avatar>
                      <b-avatar
                        v-if="channel !== 'overall' && channel === 'google'"
                        square
                        variant="white"
                        size="35"
                        src="@/assets/images/logo/ads-logo.png"
                      ></b-avatar>
                      <b-card-text
                        class="app-title sm"
                        style="margin-left: 5px;"
                      >
                        {{ channel === "overall" ? "Overall" : "" }}
                      </b-card-text>
                    </div>
                  </th>
                  <th class="dateHeader">Date Range 1 <br> <span class="date-range" v-if="comparisonCustomApiData.comparison_type != 'daily'">({{
                                    comparisonCustomApiData.date_range
                                    .current_period.start
                                    }}
                                    -
                                    {{
                                    comparisonCustomApiData.date_range
                                    .current_period.end
                                    }})</span>
                                  <span class="date-range" v-else>{{
                                    comparisonCustomApiData.date_range
                                    .current_period.start
                                    }}</span>  
                                  </th>
                  <th class="dateHeader">Date Range 2<br> <span class="date-range" v-if="comparisonCustomApiData.comparison_type != 'daily'">({{
                                    comparisonCustomApiData.date_range
                                    .previous_period.start
                                    }}
                                    -
                                    {{
                                    comparisonCustomApiData.date_range
                                    .previous_period.end
                                    }})</span>
                                  <span class="date-range" v-else>{{
                                    comparisonCustomApiData.date_range
                                    .previous_period.start
                                    }}</span> </th>
                  <th>Delta</th>
                </tr>
              </thead>
              <tbody>
                <tr
  v-for="metric in ['spends', 'clicks', 'leads','cpc', 'cpm', 'ctr', 'cvr']"
  :key="metric"
  v-if="data[metric]"
>
  <td class="matricDataName">
    {{ ['cpc', 'cpm', 'ctr', 'cvr'].includes(metric)
      ? metric.toUpperCase() 
      : metric.charAt(0).toUpperCase() + metric.slice(1) }}
  </td>
  <td>{{ data[metric].current }}</td>
  <td>{{ data[metric].previous }}</td>
  <td :style="{ color: getDeltaColor(metric, data[metric].change) }">
    <feather-icon
      :icon="
        data[metric].change[0] === '-' ? 'ArrowDownIcon' : 'ArrowUpIcon'
      "
      size="10"
    ></feather-icon>
    {{ data[metric].change.slice(1) }}
  </td>
</tr>

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="spinnerCentered">
      <multiChannelSpinner class="mt-1" />
    </div>
  </div>
</template>

<script>
import Spinner from "@/components/client/Spinner.vue";
import { BAvatar, BCardText } from "bootstrap-vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import moment from "moment";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css"
export default {
  data() {
    return {
      selectorDirections: {},
      dateRange1: {
        startDate: moment()
          .subtract(14, "days")
          .format("YYYY-MM-DD"),
        endDate: moment()
          .subtract(8, "days")
          .format("YYYY-MM-DD"),
      },
      dateRange2: {
        startDate: moment()
          .subtract(7, "days")
          .format("YYYY-MM-DD"),
        endDate: moment()
          .subtract(1, "days")
          .format("YYYY-MM-DD"),
      },
    };
  },
  components: {
    Spinner,
    multiChannelSpinner,
    BAvatar,
    BCardText,
    DateRangePicker,
  },
  props: {
    comparisonCustomApiData: Object,
    spinner_comparisonData: Boolean,
    comparisonCustomSpinner: Boolean,
    formatDateDisplay: Function,
  },
  computed: {
    dateRanges() {
      let today = new Date();
      today.setHours(0, 0, 0, 0);

      let yesterday = new Date();
      yesterday.setDate(today.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      let last7thDay = new Date();
      last7thDay.setDate(today.getDate() - 7);
      last7thDay.setHours(0, 0, 0, 0);

      let last14thDay = new Date();
      last14thDay.setDate(today.getDate() - 14);
      last14thDay.setHours(0, 0, 0, 0);

      let last30thDay = new Date();
      last30thDay.setDate(today.getDate() - 30);
      last30thDay.setHours(0, 0, 0, 0);

      var thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      var thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let ranges = {
        Today: [today, today],
        Yesterday: [yesterday, yesterday],
        "Last 7 Days": [last7thDay, yesterday],
        "Last 14 Days": [last14thDay, yesterday],
        "Last 30 Days": [last30thDay, yesterday],
        "Last Month": [
          new Date(today.getFullYear(), today.getMonth() - 1, 1),
          new Date(today.getFullYear(), today.getMonth(), 0),
        ],
        "This Month": [thisMonthStart, thisMonthEnd],
        "This Year": [new Date(today.getFullYear(), 0, 1), new Date(today)],
      };

      return ranges;
    },
  },
  methods: {
    formatDateDisplay(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    updateRangeValues(rangeName, values) {
      if (values && values.startDate && values.endDate) {
        let formattedStart = moment(values.startDate).format("YYYY-MM-DD");
        let formattedEnd = moment(values.endDate).format("YYYY-MM-DD");

        // Emit event to parent
        this.$emit("dateRangeUpdated", rangeName, formattedStart, formattedEnd);
      }
    },
    getDeltaColor(metric, change) {
      return (change.startsWith("+") && metric !== "spend") ||
        (change.startsWith("-") && metric === "spend")
        ? "green"
        : "red";
    },
    calculateMinDate() {
      // console.log(this.dateRange1);
      const minDate = new Date(this.dateRange1.endDate);
      console.log(minDate);
      minDate.setDate(minDate.getDate() + 1);

      return minDate;
    },
    calculateMaxDate() {
      const maxDate = new Date();
      maxDate.setHours(23, 59, 59, 999);
      return maxDate;
    },
    dateFormat(classes, date) {
      let yesterday = new Date();

      let d1 = moment(date).format("YYYY-MM-DD");
      let d2 = moment(yesterday.setDate(yesterday.getDate() - 1)).format(
        "YYYY-MM-DD"
      );
      return classes;
    },
  },
};
</script>

<style lang="scss">

.banchingCardCamparison {
  padding-left: 1rem;
  padding-right: 1rem;
}
.banchingCardCamparison {
  .bulb-icon {
    width: 25px;
    height: 25px;
    margin-right: 5px;
  }

  .customeDataHeaders {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    padding-top: 0px !important;
    align-items: flex-start;
  }

  .customeDataHeaders .customDateRangeSelector {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  .customeDataHeaders .customDateRangeSelector .dateRanges {
    display: flex;
    gap: 1rem;
  }

  .banchmarksights {
    background-color: #fbf1d4;
    border-radius: 0.428rem;
    padding: 1rem;
    padding-top: 3px !important;
    padding-bottom: 0rem !important;
    margin-bottom: 10px;
  }

  .highlight-text {
    text-align: center;
    font-weight: 900;
  }

  .performance-container table {
    width: 100%;
  }
  .card-content{
    overflow: scroll;
  }
  .performance-container .card-content th,
  .performance-container .card-content .matricDataName {
    color: #200e6b;
    font-weight: bold;

  }

  .banchingCardCamparison h4,
  .banchingCardCamparison p {
    color: #200e6b;
  }
  .card-Name {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .card-Name .avatar-section {
    flex: 1;
  }
  .picker2 .daterangepicker {
    left: -39rem !important;
  }

  .picker2 .daterangepicker:before,
  .picker2 .daterangepicker:after {
    left: 43rem !important;
  }
  .card-content th,
  .card-content td {
    // text-align: center;
    padding-left: 5rem;
  }
  .banchmarksights > p {
    margin-bottom: 0.5rem;
  }
  .daterangepicker td.active{
    background-color: rgb(77, 33, 255) !important;
  }
  .dateHeader{
    padding-top: 18px !important;
  }
}
</style>
