let productMap = {
    "productMapOutcome": {
        "default": {
                "campaignName": "Campaign Name",
                "campaignType": "Campaign Type",
                "adsetName": "Adset Name",
                "creativeName": "Creative Name",
                "channel": "Channel",
                "leadName":"Lead Name",
                "leadEmail":"Lead Email",
                "leadPhone":"Lead Phone",
                "platformName":"Platform",
                "projectName":"Project Name",
                "projectBudget":"Project Budget",
                "startDate": "Start Date",
                "endDate": "End Date",
                "cost": "Cost",
                "impr": "Impression",
                "lclicks": "L Clicks",
                "ctr": "CTR %",
                "cpm": "CPM",
                "fbLeads": "FB Leads",
                "crmLeads": "CRM Leads",
                "ql": "QL",
                "fbCpl": "FB CPL",
                "crmCpl": "CRM CPL",
                "cpql": "CPQL",
                "cpc": "CPC",
                "ql%": "QL %",
                "previousQl%": "Previous QL %",
                "c2l": "C2L",
                "notContactable": "Not Contactable",
                "callback": "Call Back",
                "openLeads": "Open Leads",
                "campaignDeliveryStatus": "Campaign Delivery Status",
                "adDelivery": "Ads Delivery"
        }
    }
  }
  export default productMap