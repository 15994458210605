<template>
  <div class="filter-campaigns-container">
    <b-row class="categorisedCampaignsInsightsTable">
      <b-col cols="12">
        <b-card >
          <div style=" padding-bottom: 0.5rem;">
            <div class="categorised-campaign-container">
              <h3 class="app-title mdsm">Categorised Campaigns Insights</h3>
              <span></span>
              <div class="categorised-filter-container"
                style="display: flex; gap: 1rem; flex: 1;justify-content: flex-end; align-items: center;right: 0px;"
              >
                <!-- select Category -->

                <div
                  class="dropdown_filter_main_container"
                  @mouseleave="showMainDropdown = false"
                >
                  <ul class="dropdown_filter_main_container_nav">
                    <li class="hassubs" @click="showMainDropdown = true">
                      <div class="selected_FilterData">
                        <div
                          v-if="Object.keys(selectedData).length"
                          class="selected-items"
                        >
                          <div style="display: flex; flex-direction: row">
                            <div v-if="ShowClearAll()" class="selected-box">
                              <p
                                style="margin: 0; padding-right: 5px; color: white;"
                              >
                                Clear All
                              </p>
                              <span
                                style="cursor: pointer; font-size: 10px; color: white;"
                                @click="deleteAllFilters"
                                ><svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="12"
                                  height="12"
                                  viewBox="0 0 24 24"
                                  fill="white"
                                >
                                  <path
                                    d="M18.36 5.64a1.5 1.5 0 0 0-2.12 0L12 9.88 7.76 5.64a1.5 1.5 0 1 0-2.12 2.12L9.88 12l-4.24 4.24a1.5 1.5 0 1 0 2.12 2.12L12 14.12l4.24 4.24a1.5 1.5 0 1 0 2.12-2.12L14.12 12l4.24-4.24a1.5 1.5 0 0 0 0-2.12z"
                                  /></svg
                              ></span>
                            </div>

                            <span
                              style="margin-right: 8px; display: flex;"
                              v-for="(items, category) in selectedData"
                              :key="category"
                            >
                              <span
                                style="background-color: #6C4E96; color: white; border: 1px solid #6C4E96; border-radius: 3px;display: flex; flex-direction: row; align-items: center; gap: 5px; margin-right: 8px; padding: 4px;"
                                v-for="(item, index) in items"
                                :key="index"
                                class="tag"
                              >
                                <p class="tag-text">
                                  {{ item }}
                                </p>

                                <!-- White Cross SVG Icon -->
                                <span
                                  class="tag"
                                  @click="removeFilter(category, item)"
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="12"
                                    height="12"
                                    viewBox="0 0 24 24"
                                    fill="white"
                                  >
                                    <path
                                      d="M18.36 5.64a1.5 1.5 0 0 0-2.12 0L12 9.88 7.76 5.64a1.5 1.5 0 1 0-2.12 2.12L9.88 12l-4.24 4.24a1.5 1.5 0 1 0 2.12 2.12L12 14.12l4.24 4.24a1.5 1.5 0 1 0 2.12-2.12L14.12 12l4.24-4.24a1.5 1.5 0 0 0 0-2.12z"
                                    />
                                  </svg>
                                </span>
                              </span>
                            </span>
                          </div>
                        </div>

                        <p class="filter-title-text" v-else>Apply your filters</p>
                      </div>

                      <ul
                        v-if="showMainDropdown"
                        class="dropdown_filter_main_dropdown"
                      >
                        <li
                          v-for="(values, key) in filterData"
                          :key="key"
                          class="subs hassubs"
                          @click="setHoveredCategory(key, values)"
                          @mouseleave="setHoveredCategory(null, '')"
                          :style="{
                            backgroundColor:
                              key in selectedData && selectedData[key].length
                                ? '#eeeeee'
                                : 'transparent',
                          }"
                        >
                          <div class="subscription-container key-box">
                            <div class="subscription-header">
                              <div>{{ key }}</div>
                              <span
                                v-if="
                                  selectedData[key] &&
                                    Object.keys(selectedData).length
                                "
                                class="check-icon"
                              >
                                <b-icon icon="check" />
                              </span>
                            </div>
                          </div>

                          <ul
                            v-if="hoveredCategory === key"
                            class="dropdown_filter_main_dropdown child"
                          >
                            <li
                              v-for="(item, index) in hoveredValues"
                              :key="index"
                              @click="ensureKeyExists(key, item)"
                              :style="{
                                backgroundColor:
                                  selectedData[key] &&
                                  selectedData[key].includes(item)
                                    ? '#eeeeee'
                                    : 'transparent',
                              }"
                            >
                              <div class="subscription-item key-box">
                                <div class="subscription-content">
                                  {{ item }}
                                  <span
                                    v-if="
                                      selectedData[key] &&
                                        selectedData[key].includes(item)
                                    "
                                    class="check-icon"
                                  >
                                    <b-icon icon="check" />
                                  </span>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>

                <!-- select channel  -->
                <b-form-select
                  style="width: 10rem;"
                  id="clientSelect"
                  v-model="TableChannel"
                >
                  <option
                    v-for="onetype in ChannelOption"
                    :value="onetype.value"
                    class="form-control"
                    id="lg_type"
                    name="lg_type"
                    :key="onetype.value"
                  >
                    {{ onetype.label }}
                  </option>
                </b-form-select>
              </div>
            </div>
              </div>
          <!-- Table -->
          <div v-if="isFilteredDataFetched" class="spinnerCentered">
            <multiChannelSpinner />
          </div>
          <div v-else>
            <vue-good-table
              :columns="filterDataColumns"
              :rows="filterOptimisationTabData"
              :pagination-options="{
                enabled: true,
                perPage: pageLength,
              }"
            >
              <!-- pagination -->
              <template slot="pagination-bottom" slot-scope="props">
                <div>
                  <div>
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (currentPage) =>
                          props.pageChanged({ currentPage: currentPage })
                      "
                    >
                      <template #prev-text>
                        <feather-icon icon="ChevronLeftIcon" size="18" />
                      </template>
                      <template #next-text>
                        <feather-icon icon="ChevronRightIcon" size="18" />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardTitle,
  BModal,
  BButton,
  BFormGroup,
  BCardText,
  BFormSelect,
  BFormInput,
  BTable,
  BPagination,
  BSpinner,
  BIcon,
} from "bootstrap-vue";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import { VueGoodTable } from "vue-good-table";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import axios from "axios";
import { getUserToken } from "@/auth/utils";
import { setHoverStyle } from "echarts/lib/util/graphic";
import {
  sortFuntionNumericVal,
  sortFuntionStringintoNumericVal,
} from "@/components/client/SortingFunction";
import { Tab } from "bootstrap";

export default {
  name: "FilterData",
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BModal,
    BButton,
    BFormGroup,
    vSelect,
    BCardText,
    BFormSelect,
    BFormInput,
    BTable,
    ToastificationContent,
    VueGoodTable,
    BSpinner,
    BPagination,
    multiChannelSpinner,
    BIcon,
  },
  data() {
    return {
      isFilteredDataFetched: false,
      pageLength: 10,
      currentPage: 1,
      addSpendsModal: false,
      selectedChannel: "",
      TableChannel: "overall",
      selectedCategory: "",
      selectedtags: [],
      isEditing: false,
      editingId: null,
      optionsDeleteModal: false,
      deletingId: null,
      deletingCategory: "",
      deletingChannel: "",
      ChannelOption: [
        { value: "overall", label: "Overall" },
        { value: "meta", label: "Meta Ads" },
        { value: "google", label: "Google Ads" },
      ],
      filteredTableData: [],
      selectedData: {},
      filterData: {},
      showMainDropdown: false,
      hoveredCategory: null,
      hoveredValues: [],
    };
  },
  props: {
    clientID: {
      type: String,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    businessType: {
      type: String,
    },
  },
  mounted() {
    this.fetchFilters();
    this.fetchFiltersData();
  },
  methods: {
    addActivityLog() {
      axios
        .post(
          `${process.env.VUE_APP_SERVER_URL}/api/logs/`,
          {
            description: "Categorised Campaigns Insights",
            action: "view",
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
            },
          }
        )
        .then((response) => {})
        .catch((error) => {});
    },
    fetchFilters() {
      const url = `${process.env.VUE_APP_SERVER_URL}/api/campaign-filters?clientID=${this.clientID}`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
            "Content-Type": "application/json",
          },
          params: { platform: this.TableChannel },
        })
        .then((response) => {
          this.filterData = response.data.overallFilters;
        })
        .catch((error) => {
          console.error("Error fetching filters:", error);
        });
    },
    fetchFiltersData(TableChannel = "") {
      this.isFilteredDataFetched = true;
      const url = `${process.env.VUE_APP_SERVER_URL}/api/categorized-campaigns`;
      axios
        .post(
          url,
          {
            clientID: this.clientID,
            startDate: new Date(this.startDate).toISOString().split("T")[0],
            endDate: new Date(this.endDate).toISOString().split("T")[0],
            platform: this.TableChannel,
            filters: this.selectedData,
          },
          {
            headers: {
              Authorization: `Bearer ${getUserToken()}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          this.filteredTableData = response.data.campaigns;
          this.isFilteredDataFetched = false;
          this.currentPage = 1;
          Object.keys(this.selectedData).forEach((key) => {
            if (this.selectedData[key].length === 0) {
              delete this.selectedData[key];
            }
          });
          this.addActivityLog();
        })
        .catch((error) => {
          this.isFilteredDataFetched = false;
          this.showToast("Error", "Failed to fetch filters!", "danger");
        });
    },

    sortFn(a, b) {
      return sortFuntionNumericVal(a, b);
    },
    sortStringtoNumFn(a, b) {
      return sortFuntionStringintoNumericVal(a, b);
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant === "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    setHoveredCategory(key, values) {
      if (this.selectedData[key] && this.selectedData[key].includes(values)) {
        this.selectedData[key] = this.selectedData[key].filter(
          (item) => item !== value
        );
        if (this.selectedData[key].length === 0) {
          delete this.selectedData[key];
        }
      }
      this.hoveredCategory = key;
      this.hoveredValues = values;
    },
    ensureKeyExists(key, value = "") {
      if (this.selectedData[key] && this.selectedData[key].includes(value)) {
        this.selectedData[key] = this.selectedData[key].filter(
          (item) => item !== value
        );
        if (this.selectedData[key].length === 0) {
          delete this.selectedData[key];
        }
      } else {
        if (!(key in this.selectedData)) {
          this.selectedData[key] = [];
        }

        if (value !== "" && value !== null) {
          this.selectedData[key].push(value);
        }
      }

      this.fetchFiltersData();
    },
    removeFilter(key, value) {
      if (this.selectedData[key] && this.selectedData[key].includes(value)) {
        this.selectedData[key] = this.selectedData[key].filter(
          (item) => item !== value
        );
        if (this.selectedData[key].length === 0) {
          delete this.selectedData[key];
        }
      }
      this.fetchFiltersData();
    },
    deleteAllFilters() {
      this.selectedData = {};
      this.fetchFiltersData();
    },
    ShowClearAll() {
      let count = 0;

      Object.values(this.selectedData).forEach((item) => {
        item.forEach(() => count++);
      });

      return count > 1;
    },
  },
  watch: {
    startDate(newVal, oldVal){
       if (newVal) {
        this.fetchFiltersData();
       }
    },
    TableChannel(newVal, oldVal) {
      this.fetchFilters();
      this.fetchFiltersData(newVal);
    },
  },
  computed: {
  filterDataColumns() {
    if (!this.filteredTableData || this.filteredTableData.length === 0) return [];
    let columns = [];
    const firstItem = this.filteredTableData[0];
    if (firstItem) {
      Object.keys(firstItem).forEach((key) => {
        if (!["metrics", "campaign", "campaignId", "source"].includes(key)) {
          columns.push({
            label: key.charAt(0).toUpperCase() + key.slice(1), // Capitalize first letter
            field: key,
            tdClass: "text-center custom-text",
            thClass: "text-center custom-text-th",
            sortable: true,
          });
        }
      });
    }
    columns.push(
      { label: "Campaign", field: "campaign", tdClass: "text-center custom-text", thClass: "text-center custom-text-th", sortable: false },
      { label: "Spends", field: "spends", tdClass: "text-center custom-text", thClass: "text-center custom-text-th", sortable: true, sortFn: this.sortStringtoNumFn },
      { label: "CPC", field: "cpc", tdClass: "text-center custom-text", thClass: "text-center custom-text-th", sortable: true, sortFn: this.sortStringtoNumFn },
      { label: "Impressions", field: "impressions", tdClass: "text-center custom-text", thClass: "text-center custom-text-th", sortable: true, sortFn: this.sortStringtoNumFn },
      { label: "Clicks", field: "clicks", tdClass: "text-center custom-text", thClass: "text-center custom-text-th", sortable: true, sortFn: this.sortStringtoNumFn },
      { label: "Leads", field: "conversions", tdClass: "text-center custom-text", thClass: "text-center custom-text-th", sortable: true, sortFn: this.sortStringtoNumFn }
    );
    return columns;
  },
  isCreateButtonDisabled() {
      return !(
        this.selectedCategory &&
        this.selectedChannel &&
        this.selectedtags.length
      );
    },
    filterOptimisationTabData() {
      if (!Array.isArray(this.filteredTableData)) return [];

      return this.filteredTableData.map((item) => {
        return {
          ...item,
          clicks: item.metrics.clicks,
          impressions: item.metrics.impressions,
          trueViews: item.metrics.trueViews,
          spends: item.metrics.spends,
          conversions: item.metrics.conversions,
          cpc: item.metrics.cpc,
        };
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

@media (max-width: 768px) {
    .selected_FilterData{
        padding-bottom: 1px;
    }
    .dropdown_filter_main_container_nav {
        margin: 0 auto;
        padding: 0;
        width: 10rem;

    }
    .categorised-campaign-container{
        flex-direction: column;
        align-items: center;
    }
    .categorised-filter-container{
        justify-content: center;
    }
}


@media (max-width: 480px) {
    .categorised-filter-container{
        flex-direction: column !important;
    }
}

.filter-campaigns-container {
  .spinnerCentered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .custom-text {
    font-size: 12px !important;
    color: #1a1a1d;
  }

  .custom-text-th {
    font-size: 13px !important;
    font-weight: 600 !important;
    color: black;
    min-width: 10rem !important;
  }

  .vgt-table td,
  .vgt-table th {
    padding: 0.6rem !important;
  }

  .small-row-table ::v-deep(.vgt-table tr) {
    height: 30px !important;
  }

  .small-row-table ::v-deep(.vgt-table td) {
    padding: 4px !important;
    font-size: 12px;
  }

  .dropdown_filter_main_container {
    background: #ffffff;
    color: rgb(111, 109, 125);
    height: 100%;
  }

  li {
    list-style: none;
  }

  li a {
    text-decoration: none;
    display: block;
    color: #212529;
    font-family: Arial, sans-serif;
  }

  .dropdown_filter_main_container_nav {
    margin: 0 auto;
    padding: 0;
    min-width: 20rem;
    max-width: 35rem;
    border-radius: 5px;
    display: inline-block;
    height: 100%;
  }

  .dropdown_filter_main_container_nav > li {
    position: relative;
    border: 1px solid #d8d6de;
    height: 100%;
    border-left: 1px solid #d8d6de;
  }

  .dropdown_filter_main_container_nav > li .selected_FilterData {
    height: 100%;
    color: #212529;
  }

  .dropdown_filter_main_container_nav li:first-child {
    border: none;
  }

  .dropdown_filter_main_container_nav > li > .selected_FilterData {
    color: rgb(111, 109, 125);
    border: 1px solid #d8d6de;
    border-radius: 4px;
    transition: background 0.3s, color 0.3s;
  }

  .dropdown_filter_main_dropdown {
    position: absolute;
    width: 20rem;
    top: 100%;
    background: white;
    border: 1px solid #d8d6de;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    padding: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out;
    transform: translateY(5px);
    z-index: 1000;
  }

  .dropdown_filter_main_dropdown .child {
    position: relative;
    max-width: 100% !important;
    padding: 0.3rem;
  }

  .hassubs:hover .dropdown_filter_main_dropdown,
  .hassubs .hassubs:hover .dropdown_filter_main_dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .dropdown_filter_main_dropdown li {
    padding: 0;
    margin: 5px 5px;
    border-radius: 2px;
  }

  .dropdown_filter_main_dropdown li :hover {
    background-color: rgb(238, 238, 238);
  }

  .dropdown_filter_main_dropdown li a {
    color: black;
    height: 100%;
    font-weight: normal;
    border-radius: 2px;
    transition: background 0.3s, color 0.3s;
  }

  .hassubs {
    height: 100%;
  }


  .hassubs .hassubs .dropdown_filter_main_dropdown {
    top: 0px;
    min-width: 10rem;
    background: white;
    border-radius: 5px;
    border: 1px solid #d8d6de;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateX(5px);
    transition: opacity 0.3s ease-in-out, transform 0.2s ease-in-out;
  }

  .hassubs .hassubs:hover .dropdown_filter_main_dropdown {
    opacity: 1;
    visibility: visible;
    transform: translateX(0);
  }

  .dropdown_filter_main_dropdown .selected {
    color: white !important;
    font-weight: bold;
  }

  .selected a {
    color: white !important;
  }

  .dropdown_filter_main_dropdown .selected::after {
    content: "✓";
    float: right;
    font-size: 14px;
    font-weight: bold;
  }

  .custom-name-column {
    font-size: 12px;
    font-weight: 600px;
  }

  .key-box {
    border-bottom: 1px solid #d8d6de;
    padding: 0.5rem;
    border-radius: 4px;
  }

  .selected-items {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding-top: 4px;
    padding-left: 10px;
    border-radius: 3xp;
  }

  .selected-box {
    display: flex;
    align-items: center;
    display: inline-block;
    white-space: nowrap;
    background-color: #6c4e96;
    color: white;
    border: 1px solid #6c4e96;
    border-radius: 3px;
    margin-right: 8px;
    display: flex;
    align-items: center;
    padding: 4px;
  }

  .tag {
    background-color: #6c4e96;
    color: white;
    border: 1px solid #6c4e96;
    border-radius: 3px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    margin-right: 8px;
    padding: 4px;
  }

  .tag-text {
    margin: 0;
    color: white;
  }
  .subscription-container {
    display: flex;
    flex-direction: column;
  }

  .subscription-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .check-icon {
    color: rgb(111, 109, 125);
  }
  .subscription-item {
    padding-left: 26px;
  }

  .subscription-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .check-icon {
    color: rgb(111, 109, 125);
  }
  .categorised-campaign-container{
    display: flex;
    align-items: center;
    justify-content:space-between;
  }
  .filter-title-text{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin: 0px !important ;
    padding: 7px;
  }
  .categorised-filter-container{
    display: flex; 
    gap: 1rem; 
    flex: 1;
    justify-content: flex-end; 
    align-items:center;
    right: 0px;
  }
  .categorisedCampaignsInsightsTable{
    width:100%;
    margin:auto;
  }
}
</style>