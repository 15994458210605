<template>
  <div class="multiHeaderContainer">
    <div style="display: flex; justify-content: space-between;">
      <div>
        <b-card-title>
          <h3 class="app-title mdsm">Project Outcome</h3>
        </b-card-title>
      </div>
      <div style="display: flex;justify-content:space-between; gap:10px">
        <div
          class="selectBox custom-vue-select"
          style="margin-top: -15px; min-width: 160px; max-width: 200px; min-height: 20px"
        >
          <label>Product:</label>
          <br />
          <v-select
            label="name"
            placeholder="Select Product(s)"
            v-model="filterProjectOutComeData"
            multiple
            :options="filterProjectOutcomeOptions"
            class="horizontal-scroll heightFixed"
            :deselectFromDropdown="true"
          />
        </div>
        <div style="display: flex; justify-content: space-between; gap: 10px;">
          <div style="margin-top: -15px; width: 100px;">
            <label>Data Channel:</label>
            <b-form-select
              v-model="selectedAdsOption"
              :options="sourceType"
            ></b-form-select>
          </div>
        </div>
        <div style="margin-top: 8px;">
          <b-button
            variant="outline-primary"
            @click="openProjectOutComeReport"
            size="sm"
            style="padding: 10px;"
            >Upload
            <b-icon icon="cloud-upload" variant="primary" />
          </b-button>
        </div>
      </div>
    </div>
    <div>
      <div v-if="this.dataLoadFlag" class="spinnerCentered">
        <multiChannelSpinner />
      </div>
      <div
        v-else-if="this.productOutComeData.length === 0"
        class="spinnerCentered"
      >
        <span class="p-4">
          Please upload weekly project outcome.
        </span>
      </div>
      <div v-else class="multiHeaderContainer">
        <b-row>
          <b-col cols="12">
            <b-card>
              <div class="header-container">
                <div class="table-project-out-come">
                  <div class="date-column">
                    <!-- <table class="table">
                      <thead>
                        <tr>
                          <th>
                            <feather-icon icon="DownloadIcon" color="#200E6B" size="18"
                              style="cursor: pointer; margin-top: 5px"
                              @click="exportProjectOutDataEntireData('project_outcome')" />
                          </th>
                        </tr>
                        <tr>
                          <th>Campagin Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, index) in filteredProjectOutComeData[0]
                          .productWiseData" :key="index">
                          <td class="dateFacebookProjectTd paddingMeta0">
                            <span>{{ item.projectName }}--</span>
                          </td>
                        </tr>
                      </tbody>
                    </table> -->
                  </div>
                  <div
                    class="scrollable-table"
                    v-for="(item, index) in filteredProjectOutComeData"
                    :key="index"
                  >
                    <table class="table">
                      <thead>
                        <tr>
                          <th
                            colspan="33"
                            class="mainTh"
                            :style="{
                              backgroundColor: getProjectsBackgroundColor(
                                index
                              ),
                            }"
                          >
                            <span
                              style="display: flex; justify-content: space-between; align-items: center;"
                            >
                             
                              <span style="margin-top: 10px; float: right;">{{
                                item.productName
                              }}</span>
                              <span style="float: right;">
                                <feather-icon
                                  icon="DownloadIcon"
                                  color="#200E6B"
                                  size="18"
                                  style="cursor: pointer;"
                                  @click="
                                    exportProductOutcomeData(
                                      item.productWiseData,
                                      item.productName
                                    )
                                  "
                                />
                              </span>
                            </span>
                          </th>
                        </tr>
                        <tr>
                          <th
                            v-for="(value, key) in productMap.productMapOutcome
                              .default"
                            :key="key"
                          >
                            {{ value }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(data, dataIndex) in item.productWiseData"
                          :key="dataIndex"
                        >
                          <template v-if="businessTypeProject === 'leadgen'">
                            <td
                              v-for="(value, key) in productMap
                                .productMapOutcome.default"
                              :key="key"
                            >
                              <span
                                v-if="
                                  data.metrics[key] === 'total' ||
                                    data.metrics[key] === 'Total'
                                "
                              >
                                <b>{{ data.metrics[key] }}</b>
                              </span>
                              <span v-else>{{ data.metrics[key] }}</span>
                            </td>
                          </template>
                          <template v-else-if="businessTypeProject === 'leadform'">
                            <td
                              v-for="(value, key) in productMap
                                .productMapOutcome.default"
                              :key="key"
                            >
                              <span
                                v-if="
                                  data.metrics[key] === 'total' ||
                                    data.metrics[key] === 'Total'
                                "
                              >
                                <b>{{ data.metrics[key] }}</b>
                              </span>
                              <span v-else>{{ data.metrics[key] }}</span>
                            </td>
                          </template>
                          <template v-else>
                            <td
                              v-for="(value, key) in productMap
                                .productMapOutcome.default"
                              :key="key"
                            >
                              {{ data.metrics[key] }}
                            </td>
                          </template>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </div>
    </div>
    <b-modal
      v-model="addProjectOutComeReport"
      id="showShopNameModal"
      hide-footer
      hide-header
      centered
      size="lg"
    >
      <b-card-text>
        <div class="header text-right">
          <feather-icon
            style="cursor: pointer"
            @click="CloseProjectOutComeReport"
            class="Xicon"
            icon="XIcon"
          ></feather-icon>
        </div>
        <div class="modal-item mb-1 mt-1">
          <div
            class="pr-1 edit-form"
            style="display: flex; justify-content:space-between"
          >
            <h4 class="app-title mdsm">
              Upload Weekly Project Outcome Data
            </h4>
            <div>
              <a
                v-b-tooltip.hover.top="tooltipForSampleSheet"
                href="https://docs.google.com/spreadsheets/d/1s_rQKJLKX9er7nrqVrUqnKE_UEgsGHaog388mNiZvcM/edit?gid=1976332159#gid=1976332159"
                target="_blank"
              >
                <span
                  style="text-decoration: underline;
              color: #6b6b6b !important;"
                >
                  sample sheet
                </span>
                <span>
                  <img
                    src="@/assets/images/logo/sheets.png"
                    alt="Icon"
                    style="width: 30px; height: 30px; margin-right: 5px; margin-left: 5px; cursor:pointer "
                  />
                </span>
              </a>
            </div>
          </div>
        </div>
      </b-card-text>
      <!-- <span >
        <img v-b-tooltip.hover.top="tooltipForSampleSheet" src="@/assets/images/logo/sheets.png" alt="Icon"
        style="width: 30px; height: 30px; margin-right: 5px;margin-bottom: 27px; cursor:pointer " />
      </span> -->
      <div>
        <b-row class="pl-1 pr-1" v-for="(form, index) in forms" :key="index">
          <b-col cols="4">
            <label for="username" class="username">Product Name:</label>
            <b-form-input
              placeholder="E.g. DemandGen/PMax etc."
              v-model="form.projectName"
            ></b-form-input>
          </b-col>
          <b-col cols="5" class="mb-1">
            <label for="username" class="username">Upload File:</label>
            <b-form-file
              :state="Boolean(form.files && form.files.length)"
              placeholder="Choose CSV files..."
              drop-placeholder="Drop files here..."
              accept=".csv"
              ref="fileInput"
              hidden
            ></b-form-file>
          </b-col>
          <b-col cols="3">
            <div style="display:flex; justify-content:space-around">
              <b-button
                v-if="!form.uploadSuccessful"
                variant="outline-primary"
                @click="
                  (event) => {
                    $refs.fileInput[index].$el.click();
                    handleFileChange(event, index);
                  }
                "
                size="sm"
                style="height:37px; margin-top:23px"
                >Upload <b-icon icon="cloud-upload" variant="primary" />
              </b-button>
              <!-- <b-icon
           v-b-tooltip.hover.top="'CSV Uploaded Successfully!'"
          v-if="form.uploadSuccessful"
          variant="success"
          class="upPlusIcon"
          icon="check"
        /> -->
              <b-button
                v-if="form.uploadSuccessful"
                variant="outline-primary"
                disabled
                size="sm"
                style="height:37px; margin-top:23px"
                >Uploaded <b-icon icon="check" variant="success" />
              </b-button>
              <b-icon
                v-if="index === forms.length - 1"
                variant="primary"
                class="upPlusIcon"
                icon="plus-circle-fill"
                v-b-tooltip.hover.top="tooltipForNewLine"
                @click="addForm"
              />
              <b-icon
                v-if="index !== forms.length - 1"
                v-b-tooltip.hover.top="tooltipForReduceLine"
                variant="danger"
                class="upPlusIcon"
                icon="dash-circle-fill"
                @click="removeForm(index)"
              />
            </div>
          </b-col>
        </b-row>
        <template v-if="this.uploadWarnings.length > 0">
          <span
            class="text-danger"
            style="font-size: 12px; margin-left: 10px; width: 700px; word-wrap: break-word; display: inline-block;"
          >
            {{ this.uploadWarnings }}
          </span>
        </template>
      </div>
      <b-row class="mt-2 p-1">
        <div style="display: flex; width: 100%;">
          <div style="flex-grow: 1;"></div>
          <b-button
            variant="primary"
            style="margin-left: auto;"
            @click="CloseProjectOutComeReport"
          >
            Done
          </b-button>
        </div>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BModal,
  BFormFile,
  BRow,
  BCol,
  BInputGroup,
  BFormInput,
  BCardTitle,
  BCardText,
  BSpinner,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";
import multiChannelSpinner from "@/components/client/multiChannelSpinner.vue";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { getUserToken } from "@/auth/utils";
import productMap from "@/@core/utils/projectOutCome";
export default {
  data() {
    return {
      filterProjectOutComeData: [],
      productMap: productMap,
      tooltipForSampleSheet: "Click here to view a sample sheet for the upload",
      tooltipForReduceLine: "Click to remove this",
      tooltipForNewLine: "Click to upload more",
      uploadWarnings: [],
      businessTypeProject: "",
      addProjectOutComeReport: false,
      uploadFiles: false,
      uploadStatus: "",
      forms: [{ projectName: "", files: [], uploadSuccessful: false }],
      selectedAdsOption: "all",
      sourceType: ["all", "google", "meta"],
      lightColorHashCodes: [
        "#AFEEEE", // Light Turquoise
        "#E6E6FA", // Light Lavender
        "#F08080", // Light Coral
        "#778899", // Light Slate Gray
        "#9370DB", // Light Indigo
        "#B03060", // Light Maroon
        "#008B8B", // Light Teal
        "#808000", // Light Olive
        "#FFA07A", // Light Salmon
        "#D8BFD8", // Light Orchid
      ],
    };
  },
  components: {
    BCard,
    BModal,
    BButton,
    BFormFile,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    BSpinner,
    vSelect,
    ToastificationContent,
    BFormSelect,
    multiChannelSpinner,
  },
  props: {
    clientID: {
      type: String,
    },
    productOutComeData: {
      type: Array,
    },
    dataLoadFlag: {
      type: Boolean,
    },
    clientName: {
      type: String,
    },
  },
  methods: {
    exportProjectOutDataEntireData(name) {
      let displayArr = {
        CampaignName: "campaignName",
        AdsetName: "adsetName",
        CreativeName: "creativeName",
        Channel: "channel",
        StartDate: "StartDate",
        EndDate: "endDate",
        Cost: "cost",
        Impression: "impr",
        L_clicks: "lclicks",
        CTR: "ctr",
        CPM: "cpm",
        FB_Leads: "fbLeads",
        CRM_Leads: "crmLeads",
        QL: "ql",
        FB_CPL: "fbCpl",
        CRM_CPL: "crmCpl",
        CPQL: "cpql",
        CPC: "cpc",
        QL_: "ql%",
        Previos_QL: "previousQl%",
        C2L: "c2l",
        Not_Contactable: "not_contactable",
        CallBack: "callback",
        Open_Leads: "open_leads",
        Campaign_Delivery_Status: "Campaign_Delivery_Status",
        Ads_Delivery: "Ads_Delivery",
      };

      // Function to get nested values from objects using a string path
      const getNestedValue = (obj, path) => {
        return path.split(".").reduce((acc, part) => acc && acc[part], obj);
      };

      // Check if data is an array and not empty
      if (
        !Array.isArray(this.productOutComeData) ||
        this.productOutComeData.length === 0
      ) {
        console.error("Data is empty or not an array");
        return;
      }

      // Function to escape CSV values
      const escapeCSV = (str) => {
        if (str === null || str === undefined) {
          return '""';
        }
        return `"${String(str).replace(/"/g, '""')}"`;
      };

      // Create CSV headers
      const headers = ["Date"];
      const groupNames = [];

      // Collect group names and headers
      this.productOutComeData.forEach((item) => {
        Object.keys(displayArr).forEach((key) => {
          headers.push(`${item.productName} ${key}`);
        });
      });

      // Create main header row
      const headerRow = headers.map((header) => escapeCSV(header)).join(",");

      // Get all unique project names
      const allProjects = new Set();
      this.productOutComeData.forEach((item) => {
        item.productWiseData.forEach((dateData) => {
          allProjects.add(dateData.projectName);
        });
      });

      // Create CSV rows for each unique project
      const rows = Array.from(allProjects).map((projectName) => {
        const row = [escapeCSV(projectName)];
        this.productOutComeData.forEach((item) => {
          const dateData = item.productWiseData.find(
            (d) => d.projectName === projectName
          ) || { metrics: {} };
          Object.keys(displayArr).forEach((key) => {
            row.push(
              escapeCSV(getNestedValue(dateData.metrics, displayArr[key]))
            );
          });
        });
        return row.join(",");
      });

      // Combine main header and rows to form CSV content
      const csv = [headerRow, ...rows].join("\n");
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");

      // Create a download link for the CSV file
      if (link.download !== undefined) {
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `${this.clientName}_${name}_${Date.now()}.csv`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    },
    handleCallOutComeGetApi(newSelection) {
      this.$emit("callOutComeGetApi", newSelection);
    },
    exportProductOutcomeData(data, name) {
  // Define the display array based on the ad type (use default for now)
  let displayArr = productMap.productMapOutcome.default;

  // Check if data is an array and not empty
  if (!Array.isArray(data) || data.length === 0) {
    console.error("Data is empty or not an array");
    return;
  }

  // Function to escape CSV values
  const escapeCSV = (str) => {
    if (str === null || str === undefined) {
      return '""';
    }
    return `"${String(str).replace(/"/g, '""')}"`;
  };

  // Function to get nested values from objects using a string path
  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((acc, part) => acc && acc[part], obj);
  };

  // Create CSV header
  const header = Object.keys(displayArr)
    .map((displayName) =>
      escapeCSV(
        displayArr[displayName].charAt(0).toUpperCase() +
          displayArr[displayName].slice(1)
      )
    )
    .join(",");

  // Create CSV rows
  const rows = data.map((item) => {
    return Object.keys(displayArr)
      .map((key) =>
        escapeCSV(getNestedValue(item.metrics, key))
      )
      .join(",");
  });

  // Combine header and rows to form CSV content
  const csv = [header].concat(rows).join("\n");
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");

  // Create a download link for the CSV file
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", `${name}.csv`);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
,
    getProjectsBackgroundColor(index) {
      return this.lightColorHashCodes[index % 10] + "!important";
    },
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    isFormComplete(form) {
      return form.projectName && form.files.length;
    },
    async handleFileChange(event, formIndex) {
      this.uploadWarnings = "";
      const inputElement = this.$refs.fileInput[formIndex];
      if (!inputElement) {
        console.error(
          "File input element not found for form index:",
          formIndex
        );
        return;
      }

      const files = inputElement.files;

      if (files && files.length) {
        if (this.forms[formIndex]) {
          this.$set(this.forms[formIndex], "files", Array.from(files));
          await this.submitForms();
        } else {
          console.error("Form at index", formIndex, "does not exist");
        }
      }
    },

    async submitForms() {
      this.uploadWarnings = [];
      this.uploadStatus = "";
      this.uploadFiles = true;

      for (const [index, form] of this.forms.entries()) {
        if (form.projectName && form.files.length) {
          const formData = new FormData();
          formData.append("productName", form.projectName);
          form.files.forEach((file) => {
            formData.append(`csvFile`, file);
          });
          formData.append(`clientID`, this.clientID);
          

          try {
            const response = await axios.post(
              `${process.env.VUE_APP_SERVER_URL}/api/channel/csv/upload-project-outcome`,
              formData,
              {
                headers: {
                  Authorization: `Bearer ${getUserToken()}`,
                },
              }
            );

            if (response.status === 200) {
              this.uploadWarnings = response.data && response.data.errors;
              this.handleCallOutComeGetApi();
              this.showToast("Success!", `${response.data.message}`, "success");
              this.uploadFiles = false;
              this.uploadStatus = "Files uploaded successfully.";
              this.$set(this.forms[index], "uploadSuccessful", true); // Set upload successful
            } else {
              this.uploadStatus = "File upload failed.";
            }
          } catch (error) {
            this.uploadWarnings = "";
            this.uploadFiles = false;
            this.showToast("Error", "Unknown error occurred.", "danger");
            console.error("Error uploading files:", error);
            this.uploadStatus = "Error uploading files.";
          }
        } else {
          this.uploadStatus = "Please provide a project name and select files.";
        }
      }
    },
    removeForm(index) {
      this.forms.splice(index, 1);
      this.uploadWarnings = "";
    },
    addForm() {
      this.uploadWarnings = "";
      this.forms.push({ projectName: "", files: [], uploadSuccessful: false });
    },
    CloseProjectOutComeReport() {
      (this.forms = [
        {
          projectName: "",
          file: null,
        },
      ]),
        (this.addProjectOutComeReport = false);
      this.uploadWarnings = "";
    },
    openProjectOutComeReport() {
      this.addProjectOutComeReport = true;
    },
    exportData(dateWiseData, projectName) {
      console.log("Exporting data for", projectName, dateWiseData);
    },
    downReportProjectsEntirely(reportName) {
      console.log("Downloading report", reportName);
    },
  },
  computed: {
    filteredProjectOutComeData() {
      // Filter by productName if any filter is applied
      let filteredData =
        this.filterProjectOutComeData.length === 0
          ? this.productOutComeData
          : this.productOutComeData.filter((item) =>
              this.filterProjectOutComeData.includes(item.productName)
            );

      if (this.selectedAdsOption === "meta") {
        // Return a single object with "meta" channel if available
        return filteredData
          .map((product) => {
            // Find the first productWiseData object where channel is "meta"
            const metaData = product.productWiseData.find(
              (dataItem) => dataItem.metrics.channel.toLowerCase() === "meta"
            );
            return metaData
              ? { ...product, productWiseData: [metaData] }
              : null;
          })
          .filter((item) => item !== null);
      }

      if (this.selectedAdsOption === "google") {
        // Return a single object with "meta" channel if available
        return filteredData
          .map((product) => {
            // Find the first productWiseData object where channel is "meta"
            const googleData = product.productWiseData.find(
              (dataItem) => dataItem.metrics.channel.toLowerCase() === "google"
            );
            return googleData
              ? { ...product, productWiseData: [googleData] }
              : null;
          })
          .filter((item) => item !== null);
      }
      // Default behavior: return filtered data with channel filtering
      return filteredData.map((product) => ({
        ...product,
        productWiseData: product.productWiseData.filter((dataItem) => {
          if (this.selectedAdsOption === "all") {
            return true; // Include all channels if "all" is selected
          }
          return (
            dataItem.metrics.channel.toLowerCase() ===
            this.selectedAdsOption.toLowerCase()
          );
        }),
      }));
    },
    filterProjectOutcomeOptions() {
      let projectNamesSet = new Set();
      this.productOutComeData.forEach((item) => {
        projectNamesSet.add(item.productName);
      });
      let projectNamesArray = Array.from(projectNamesSet);
      return projectNamesArray;
    },
    filteredDataForOutcome() {
      return this.productOutComeData;
    },
  },
  watch: {
    "form.files": function(newFiles) {
      if (newFiles.length) {
        this.submitForms();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.heightFixed {
  .vs__dropdown-toggle {
    padding: 0 0 4px 0;
    background: none;
    font-size: 13px !important;
    border: 1px solid #d8d6de;
    border-radius: 0.357rem;
    height: 38px !important;
    margin-bottom: 10px;
  }
}

.selectBox {
  .custom-select {
    width: 100px !important;
  }
}

.custom-vue-select .vs__selected-options {
  display: flex !important;
  overflow-x: auto !important;
  white-space: nowrap;
  flex-wrap: nowrap !important;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.custom-vue-select .vs__selected {
  display: inline-block;
  margin-right: 5px;
}

.spinnerCentered {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.table-project-out-come {
  display: flex;
  width: 100%;
  overflow-x: auto;

  .table thead th {
    // border-bottom: 1px solid #ebe9f1;
    font-size: 12px !important;
    color: black;
  }

  .table {
    width: 100%;
    border-collapse: collapse;
  }

  th,
  td {
    border: 0.5px solid #ddd;
    color: black;
    text-align: center;
    font-size: 12px;
    height: 60px;
    width: 100px;
  }

  .dateTd {
    color: black;
    font-size: 12px !important;
  }

  th {
    color: black;
    background-color: #eff5f5 !important;
  }

  .padding0 {
    padding: 0px;
  }
}

.date-column {
  position: sticky;
  left: 0;
  z-index: 1;
  color: black;
  background-color: white;

  .padding0 {
    padding: 0px;
  }

  .paddingMeta0 {
    padding: 0 2px !important;
  }

  td {
    color: black;
    background-color: #ffffff;
  }
}

.dateProjectTd {
  color: black;
  font-size: 12px !important;
}

.dateFacebookProjectTd {
  color: black;
  font-size: 12px !important;
}

.single-select {
  height: 38px !important;
}

.upDownUploadIcon {
  margin-left: 10px;
  margin-top: 2px;
  cursor: pointer;
  color: blue;
  font-size: 20px;
}

.upPlusIcon {
  margin-left: 10px;
  margin-top: 30px;
  cursor: pointer;
  color: #200e6b;
  font-size: 20px;
}
</style>
